import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckCircle, Close, ReportProblem } from "@mui/icons-material";
import { Problem } from "../../entities/problem";
import DoubleClickDelete from "../buttons/double-click-delete";
import {
  deleteProblemById,
  resolveProblemById,
} from "../../requests/problem-requests";
import { useDispatch } from "react-redux";
import { getProblemsAction } from "../../actions/problem-actions";
import { errorString } from "../../functions/error-functions";
import DoubleClickResolve from "../buttons/double-click-resolve";
import { ProblemStatus } from "../../enums/problem-status";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function ProblemDialog({
  onClose,
  problem,
}: {
  onClose: () => any;
  problem: Problem;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={problem.problemStatus}>
            {problem.problemStatus === ProblemStatus.UNRESOLVED ? (
              <ReportProblem fontSize={"large"} color={"error"} />
            ) : (
              <CheckCircle fontSize={"large"} color={"success"} />
            )}
          </Tooltip>

          <Typography sx={{ ml: 1 }} variant={"h6"}>
            Subject: {problem.subject}
          </Typography>
        </Box>
        <Box>
          {problem.problemStatus === ProblemStatus.UNRESOLVED && (
            <DoubleClickResolve
              onClick={() => {
                resolveProblemById(problem.id)
                  .then((response) => {
                    dispatch(getProblemsAction());
                    emitSnackbarEvent({
                      message: `${t("problem.resolvedSnackbarMessage")}`,
                      severity: "success",
                    });

                    handleClose();
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          )}
          <DoubleClickDelete
            onClick={() => {
              deleteProblemById(problem.id)
                .then(() => {
                  dispatch(getProblemsAction());
                  emitSnackbarEvent({
                    message: `${t("problem.deletedSnackbarMessage")}`,
                    severity: "warning",
                  });
                  handleClose();
                })
                .catch((error) =>
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  })
                );
            }}
          />
          <Tooltip title={`${t("tooltips.close")}`}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box>
          <Typography variant="body1" gutterBottom>
            {problem.text}
          </Typography>
        </Box>
        <Stack>
          <Typography variant="caption">
            {t("problem.dialogLastChangedAt")}:{" "}
            {new Date(problem.lastChangedAt).toDateString()}
          </Typography>
          <Typography variant="caption">
            {t("problem.dialogCreatedAt")}:{" "}
            {new Date(problem.createdAt).toDateString()}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
