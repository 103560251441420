import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Person } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import PasswordInput from "../inputs/password-input";
import { UserCreationBody } from "../../request-bodies/user-creation-body";
import md5 from "md5";
import { postUser } from "../../requests/user-requests";
import { getSelfAction, getUsersAction } from "../../actions/user-actions";
import RoleSelect, { RoleOption } from "../selects/role-select";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import UserSelect, { UserOption } from "../selects/user-select";
import { Role } from "../../enums/role";

export default function UserCreateDialog({ onClose }: { onClose: () => any }) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedRoleOption, setSelectedRoleOption] =
    useState<RoleOption | null>(null);

  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(null);
  const emitSnackbarEvent = useGlobalSnackbar();

  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <Person />
          </Avatar>
          <Typography>{t("user.createDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            sx={{ mb: 2 }}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            label={`${t("user.usernameLabel")}`}
            required={true}
            fullWidth={true}
          />

          <PasswordInput
            sx={{ mb: 2 }}
            password={password}
            setPassword={setPassword}
            required={true}
            fullWidth={true}
            clipboard={true}
            autoGenerate={true}
            generateButton={true}
          />

          <Box sx={{ mb: 2 }}>
            <RoleSelect
              selectedRoleOption={selectedRoleOption}
              setSelectedRoleOption={setSelectedRoleOption}
              required={true}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <UserSelect
              selectedUserOption={selectedUserOption}
              setSelectedUserOption={setSelectedUserOption}
              userFilterFunction={(user) =>
                user.role === Role.OWNER || user.role === Role.HOUSEKEEPER
              }
              label={"Host"}
            />
          </Box>

          <Button type={"submit"} fullWidth={true} variant={"contained"}>
            {t("createButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!username) {
      setError(`${t("user.noUsernameError")}`);
      return;
    }
    if (!password) {
      setError(`${t("user.noPasswordError")}`);
      return;
    }
    if (!selectedRoleOption) {
      setError(`${t("user.noRoleError")}`);
      return;
    }
    let hostUserId: number | null = null;
    if (selectedUserOption) {
      hostUserId = selectedUserOption.id;
    }

    let userCreationBody = {
      username,
      password: md5(password),
      role: selectedRoleOption.role,
      hostUserId,
    } as UserCreationBody;

    postUser(userCreationBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("user.createdSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getUsersAction());
        dispatch(getSelfAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
