import React, { useState } from "react";
import {
  Badge,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  styled,
} from "@mui/material";
import {
  AddCircle,
  Apartment,
  Article,
  DirectionsCar,
  Event,
  MeetingRoom,
  Person,
  ReportProblem,
  Restaurant,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useIsWorker from "../../hooks/use-is-worker";
import { useTranslation } from "react-i18next";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },

  ".MuiSpeedDialAction-staticTooltipLabel": {
    width: "200px",
  },
}));

export default function EntitySpeedDial() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  function handleClose(event?: object, reason?: string) {
    if (reason === "toggle") setOpen(false);
  }
  function handleOpen(event?: object, reason?: string) {
    if (reason === "toggle") setOpen(true);
  }

  const isHousekeeper = useIsHousekeeper();
  const isWorker = useIsWorker();

  const speedDialItems = [
    {
      text: `${t("speedDial.addFoodReservationButtonText")}`,
      to: "/food/new-reservation",
      iconComponent: <Restaurant />,
      onlyHousekeeper: false,
    },
    {
      text: `${t("speedDial.addApartmentReservationButtonText")}`,
      to: "/apartments/new-reservation",
      iconComponent: <Apartment />,
      onlyHousekeeper: false,
    },
    {
      text: `${t("speedDial.addOfficeReservationButtonText")}`,
      to: "/offices/new-reservation",
      iconComponent: <MeetingRoom />,
      onlyHousekeeper: false,
      forWorker: true,
    },
    {
      text: `${t("speedDial.addCarReservationButtonText")}`,
      to: "/cars/new-reservation",
      iconComponent: <DirectionsCar />,
      onlyHousekeeper: false,
    },
    {
      text: `${t("speedDial.addApartmentButtonText")}`,
      to: "/apartments/new-apartment",
      iconComponent: <Apartment />,
      onlyHousekeeper: true,
    },
    {
      text: `${t("speedDial.addOfficeButtonText")}`,
      to: "/offices/new-office",
      iconComponent: <MeetingRoom />,
      onlyHousekeeper: true,
    },
    {
      text: `${t("speedDial.addCarButtonText")}`,
      to: "/cars/new-car",
      iconComponent: <DirectionsCar />,
      onlyHousekeeper: true,
    },
    {
      text: `${t("speedDial.addDocumentButtonText")}`,
      to: "/information/new-document",
      iconComponent: <Article />,
      onlyHousekeeper: true,
    },
    {
      text: `${t("speedDial.addUserButtonText")}`,
      to: "/users/new-user",
      iconComponent: <Person />,
      onlyHousekeeper: true,
    },
    {
      text: `${t("speedDial.reportProblemButtonText")}`,
      to: "/problems/new-problem",
      iconComponent: <ReportProblem />,
    },
    {
      text: `${t("speedDial.addCustomReservationText")}`,
      to: "/new-custom-reservation",
      iconComponent: <Event />,
      onlyHousekeeper: true,
    },
  ];

  return (
    <StyledSpeedDial
      ariaLabel={"Add Entity Speed Dial"}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      transitionDuration={{ enter: 1, exit: 1 }}
    >
      {speedDialItems
        .filter((speedDialItem) => {
          if (isWorker && !speedDialItem.forWorker) return false;
          return (
            (speedDialItem.onlyHousekeeper && isHousekeeper) ||
            !speedDialItem.onlyHousekeeper
          );
        })
        .map((speedDialItem) => {
          return (
            <SpeedDialAction
              key={speedDialItem.to}
              sx={{
                ".MuiSpeedDialAction-staticTooltipClosed": { maxWidth: "none" },
              }}
              icon={
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <AddCircle fontSize={"small"} color={"primary"} />
                  }
                >
                  {speedDialItem.iconComponent}
                </Badge>
              }
              tooltipTitle={speedDialItem.text}
              tooltipOpen={true}
              onClick={() => {
                navigate(speedDialItem.to);
                setOpen(false);
              }}
            />
          );
        })}
    </StyledSpeedDial>
  );
}
