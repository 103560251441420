import { LoginBody } from "../request-bodies/login-body";
import { healthEndpoint, loginEndpoint } from "./endpoints";
import axios from "axios";

export function login(loginBody: LoginBody) {
  return axios.post(loginEndpoint, loginBody);
}

export function healthRequest(token: string) {
  return axios.get(healthEndpoint, { headers: { Authorization: token } });
}
