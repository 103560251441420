import React, { FormEvent, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Apartment as ApartmentIcon, Close, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function DialogDelete({
  onClick,
  avatarIcon,
  title,
  sx,
  color = "error",
  message,
}: {
  onClick: any;
  avatarIcon: JSX.Element;
  title: string;
  sx?: SxProps<Theme>;
  color?: IconButtonProps["color"];
  message: string;
}) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function handleClose() {
    setDialogOpen(false);
  }
  return (
    <>
      <Tooltip title={`${t("tooltips.delete")}`}>
        <IconButton sx={sx} color={color} onClick={() => setDialogOpen(true)}>
          <Delete />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <Dialog
          //Added this to prevent calendar popup from closing
          onClick={(event) => {
            event.stopPropagation();
          }}
          onClose={handleClose}
          open={dialogOpen}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mr: 1 }}>{avatarIcon}</Avatar>
              <Typography>{title}</Typography>
            </Box>
            <Tooltip title={`${t("tooltips.close")}`}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent dividers={true} sx={{ width: "600px" }}>
            <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
              <Typography>{message}</Typography>

              <Button
                autoFocus={true}
                type={"submit"}
                sx={{ mt: 2 }}
                fullWidth={true}
                variant={"contained"}
                color={"error"}
              >
                {t("deleteButtonText")}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    onClick();
    handleClose();
  }
}
