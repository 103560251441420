import React, { Dispatch, SetStateAction } from "react";
import { Divider, Drawer, IconButton, List, Toolbar } from "@mui/material";
import {
  Apartment,
  Article,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  DirectionsCar,
  ExpandMore,
  Home,
  MeetingRoom,
  Person,
  ReportProblem,
  Restaurant,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ListNavItem from "./list-nav-item";
import { TreeItem, TreeView } from "@mui/lab";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import useIsWorker from "../../hooks/use-is-worker";
import { useTranslation } from "react-i18next";
import { sortByNumber } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";

export const drawerWidth = 240;

export default function DrawerLeft({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const isHousekeeper = useIsHousekeeper();
  const isWorker = useIsWorker();

  const documents = useSelector(
    (state: RootState) => state.documentsState.documents
  );

  const navigate = useNavigate();
  function getLinks() {
    if (isWorker)
      return [
        {
          text: `${t("drawer.officesLinkText")}`,
          to: "/offices",
          iconComponent: <MeetingRoom />,
          forWorker: true,
        },
      ];
    else if (isHousekeeper)
      return [
        {
          text: isHousekeeper
            ? `${t("drawer.dashboardLinkText")}`
            : `${t("drawer.homeLinkText")}`,
          to: "/",
          iconComponent: isHousekeeper ? <Dashboard /> : <Home />,
        },
        {
          text: `${t("drawer.apartmentsLinkText")}`,
          to: "/apartments",
          iconComponent: <Apartment />,
        },
        {
          text: `${t("drawer.foodLinkText")}`,
          to: "/food",
          iconComponent: <Restaurant />,
        },
        {
          text: `${t("drawer.officesLinkText")}`,
          to: "/offices",
          iconComponent: <MeetingRoom />,
          forWorker: true,
        },
        {
          text: `${t("drawer.carsLinkText")}`,
          to: "/cars",
          iconComponent: <DirectionsCar />,
        },
        {
          text: `${t("drawer.usersLinkText")}`,
          to: "/users",
          iconComponent: <Person />,
          housekeeperOnly: true,
        },
        {
          text: `${t("drawer.documentsLinkText")}`,
          to: "/information",
          iconComponent: <Article />,
          housekeeperOnly: true,
        },
        {
          text: `${t("drawer.problemsLinkText")}`,
          to: "/problems",
          iconComponent: <ReportProblem />,
        },
      ];
    else
      return [
        {
          text: isHousekeeper
            ? `${t("drawer.dashboardLinkText")}`
            : `${t("drawer.homeLinkText")}`,
          to: "/",
          iconComponent: isHousekeeper ? <Dashboard /> : <Home />,
        },
        {
          text: `${t("drawer.apartmentsLinkText")}`,
          to: "/apartments",
          iconComponent: <Apartment />,
        },
        {
          text: `${t("drawer.foodLinkText")}`,
          to: "/food",
          iconComponent: <Restaurant />,
        },
        {
          text: `${t("drawer.carsLinkText")}`,
          to: "/cars",
          iconComponent: <DirectionsCar />,
        },
        {
          text: `${t("drawer.problemsLinkText")}`,
          to: "/problems",
          iconComponent: <ReportProblem />,
        },
      ];
  }
  const links = getLinks();

  return (
    <Drawer
      sx={{
        width: drawerOpen ? drawerWidth : "0",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant={"persistent"}
      anchor={"left"}
      open={drawerOpen}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "right" }}>
        <IconButton onClick={() => setDrawerOpen(false)}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {links.map((linkObject) => (
          <ListNavItem key={linkObject.to} linkObject={linkObject} />
        ))}
      </List>
      <Divider />
      {renderTreeView()}
    </Drawer>
  );

  function renderTreeView() {
    let orderedDocuments = [...documents].sort((a, b) =>
      sortByNumber(a.index, b.index, SortType.ASC)
    );

    let parentNodes = Array.from(
      new Set(orderedDocuments.map((document) => document.parentTopic))
    );

    let jsx = [];

    let counter = 0;

    for (let parentNode of parentNodes) {
      if (!parentNode) continue;
      jsx.push(
        <TreeItem nodeId={counter.toString()} label={parentNode}>
          {orderedDocuments
            .filter((document) => document.parentTopic === parentNode)
            .map((document) => {
              counter++;
              return (
                <TreeItem
                  nodeId={counter.toString()}
                  label={document.title}
                  onClick={() => {
                    navigate(`/${document.title}`);
                  }}
                />
              );
            })}
        </TreeItem>
      );
      counter++;
    }
    orderedDocuments
      .filter((document) => document.parentTopic === null)
      .forEach((document) => {
        counter++;
        jsx.push(
          <TreeItem
            nodeId={counter.toString()}
            label={document.title}
            onClick={() => {
              navigate(`/${document.title}`);
            }}
          />
        );
      });
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        disableSelection={true}
        disabledItemsFocusable={true}
      >
        {jsx}

        {/*<TreeItem nodeId="1" label="Applications">*/}
        {/*  <TreeItem nodeId="2" label="Calendar" />*/}
        {/*</TreeItem>*/}
        {/*<TreeItem nodeId="5" label="Documents">*/}
        {/*  <TreeItem nodeId="10" label="OSS" />*/}
        {/*  <TreeItem nodeId="6" label="MUI">*/}
        {/*    <TreeItem nodeId="8" label="index.js" />*/}
        {/*  </TreeItem>*/}
        {/*</TreeItem>*/}
      </TreeView>
    );
  }
}
