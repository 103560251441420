import React, { useEffect, useState } from "react";
import Calendar from "../calendar/calendar";
import { Event, SlotInfo } from "react-big-calendar";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  apartmentReservationsToCalendarEvents,
  carReservationsToCalendarEvents,
  customReservationsToCalendarEvents,
  foodReservationsToCalendarEvents,
} from "../../functions/calendar-events-functions";
import FoodReservationCreateDialog from "./food-reservation-create-dialog";
import { sortFoodReservation } from "../../functions/sort-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import useUser from "../../hooks/use-user";
import { useTranslation } from "react-i18next";
import { CarReservation } from "../../entities/car-reservation";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteCarReservationById } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import { errorString } from "../../functions/error-functions";
import { deleteFoodReservationById } from "../../requests/food-requests";
import { FoodReservation } from "../../entities/food-reservation";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { Apartment, Bookmark, Favorite, Restaurant } from "@mui/icons-material";
import { Typography } from "@mui/material";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { VariableCustomEvent } from "../mixed/mixed-calendar";
import { getApartmentReservationById } from "../../requests/apartment-requests";
import { useNavigate, useParams } from "react-router-dom";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import { userInfo } from "os";
import { UTCDateToTimelessDateKeepValues } from "../../functions/date-functions";

const MS_IN_MINUTE = 60000;

export default function FoodReservationsCalendar({
  hideHousekeeperEvents,
}: {
  hideHousekeeperEvents: boolean;
}) {
  const [slotInfo, setSlotInfo] = useState<SlotInfo | null>();
  const { apartmentReservationId } = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const apartmentReservations = useSelector((state: RootState) => {
    return state.apartmentsState.apartmentReservations;
  });

  const foodReservationEvents = useSelector((state: RootState) =>
    foodReservationsToCalendarEvents(
      state.foodState.foodReservations,
      state.apartmentsState.apartments
    )
  );

  const apartmentReservationEvents = useSelector((state: RootState) =>
    apartmentReservationsToCalendarEvents(
      [...state.apartmentsState.apartmentReservations].filter(
        (apartmentReservation) =>
          apartmentReservation.userId === state.authorizationState.user?.id ||
          user?.guests.some(
            (innerUser) => innerUser.id === apartmentReservation.userId
          )
      )
    )
  );
  const customReservationEvents = useSelector((state: RootState) => {
    return customReservationsToCalendarEvents([
      ...state.customReservationsState.customReservations,
    ]);
  });
  useEffect(() => {
    if (apartmentReservationId) {
      let apartmentReservation = apartmentReservations.find(
        (apartmentReservation) =>
          apartmentReservation.id === parseInt(apartmentReservationId)
      );
      if (apartmentReservation) {
        let slots: Date[] = [];
        let currentDate = new Date(
          UTCDateToTimelessDateKeepValues(apartmentReservation.start)
        );
        let endDate = new Date(
          UTCDateToTimelessDateKeepValues(apartmentReservation.end)
        );
        while (currentDate <= endDate) {
          slots.push(new Date(currentDate));
          currentDate = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24);
        }
        setSlotInfo({
          start: apartmentReservation.start,
          end: apartmentReservation.end,
          slots,
        } as SlotInfo);
      }
    }
  }, [apartmentReservationId]);
  return (
    <>
      <Calendar
        onSelectSlot={(slotInfo) => {
          setSlotInfo(slotInfo);
        }}
        events={[
          ...foodReservationEvents,
          ...apartmentReservationEvents,
          ...(hideHousekeeperEvents ? [] : customReservationEvents),
        ]}
        customEvent={VariableCustomEvent}
      />
      {slotInfo && (
        <FoodReservationCreateDialog
          initialDate={new Date(slotInfo.start)}
          initialDates={slotInfo.slots}
          onClose={() => {
            setSlotInfo(null);
          }}
        />
      )}
    </>
  );
}

export function CustomFoodReservationEvent({ event }: { event: Event }) {
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const { t } = useTranslation();

  let foodReservation: FoodReservation = event.resource;

  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <Restaurant sx={{ height: 24 }} />

      {user && user.id === foodReservation.apartmentReservation.userId && (
        <Bookmark sx={{ height: 24 }} />
      )}
      <Typography
        sx={{
          ml: 1,
          maxWidth: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title}
      </Typography>
      {((hovering && isHousekeeper) ||
        (hovering &&
          user &&
          (user.id === foodReservation.apartmentReservation.userId ||
            user.guests.some(
              (innerUser) =>
                innerUser.id === foodReservation.apartmentReservation.userId
            )))) && (
        <>
          <DoubleClickDelete
            sx={{ ml: 1, height: 24, width: 24 }}
            onClick={() => {
              deleteFoodReservationById(foodReservation.id)
                .then((response) => {
                  emitSnackbarEvent({
                    message: `${t("food.reservationDeletedSnackbarMessage")}`,
                    severity: "warning",
                  });
                  dispatch(getFoodReservationsAction());
                })
                .catch((error) => {
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  });
                });
            }}
          />
        </>
      )}
    </div>
  );
}
