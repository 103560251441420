import React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DocumentsList from "./documents-list";
import useTitle from "../../hooks/use-title";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Documents() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useTitle(`${t("document.title")}`);
  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {t("document.documentListTitle")}
                </Typography>
                <Typography>{t("document.clickToEdit")}</Typography>
              </Stack>
              <Tooltip title={`${t("document.newTooltip")}`}>
                <IconButton
                  onClick={() => navigate("/information/new-document")}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>

            <DocumentsList editable={true} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
