import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Apartment as ApartmentIcon,
  ArrowForward,
  Close,
  Event,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import ApartmentSelect from "../selects/apartment-select";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { CustomReservationCreationBody } from "../../request-bodies/custom-reservation-creation-body";
import { postCustomReservation } from "../../requests/custom-reservations-requests";
import { getCustomReservationsAction } from "../../actions/custom-reservation-actions";
import { useDispatch } from "react-redux";
import { errorString } from "../../functions/error-functions";
import ColorButton from "../color-button/color-button";

const colorOptions = ["red", "green", "blue", "orange"];

export default function CustomReservationCreateDialog({
  initialStart,
  initialEnd,
  onClose,
}: {
  initialStart: Date;
  initialEnd: Date;
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [start, setStart] = useState<Date>(initialStart);
  const [end, setEnd] = useState<Date>(initialEnd);
  const emitSnackbarEvent = useGlobalSnackbar();
  const [error, setError] = useState<string | null>(null);
  const [color, setColor] = useState<string>("red");
  const [title, setTitle] = useState<string>("");

  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        handleClose();
      }}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <Event />
          </Avatar>
          <Typography>{t("customReservation.createDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            required={true}
            fullWidth={true}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label={`${t("customReservation.titleLabel")}`}
            sx={{ mb: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <DateTimePicker
              onChange={(event) => {
                if (event) setStart(event);
              }}
              value={start}
              label={`${t("startLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
            <ArrowForward />
            <DateTimePicker
              onChange={(event) => {
                if (event) setEnd(event);
              }}
              value={end}
              label={`${t("endLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              mb: 2,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {colorOptions.map((innerColor) => {
              return (
                <ColorButton
                  // borderWidth={innerColor === color ? 3 : 0}
                  selected={innerColor === color}
                  key={innerColor}
                  color={innerColor}
                  onChange={(color) => setColor(color)}
                  size={24}
                />
              );
            })}
          </Box>
          <Box>
            <Button fullWidth={true} variant={"contained"} type={"submit"}>
              {t("createButtonText")}
            </Button>
          </Box>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (start > end) {
      setError(`${t("apartment.startDateBeforeEndDateError")}`);
      return false;
    }

    let customReservationCreationBody = {
      start,
      end,
      title,
      color,
    } as CustomReservationCreationBody;

    postCustomReservation(customReservationCreationBody)
      .then(() => {
        dispatch(getCustomReservationsAction());
        emitSnackbarEvent({
          message: `${t("customReservation.createdSnackbarMessage")}`,
          severity: "success",
        });
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
