import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import { RootState } from "../..";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useUser from "../../hooks/use-user";

import AutoSizer from "react-virtualized-auto-sizer";
import CustomReservationListItem from "./custom-reservation-list-item";
import { CustomReservation } from "../../entities/custom-reservation";
import { getTodayNoTime } from "../../functions/date-functions";
import { sortByDate } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
export default function CustomReservationList() {
  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const [showExpired, setShowExpired] = useState<boolean>(false);

  const customReservations = useSelector((state: RootState) => {
    return state.customReservationsState.customReservations;
  });
  const { t } = useTranslation();
  const [processedCustomReservations, setProcessedCustomReservations] =
    useState<CustomReservation[]>([]);

  useEffect(() => {
    setProcessedCustomReservations(
      [...customReservations]
        .filter((customReservation) => {
          if (
            !showExpired &&
            new Date(customReservation.end) < getTodayNoTime()
          )
            return false;
          return true;
        })
        .sort((a, b) => sortByDate(a.start, b.start, SortType.ASC))
    );
  }, [customReservations, showExpired]);

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showExpired}
              onChange={(event) => setShowExpired(event.target.checked)}
            />
          }
          label={`${t("showExpiredLabel")}`}
          labelPlacement="end"
        />
      </Stack>
      <Box
        sx={{
          overflowY: "auto",
          height: "350px",
          maxHeight: "350px",
          width: "100%",
        }}
      >
        {processedCustomReservations.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText primary={`${t("noReservationsListItemText")}`} />
            </ListItem>
          </List>
        ) : (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  itemSize={60}
                  height={height}
                  itemCount={processedCustomReservations.length}
                  width={width}
                >
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        )}
      </Box>
    </>
  );
  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    let item = processedCustomReservations[index];
    return <CustomReservationListItem customReservation={item} style={style} />;
  }
}
