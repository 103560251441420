import React, { CSSProperties, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { DirectionsCar } from "@mui/icons-material";
import { CarReservation } from "../../entities/car-reservation";
import { sortByDate } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import useMatchApartment from "../../hooks/use-match-apartment";
import useGetApartmentIdentifierString from "../../hooks/use-get-apartment-identifier-string";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { deleteCarReservationById } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function CarReservationListItem({
  carReservation,
  style,
}: {
  carReservation: CarReservation;
  style: CSSProperties;
}) {
  const getApartmentIdentifierString = useGetApartmentIdentifierString();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const dispatch = useDispatch();

  const emitSnackbarEvent = useGlobalSnackbar();

  const { t } = useTranslation();

  let date = new Date(carReservation.date);

  return (
    <ListItem
      key={carReservation.id}
      divider={true}
      style={style}
      secondaryAction={
        isHousekeeper ||
        (user && user.id === carReservation.apartmentReservation.userId) ? (
          <>
            <DoubleClickDelete
              onClick={() => {
                deleteCarReservationById(carReservation.id)
                  .then((response) => {
                    emitSnackbarEvent({
                      message: `${t("car.reservationDeletedSnackbarMessage")}`,
                      severity: "warning",
                    });
                    dispatch(getCarReservationsAction());
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          </>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar>
          <DirectionsCar />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{
          width: "60%",
          maxHeight: "101%",
          overflow: "auto",
        }}
        primary={carReservation.car.model}
        secondary={`${t("reservedBy")} ${getApartmentIdentifierString(
          carReservation.apartmentReservation.apartmentId
        )} for ${carReservation.guests.join(", ")} (${
          carReservation.guests.length
        } people)`}
      />
      <ListItemText
        style={{
          width: "40%",
        }}
        primary={date.toDateString()}
      />
    </ListItem>
  );
}
