import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { FoodReservation } from "../../entities/food-reservation";
import { FoodReservationType } from "../../enums/food-reservation-type";
import { useTranslation } from "react-i18next";
import {
  Apartment,
  BreakfastDining,
  DinnerDining,
  LunchDining,
  Restaurant,
} from "@mui/icons-material";
import useGetApartmentIdentifierString from "../../hooks/use-get-apartment-identifier-string";
import { blue } from "@mui/material/colors";

export default function TodaysFoodReservations() {
  const { t } = useTranslation();

  const getApartmentIdentifierString = useGetApartmentIdentifierString();

  const foodReservations = useSelector(
    (state: RootState) => state.foodState.foodReservations
  );

  const [breakfasts, setBreakfasts] = useState<FoodReservation[]>([]);
  const [lunches, setLunches] = useState<FoodReservation[]>([]);
  const [dinners, setDinners] = useState<FoodReservation[]>([]);

  useEffect(() => {
    setBreakfasts(
      [...foodReservations].filter((foodReservation) => {
        let today = new Date();
        let foodReservationDate = new Date(foodReservation.date);

        return (
          today.getDate() === foodReservationDate.getDate() &&
          today.getMonth() === foodReservationDate.getMonth() &&
          today.getFullYear() === foodReservationDate.getFullYear() &&
          foodReservation.foodReservationType === FoodReservationType.BREAKFAST
        );
      })
    );
    setLunches(
      [...foodReservations].filter((foodReservation) => {
        let today = new Date();
        let foodReservationDate = new Date(foodReservation.date);

        return (
          today.getDate() === foodReservationDate.getDate() &&
          today.getMonth() === foodReservationDate.getMonth() &&
          today.getFullYear() === foodReservationDate.getFullYear() &&
          foodReservation.foodReservationType === FoodReservationType.LUNCH
        );
      })
    );
    setDinners(
      [...foodReservations].filter((foodReservation) => {
        let today = new Date();
        let foodReservationDate = new Date(foodReservation.date);

        return (
          today.getDate() === foodReservationDate.getDate() &&
          today.getMonth() === foodReservationDate.getMonth() &&
          today.getFullYear() === foodReservationDate.getFullYear() &&
          foodReservation.foodReservationType === FoodReservationType.DINNER
        );
      })
    );
  }, [foodReservations]);

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {t("dashboard.todaysFoodReservationsTitle")}
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "space-around", width: "100%" }}
      >
        <Box sx={{ width: "30%" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Avatar sx={{ mr: 2, height: 56, width: 56 }}>
              <BreakfastDining fontSize={"large"} />
            </Avatar>
            <Stack>
              <Typography> {t("dashboard.breakfastsTitle")}</Typography>
              <Typography> {breakfasts.length}</Typography>
            </Stack>
          </Box>

          <List>
            {breakfasts.map((foodReservation) => {
              return (
                <ListItem dense={true} key={foodReservation.id}>
                  <ListItemIcon>
                    <Apartment />
                  </ListItemIcon>

                  <ListItemText
                    primary={`${getApartmentIdentifierString(
                      foodReservation.apartmentReservation.apartmentId
                    )}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ width: "30%" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Avatar sx={{ mr: 2, height: 56, width: 56 }}>
              <LunchDining fontSize={"large"} />
            </Avatar>
            <Stack>
              <Typography> {t("dashboard.lunchesTitle")}</Typography>
              <Typography> {lunches.length}</Typography>
            </Stack>
          </Box>

          <List>
            {lunches.map((foodReservation) => {
              return (
                <ListItem dense={true} key={foodReservation.id}>
                  <ListItemIcon>
                    <Apartment />
                  </ListItemIcon>

                  <ListItemText
                    primary={`${getApartmentIdentifierString(
                      foodReservation.apartmentReservation.apartmentId
                    )}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ width: "30%" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Avatar sx={{ mr: 2, height: 56, width: 56 }}>
              <DinnerDining fontSize={"large"} />
            </Avatar>
            <Stack>
              <Typography> {t("dashboard.dinnersTitle")}</Typography>
              <Typography> {dinners.length}</Typography>
            </Stack>
          </Box>

          <List>
            {lunches.map((foodReservation) => {
              return (
                <ListItem dense={true} key={foodReservation.id}>
                  <ListItemIcon>
                    <Apartment />
                  </ListItemIcon>

                  <ListItemText
                    primary={`${getApartmentIdentifierString(
                      foodReservation.apartmentReservation.apartmentId
                    )}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </>
  );
}
