import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Alert,
  IconButton,
  IconButtonClasses,
  IconButtonProps,
  IconButtonPropsColorOverrides,
  PropTypes,
  Snackbar,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { Delete, DeleteForever } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function DoubleClickDelete({
  onClick,
  sx,
  firstColor = "error",
  secondColor = "error",
}: {
  onClick: any;
  sx?: SxProps<Theme>;
  firstColor?: IconButtonProps["color"];
  secondColor?: IconButtonProps["color"];
}) {
  const [firstClick, setFirstClick] = useState<boolean>(true);

  const timeoutRef = useRef<any>(null);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <Tooltip
        title={
          firstClick
            ? `${t("tooltips.delete")}`
            : `${t("tooltips.clickAgainToDelete")}`
        }
      >
        <IconButton
          sx={sx}
          color={firstClick ? firstColor : secondColor}
          onClick={(event) => {
            if (firstClick) {
              setFirstClick(false);
              timeoutRef.current = setTimeout(() => {
                setFirstClick(true);
              }, 5000);
            } else {
              onClick();
            }
          }}
        >
          {firstClick ? (
            <Delete />
          ) : (
            <>
              <DeleteForever />
            </>
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}
