import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { Apartment } from "../../entities/apartment";
import { Office } from "../../entities/office";
import { useTranslation } from "react-i18next";

export type OfficeOption = {
  label: string;
  id: number;
};

export default function OfficeSelect({
  selectedOfficeOption,
  estSelectedOfficeOption,
  officeFilterFunction,
  label,
  autoFocus,
  required = false,
}: {
  selectedOfficeOption: OfficeOption | null;
  estSelectedOfficeOption: Dispatch<SetStateAction<OfficeOption | null>>;
  officeFilterFunction?: (office: Office) => boolean;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
}) {
  const { t } = useTranslation();
  const officeOptions = useSelector((state: RootState) =>
    officeFilterFunction
      ? state.officesState.offices
          .filter(officeFilterFunction)
          .map((apartment) => {
            return { label: apartment.name, id: apartment.id };
          })
      : state.officesState.offices.map((apartment) => {
          return { label: apartment.name, id: apartment.id };
        })
  );

  return (
    <Autocomplete
      value={selectedOfficeOption}
      onChange={(event, value) => {
        estSelectedOfficeOption(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      options={officeOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ? label : `${t("officeLabel")}`}
          autoFocus={autoFocus}
        />
      )}
    />
  );
}
