import React from "react";
import useMatchApartment from "./use-match-apartment";

export default function useGetApartmentIdentifierString(): (
  apartmentId: number
) => string {
  const matchApartment = useMatchApartment();

  function getApartmentIdentifierString(apartmentId: number) {
    let matchedApartment = matchApartment(apartmentId);
    return matchedApartment ? matchedApartment.name : apartmentId.toString();
  }

  return getApartmentIdentifierString;
}
