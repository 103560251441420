import { User } from "./user";
import { Car } from "./car";
import { ApartmentReservation } from "./apartment-reservation";

export class CarReservation {
  constructor(carReservation: CarReservation) {
    this.id = carReservation.id;
    this.date = new Date(carReservation.date);
    this.apartmentReservationId = carReservation.apartmentReservationId;
    this.apartmentReservation = carReservation.apartmentReservation;
    this.carId = carReservation.carId;
    this.car = carReservation.car;
    this.createdAt = new Date(carReservation.createdAt);
    this.guests = carReservation.guests;
  }

  id: number;

  date: Date;

  apartmentReservationId: number;

  apartmentReservation: ApartmentReservation;

  carId: number;

  car: Car;

  createdAt: Date;

  guests: string[];
}
