import React, { forwardRef, useMemo } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function ListNavItem({
  linkObject,
}: {
  linkObject: {
    to: string;
    text: string;
    iconComponent: any;
  };
}) {
  let CustomNavLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<NavLinkProps, "to">>(
        (navLinkProps, ref) => {
          const { className: previousClasses } = navLinkProps;
          const elementClasses = previousClasses?.toString() ?? "";
          return (
            <NavLink
              {...navLinkProps}
              ref={ref}
              to={linkObject.to}
              className={({ isActive }) =>
                isActive ? elementClasses + " Mui-selected" : elementClasses
              }
            />
          );
        }
      ),
    [linkObject.to]
  );

  return (
    <ListItemButton component={CustomNavLink}>
      <ListItemIcon>{linkObject.iconComponent}</ListItemIcon>
      <ListItemText primary={linkObject.text} />
    </ListItemButton>
  );
}
