import { Event } from "@mui/icons-material";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCustomReservationsAction } from "../../actions/custom-reservation-actions";
import { CustomReservation } from "../../entities/custom-reservation";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useUser from "../../hooks/use-user";
import { deleteCustomReservationById } from "../../requests/custom-reservations-requests";
import DoubleClickDelete from "../buttons/double-click-delete";

export default function CustomReservationListItem({
  customReservation,
  style,
}: {
  customReservation: CustomReservation;
  style: CSSProperties;
}) {
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const { t } = useTranslation();

  let start = new Date(customReservation.start);
  let end = new Date(customReservation.end);
  return (
    <ListItem
      style={style}
      key={customReservation.id}
      secondaryAction={
        isHousekeeper ? (
          <>
            <DoubleClickDelete
              onClick={() => {
                deleteCustomReservationById(customReservation.id)
                  .then((response) => {
                    emitSnackbarEvent({
                      message: `${t(
                        "customReservation.deletedSnackbarMessage"
                      )}`,
                      severity: "warning",
                    });
                    dispatch(getCustomReservationsAction());
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          </>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar>
          <Event />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{
          width: "60%",
        }}
        primary={customReservation.title}
        secondary={`${t("reservationSlashEventReservedBy")}: ${
          customReservation.user.username
        }`}
      />
      <ListItemText
        style={{
          width: "40%",
        }}
        primary={
          start.getTime() === end.getTime()
            ? start.toDateString()
            : `${start.toUTCString()} ${t("until")} ${end.toUTCString()}`
        }
      />
    </ListItem>
  );
}
