import React, { useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarEvent } from "../../reducers/misc-reducer";

export default function GlobalSnackbar() {
  const snackbarEvent = useSelector(
    (state: RootState) => state.miscState.snackbarEvent
  );

  // for consistency
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (snackbarEvent) {
      setSeverity(snackbarEvent.severity);
      setMessage(snackbarEvent.message);
    }
  }, [snackbarEvent]);

  const dispatch = useDispatch();

  return (
    <>
      <Snackbar
        sx={{ position: "absolute" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!snackbarEvent}
        autoHideDuration={5000}
        onClose={() => {
          dispatch(setSnackbarEvent(null));
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </>
  );
}
