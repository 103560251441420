import React, { useMemo, useState } from "react";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Login from "./components/login/login";
import { useSelector } from "react-redux";
import { RootState } from "./index";
import Main from "./components/main";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import "./i18n/config";
import CustomHelmet from "./components/custom-helmet";
// import LandingPage from "./components/landing-page/landing-page";

function App() {
  const user = useSelector((state: RootState) => state.authorizationState.user);

  const mode = useSelector((state: RootState) => state.themeState.mode);

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode,
      },
    });
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomHelmet />
        <CssBaseline />

        {user ? <Main /> : <Login />}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
