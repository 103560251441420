import { Dispatch } from "redux";
import { setFoodReservations } from "../reducers/food-reducer";
import { getFoodReservations } from "../requests/food-requests";

export function getFoodReservationsAction() {
  return (dispatch: Dispatch) => {
    getFoodReservations()
      .then((response) => dispatch(setFoodReservations(response.data.data)))
      .catch((error) => console.error(error));
  };
}
