import React, { useEffect, useState } from "react";
import useTitle from "../../hooks/use-title";
import {
  Box,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle, Close, HelpOutline, OpenInNew } from "@mui/icons-material";
import ApartmentList from "./apartment-list";
import { useLocation, useNavigate } from "react-router-dom";
import ApartmentCreateDialog from "./apartment-create-dialog";
import ApartmentReservationList from "./apartment-reservation-list";
import ApartmentReservationsCalendar from "./apartment-reservations-calendar";
import ApartmentReservationCreateDialog from "./apartment-reservation-create-dialog";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { useTranslation } from "react-i18next";

export default function Apartments() {
  const { t } = useTranslation();
  useTitle(`${t("apartment.title")}`);

  const location = useLocation();
  const navigate = useNavigate();

  const isHousekeeper = useIsHousekeeper();

  const [
    createApartmentReservationDialogOpen,
    setCreateApartmentReservationDialogOpen,
  ] = useState<boolean>(false);
  const [createApartmentDialogOpen, setCreateApartmentDialogOpen] =
    useState<boolean>(false);
  const [fullscreenCalendarDialogOpen, setFullscreenCalendarDialogOpen] =
    useState<boolean>(false);
  const [hideHousekeeperEvents, setHideHousekeeperEvents] =
    useState<boolean>(false);

  useEffect(() => {
    if (location && location.pathname.includes("new-reservation"))
      setCreateApartmentReservationDialogOpen(true);
    else if (location && location.pathname.includes("new-apartment"))
      setCreateApartmentDialogOpen(true);
    else if (location && location.pathname.includes("fullscreen-calendar"))
      setFullscreenCalendarDialogOpen(true);
    else {
      setCreateApartmentReservationDialogOpen(false);
      setCreateApartmentDialogOpen(false);
      setFullscreenCalendarDialogOpen(false);
    }
  }, [location]);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>{renderCalendar()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {t("apartment.reservationListTitle")}
                </Typography>
              </Stack>
              <Tooltip title={`${t("apartment.newReservationTooltip")}`}>
                <IconButton
                  onClick={() => setCreateApartmentReservationDialogOpen(true)}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>

            <ApartmentReservationList />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {t("apartment.listTitle")}
                </Typography>
              </Stack>
              {isHousekeeper && (
                <Tooltip title={`${t("apartment.newTooltip")}`}>
                  <IconButton
                    onClick={() => setCreateApartmentDialogOpen(true)}
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            <ApartmentList />
          </Paper>
        </Grid>
      </Grid>
      {createApartmentReservationDialogOpen && (
        <ApartmentReservationCreateDialog
          initialStart={new Date()}
          initialEnd={new Date()}
          onClose={() => setCreateApartmentReservationDialogOpen(false)}
        />
      )}
      {createApartmentDialogOpen && (
        <ApartmentCreateDialog
          onClose={() => setCreateApartmentDialogOpen(false)}
        />
      )}
      {fullscreenCalendarDialogOpen && (
        <Dialog fullScreen open={fullscreenCalendarDialogOpen}>
          <DialogContent>{renderCalendar()}</DialogContent>
        </Dialog>
      )}
    </Container>
  );

  function renderCalendar() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {t("apartment.reservationCalenderTitle")}
            </Typography>
            {!fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.openInFullscreen")}`}>
                <IconButton
                  onClick={() => {
                    navigate("fullscreen-calendar");
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            {/* <Tooltip title={`${t("tooltips.calendarHelp")}`}> */}
            {/*   <IconButton> */}
            {/*     <HelpOutline /> */}
            {/*   </IconButton> */}
            {/* </Tooltip> */}
            <Tooltip title={`${t("apartment.newReservationTooltip")}`}>
              <IconButton
                onClick={() => setCreateApartmentReservationDialogOpen(true)}
              >
                <AddCircle />
              </IconButton>
            </Tooltip>
            {fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.close")}`}>
                <IconButton
                  onClick={() => setFullscreenCalendarDialogOpen(false)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideHousekeeperEvents}
                onChange={(event) =>
                  setHideHousekeeperEvents(event.target.checked)
                }
              />
            }
            label={`${t("hideHousekeeperEventsLabel")}`}
            labelPlacement="end"
          />
        </Box>
        <Box sx={{ height: fullscreenCalendarDialogOpen ? "90%" : 500 }}>
          <ApartmentReservationsCalendar
            hideHousekeeperEvents={hideHousekeeperEvents}
          />
        </Box>
      </>
    );
  }
}
