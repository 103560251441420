import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { Apartment } from "../../entities/apartment";
import { useTranslation } from "react-i18next";

export type ApartmentOption = {
  label: string;
  id: number;
};

export default function ApartmentSelect({
  selectedApartmentOption,
  setSelectedApartmentOption,
  apartmentFilterFunction,
  label,
  required = false,
}: {
  selectedApartmentOption: ApartmentOption | null;
  setSelectedApartmentOption: Dispatch<SetStateAction<ApartmentOption | null>>;
  apartmentFilterFunction?: (apartment: Apartment) => boolean;
  label?: string;
  required?: boolean;
}) {
  const { t } = useTranslation();
  const apartmentOptions = useSelector((state: RootState) =>
    apartmentFilterFunction
      ? state.apartmentsState.apartments
          .filter(apartmentFilterFunction)
          .map((apartment) => {
            return { label: apartment.name, id: apartment.id };
          })
      : state.apartmentsState.apartments.map((apartment) => {
          return { label: apartment.name, id: apartment.id };
        })
  );

  return (
    <Autocomplete
      value={selectedApartmentOption}
      onChange={(event, value) => {
        setSelectedApartmentOption(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      options={apartmentOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ? label : `${t("apartmentLabel")}`}
        />
      )}
    />
  );
}
