import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";

import { sortByDate } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import ApartmentReservationListItem from "./apartment-reservation-list-item";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { useTranslation } from "react-i18next";
import { getTodayNoTime } from "../../functions/date-functions";

export default function ApartmentReservationList() {
  const apartmentReservations = useSelector(
    (state: RootState) => state.apartmentsState.apartmentReservations
  );

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const [showOthers, setShowOthers] = useState<boolean>(isHousekeeper);
  const [showExpired, setShowExpired] = useState<boolean>(false);

  const [processedApartmentReservations, setProcessedApartmentReservations] =
    useState<ApartmentReservation[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedApartmentReservations(
      [...apartmentReservations]
        .filter((apartmentReservation) => {
          if (
            !showExpired &&
            new Date(apartmentReservation.end) < getTodayNoTime()
          )
            return false;
          if (!showOthers && user && user.id !== apartmentReservation.userId)
            return false;
          return true;
        })
        .sort((a, b) => sortByDate(a.start, b.start, SortType.ASC))
    );
  }, [apartmentReservations, showOthers, showExpired]);

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showExpired}
              onChange={(event) => setShowExpired(event.target.checked)}
            />
          }
          label={`${t("showExpiredLabel")}`}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showOthers}
              onChange={(event) => setShowOthers(event.target.checked)}
            />
          }
          label={`${t("showOthersLabel")}`}
          labelPlacement="end"
        />
      </Stack>
      <Box
        sx={{
          overflowY: "auto",
          height: "350px",
          maxHeight: "350px",
          width: "100%",
        }}
      >
        {processedApartmentReservations.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText primary={`${t("noReservationsListItemText")}`} />
            </ListItem>
          </List>
        ) : (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  itemSize={60}
                  height={height}
                  itemCount={processedApartmentReservations.length}
                  width={width}
                >
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        )}
      </Box>
    </>
  );

  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    return (
      <ApartmentReservationListItem
        key={index}
        style={style}
        apartmentReservation={processedApartmentReservations[index]}
      />
    );
  }
}
