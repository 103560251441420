import React from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export default function useLogout() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "refreshToken",
  ]);

  const dispatch = useDispatch();

  function logout() {
    removeCookie("token", { path: "/" });
    removeCookie("refreshToken", { path: "/" });
    dispatch({ type: "LOGOUT_SUCCESS" });
  }

  return logout;
}
