import { User } from "./user";
import { Office } from "./office";

export class OfficeReservation {
  constructor(officeReservation: OfficeReservation) {
    this.id = officeReservation.id;
    this.start = new Date(officeReservation.start);
    this.end = new Date(officeReservation.end);
    this.userId = officeReservation.userId;
    this.user = officeReservation.user;
    this.officeId = officeReservation.officeId;
    this.office = officeReservation.office;
    this.createdAt = new Date(officeReservation.createdAt);
  }

  id: number;

  start: Date;

  end: Date;

  userId: number;

  user: User;

  officeId: number;

  office: Office;

  createdAt: Date;
}
