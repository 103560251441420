import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { Apartment } from "../../entities/apartment";
import { Role } from "../../enums/role";
import { useTranslation } from "react-i18next";

export type RoleOption = {
  label: string;
  role: Role;
};

const roleOptions = Object.values(Role).map((roleValue) => {
  return { label: roleValue, role: roleValue } as RoleOption;
});

export default function RoleSelect({
  selectedRoleOption,
  setSelectedRoleOption,
  label,
  autoFocus,
  required = false,
}: {
  selectedRoleOption: RoleOption | null;
  setSelectedRoleOption: Dispatch<SetStateAction<RoleOption | null>>;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      value={selectedRoleOption}
      onChange={(event, value) => {
        setSelectedRoleOption(value);
      }}
      isOptionEqualToValue={(option, value) => option.role === value.role}
      disablePortal
      options={roleOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          required={required}
          label={label ? label : `${t("roleLabel")}`}
        />
      )}
    />
  );
}
