import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  Apartment as ApartmentIcon,
  HelpOutline,
  MeetingRoom,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { errorString } from "../../functions/error-functions";
import { Office } from "../../entities/office";
import { OfficeUpdateBody } from "../../request-bodies/office-update-body";
import { putOfficeById } from "../../requests/office-requests";
import { getOfficesAction } from "../../actions/office-actions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function OfficeEditDialog({
  onClose,
  office,
}: {
  onClose: () => any;
  office: Office;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [name, setName] = useState<string>(office.name);
  const [number, setNumber] = useState<number>(office.number);

  const emitSnackbarEvent = useGlobalSnackbar();

  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <MeetingRoom />
          </Avatar>
          <Typography>{t("office.editDialogTitle")}</Typography>
        </Box>
        <Box>
          {/* <Tooltip title={`${t("tooltips.editHelp")}`}> */}
          {/*   <IconButton> */}
          {/*     <HelpOutline /> */}
          {/*   </IconButton> */}
          {/* </Tooltip> */}
          <Tooltip title={`${t("tooltips.close")}`}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            sx={{ mb: 2 }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={`${t("office.nameLabel")}`}
            required={true}
            fullWidth={true}
          />

          <TextField
            sx={{ mb: 2 }}
            value={number}
            type={"number"}
            onChange={(event) => setNumber(parseInt(event.target.value))}
            label={`${t("office.numberLabel")}`}
            required={true}
            fullWidth={true}
          />

          <Button type={"submit"} fullWidth={true} variant={"contained"}>
            {t("updateButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    let officeUpdateBody = {
      name: name,
      number: number,
    } as OfficeUpdateBody;

    putOfficeById(office.id, officeUpdateBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("office.updatedSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getOfficesAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
