import { CustomReservation } from "../entities/custom-reservation";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import axios, { AxiosResponse } from "axios";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import {
  deleteCustomReservationByIdEndpoint,
  getCustomReservationsEndpoint,
  postCustomReservationEndpoint,
} from "./endpoints";
import { CustomReservationCreationBody } from "../request-bodies/custom-reservation-creation-body";

export function getCustomReservations(): Promise<
  AxiosResponse<ReadResponseBody<CustomReservation[]>>
> {
  return axios.get(getCustomReservationsEndpoint);
}

export function postCustomReservation(
  customReservationCreationBody: CustomReservationCreationBody
): Promise<AxiosResponse<CreateResponseBody<CustomReservation>>> {
  return axios.post(
    postCustomReservationEndpoint,
    customReservationCreationBody
  );
}

export function deleteCustomReservationById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteCustomReservationByIdEndpoint(id));
}
