import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { Apartment } from "../../entities/apartment";
import {
  AddCircle,
  Apartment as ApartmentIcon,
  DomainDisabled,
  Edit,
} from "@mui/icons-material";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteUserById } from "../../requests/user-requests";
import { getUsersAction } from "../../actions/user-actions";
import { errorString } from "../../functions/error-functions";
import { deleteApartmentById } from "../../requests/apartment-requests";
import { getApartmentsAction } from "../../actions/apartment-actions";
import UserEditDialog from "../users/user-edit-dialog";
import ApartmentEditDialog from "./apartment-edit-dialog";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { sortByNumber } from "../../functions/sort-functions";

export default function ApartmentList() {
  const apartments = useSelector(
    (state: RootState) => state.apartmentsState.apartments
  );

  const user = useUser();

  const dispatch = useDispatch();

  const isHousekeeper = useIsHousekeeper();

  const [editingApartment, setEditingApartment] = useState<Apartment | null>(
    null
  );

  const [editingDialogOpen, setEditingDialogOpen] = useState<boolean>(false);

  const emitSnackbarEvent = useGlobalSnackbar();

  useEffect(() => {
    setProcessedApartments(
      [...apartments].sort((a, b) => sortByNumber(a.id, b.id))
    );
  }, [apartments]);

  const [processedApartments, setProcessedApartments] = useState<Apartment[]>(
    []
  );
  const { t } = useTranslation();

  return (
    <>
      <List sx={{ overflowY: "auto", height: "350px" }}>
        {processedApartments.length === 0 && (
          <ListItem>
            <ListItemText
              primary={`${t("apartment.noApartmentsListItemText")}`}
            />
          </ListItem>
        )}
        {processedApartments.map((apartment) => {
          let ownerString =
            apartment.owners.length > 0
              ? `${t("ownedBy")}: ${apartment.owners
                  .map((owner) => owner.username)
                  .join(", ")}`
              : "";
          let reservableByString =
            apartment.users.length > 0
              ? `${t("reservableBy")}: ${apartment.users
                  .map((user) => user.username)
                  .join(", ")}`
              : "";

          // let secondaryString = `${ownerString} ${
          //   ownerString && reservableByString && !apartment.ownerExclusive
          //     ? ";"
          //     : ""
          // } ${!apartment.ownerExclusive ? reservableByString : ""}`;
          let secondaryString = `${ownerString} `;
          return (
            <ListItem
              divider={true}
              key={apartment.id}
              secondaryAction={
                isHousekeeper ? (
                  <>
                    <Tooltip title={`${t("tooltips.edit")}`}>
                      <IconButton
                        onClick={() => {
                          setEditingApartment(apartment);
                          setEditingDialogOpen(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <DoubleClickDelete
                      onClick={() => {
                        deleteApartmentById(apartment.id)
                          .then((response) => {
                            emitSnackbarEvent({
                              message: `${t(
                                "apartment.deletedSnackbarMessage"
                              )}`,
                              severity: "warning",
                            });
                            dispatch(getApartmentsAction());
                          })
                          .catch((error) =>
                            emitSnackbarEvent({
                              message: errorString(error),
                              severity: "error",
                            })
                          );
                      }}
                    />
                  </>
                ) : null
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {apartment.inactive ? <DomainDisabled /> : <ApartmentIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${apartment.name}, ${apartment.number}`}
                secondary={secondaryString}
              />
            </ListItem>
          );
        })}
      </List>
      {editingDialogOpen && editingApartment && (
        <ApartmentEditDialog
          onClose={() => setEditingDialogOpen(false)}
          apartment={editingApartment}
        />
      )}
    </>
  );
}
