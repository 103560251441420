import { Apartment } from "./apartment";
import { User } from "./user";
import { CarReservation } from "./car-reservation";
import { FoodReservation } from "./food-reservation";

export class ApartmentReservation {
  constructor(apartmentReservation: ApartmentReservation) {
    this.id = apartmentReservation.id;
    this.start = new Date(apartmentReservation.start);
    this.end = new Date(apartmentReservation.end);
    this.userId = apartmentReservation.userId;
    this.user = apartmentReservation.user;
    this.apartmentId = apartmentReservation.apartmentId;
    this.apartment = apartmentReservation.apartment;
    this.carReservations = apartmentReservation.carReservations;
    this.foodReservations = apartmentReservation.foodReservations;
    this.createdAt = new Date(apartmentReservation.createdAt);
    this.guests = apartmentReservation.guests;
  }

  id: number;

  start: Date;

  end: Date;

  userId: number;

  user: User;

  apartmentId: number;

  apartment: Apartment;

  carReservations: CarReservation[];

  foodReservations: FoodReservation[];

  createdAt: Date;

  guests: string[];
}
