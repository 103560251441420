import React, { useState } from "react";
import { Alert, IconButton, Snackbar, Tooltip } from "@mui/material";
import { CheckCircle, Delete, DeleteForever } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function DoubleClickResolve({ onClick }: { onClick: any }) {
  const [firstClick, setFirstClick] = useState<boolean>(true);

  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={
          firstClick
            ? `${t("tooltips.resolve")}`
            : `${t("tooltips.clickAgainToResolve")}`
        }
      >
        <IconButton
          color={firstClick ? "default" : "success"}
          onClick={(event) => {
            if (firstClick) {
              setFirstClick(false);
              setTimeout(() => {
                setFirstClick(true);
              }, 5000);
            } else {
              onClick();
            }
          }}
        >
          {firstClick ? (
            <CheckCircle />
          ) : (
            <>
              <CheckCircle />
            </>
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}
