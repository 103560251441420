import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FoodReservation } from "../entities/food-reservation";

export type FoodState = {
  foodReservations: FoodReservation[];
  foodReservationsFetched: boolean;
};

const initialState = {
  foodReservations: [],
  foodReservationsFetched: false,
} as FoodState;

export const foodStateSlice = createSlice({
  name: "foodState",
  initialState,
  reducers: {
    setFoodReservations(state, action: PayloadAction<FoodReservation[]>) {
      state.foodReservations = action.payload;
      state.foodReservationsFetched = true;
    },
  },
});

export const foodState = foodStateSlice.reducer;
export const { setFoodReservations } = foodStateSlice.actions;
