import { FoodReservation } from "../entities/food-reservation";
import {
  deleteFoodReservationByIdEndpoint,
  getFoodReservationByIdEndpoint,
  getFoodReservationsEndpoint,
  postFoodReservationEndpoint,
  putFoodReservationByIdEndpoint,
} from "./endpoints";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import axios, { AxiosResponse } from "axios";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { FoodReservationCreationBody } from "../request-bodies/food-reservation-creation-body";

export function getFoodReservations(): Promise<
  AxiosResponse<ReadResponseBody<FoodReservation[]>>
> {
  return axios.get(getFoodReservationsEndpoint);
}

export function getFoodReservationById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<FoodReservation>>> {
  return axios.get(getFoodReservationByIdEndpoint(id));
}

export function postFoodReservations(
  foodReservationCreationBodies: FoodReservationCreationBody[]
): Promise<AxiosResponse<CreateResponseBody<FoodReservation>>> {
  return axios.post(postFoodReservationEndpoint, foodReservationCreationBodies);
}

export function putFoodReservationById(
  id: number,
  foodReservation: FoodReservation
): Promise<AxiosResponse<UpdateResponseBody<FoodReservation>>> {
  return axios.put(putFoodReservationByIdEndpoint(id), foodReservation);
}

export function deleteFoodReservationById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteFoodReservationByIdEndpoint(id));
}
