import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderTitle, setTitle } from "../reducers/misc-reducer";

export default function useTitle(title: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(title));
    dispatch(setHeaderTitle(`Ranc De Banes - ${title}`));
    return () => {
      dispatch(setTitle(null));
      dispatch(setHeaderTitle(`Ranc De Banes`));
    };
  }, []);

  function resetTitle(title: string) {
    dispatch(setTitle(title));
    dispatch(setHeaderTitle(`Ranc De Banes - ${title}`));
  }

  return resetTitle;
}
