import React, { CSSProperties, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { Restaurant } from "@mui/icons-material";
import { FoodReservation } from "../../entities/food-reservation";
import {
  sortByDate,
  sortFoodReservation,
} from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useUser from "../../hooks/use-user";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteCarReservationById } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import { errorString } from "../../functions/error-functions";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { deleteFoodReservationById } from "../../requests/food-requests";
import useGetApartmentIdentifierString from "../../hooks/use-get-apartment-identifier-string";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function FoodReservationListItem({
  foodReservation,
  style,
}: {
  foodReservation: FoodReservation;
  style: CSSProperties;
}) {
  const dispatch = useDispatch();

  const getApartmentIdentifierString = useGetApartmentIdentifierString();

  const isHousekeeper = useIsHousekeeper();
  const user = useUser();

  const emitSnackbarEvent = useGlobalSnackbar();

  const { t } = useTranslation();

  return (
    <ListItem
      style={style}
      key={foodReservation.id}
      divider={true}
      // dense={true}
      secondaryAction={
        isHousekeeper ||
        (user && user.id === foodReservation.apartmentReservation.userId) ? (
          <>
            <DoubleClickDelete
              onClick={() => {
                deleteFoodReservationById(foodReservation.id)
                  .then((response) => {
                    emitSnackbarEvent({
                      message: `${t("food.reservationDeletedSnackbarMessage")}`,
                      severity: "warning",
                    });
                    dispatch(getFoodReservationsAction());
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          </>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar>
          <Restaurant />
        </Avatar>
      </ListItemAvatar>
      <ListItemText

        style={{
          width: "60%",
          maxHeight: "101%",
          overflow: "auto",
        }}
        primary={foodReservation.foodReservationType}
        secondary={`${t("reservationMadeBy")} by ${getApartmentIdentifierString(
          foodReservation.apartmentReservation.apartmentId
        )} for ${foodReservation.guests.join(", ")} (${
          foodReservation.guests.length
        } people)
`}
      />
      <ListItemText

        style={{
          width: "40%",
        }}
                primary={new Date(foodReservation.date).toDateString()} />
    </ListItem>
  );
}
