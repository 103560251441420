import React, { FormEvent, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  Delete,
  Remove,
  Restaurant,
  BreakfastDining,
} from "@mui/icons-material";
import { DatePicker, dateRangePickerDayClasses } from "@mui/lab";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { FoodReservationType } from "../../enums/food-reservation-type";
import { FoodReservationCreationBody } from "../../request-bodies/food-reservation-creation-body";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { postFoodReservations } from "../../requests/food-requests";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { dateToTimelessUTCDateKeepValues } from "../../functions/date-functions";
import UserSelect, { UserOption } from "../selects/user-select";
import useUser from "../../hooks/use-user";
import { Guest } from "../../entities/guest";

export default function FoodReservationCreateDialog({
  initialDate,
  onClose,
  initialDates,
}: {
  initialDate: Date;
  initialDates: Date[] | string[];
  onClose: () => any;
}) {
  const navigate = useNavigate();
  const { apartmentReservationId } = useParams();
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(initialDate);
  const user = useUser();
  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(
      user ? { id: user.id, label: user.username } : null
    );
  const [guests, setGuests] = useState<Guest[]>([]);
  const [dateEntries, setDateEntries] = useState<
    { date: Date; breakfast: string[]; lunch: string[]; dinner: string[] }[]
  >(
    initialDates.map((date) => {
      return {
        date: new Date(date),
        breakfast: [],
        lunch: [],
        dinner: [],
      };
    })
  );

  const personalFoodReservations = useSelector((state: RootState) => {
    return state.foodState.foodReservations.filter((foodReservation) => {
      return (
        selectedUserOption &&
        foodReservation.apartmentReservation.userId === selectedUserOption.id
      );
    });
  });

  const apartmentReservation = useSelector((state: RootState) =>
    state.apartmentsState.apartmentReservations.find((apartmentReservation) => {
      return (
        selectedUserOption &&
        apartmentReservation.userId === selectedUserOption.id &&
        dateToTimelessUTCDateKeepValues(apartmentReservation.end).getTime() >=
          dateToTimelessUTCDateKeepValues(date).getTime() &&
        dateToTimelessUTCDateKeepValues(apartmentReservation.start).getTime() <=
          dateToTimelessUTCDateKeepValues(date).getTime()
      );
    })
  );

  useEffect(() => {
    console.log(dateEntries);
  }, [dateEntries]);
  // const [
  //   selectedFoodReservationTypeOption,
  //   setSelectedFoodReservationTypeOption,
  // ] = useState<FoodReservationTypeOption | null>(null);

  const [breakfast, setBreakfast] = useState<boolean>(false);
  const [lunch, setLunch] = useState<boolean>(false);
  const [dinner, setDinner] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  const { t } = useTranslation();
  useEffect(() => {
    if (apartmentReservation) {
      let newGuests = apartmentReservation.guests.map((guestString) => {
        return Guest.fromString(guestString);
      });
      // let guestNames = newGuests.map((guest) => guest.name);

      setGuests(newGuests);
      // setDateEntries(
      //   initialDates.map((date) => {
      //     return {
      //       date: new Date(date),
      //       breakfast: [],
      //       lunch: [],
      //       dinner: [],
      //     };
      //   })
      // );
    }
    // else {
    //   if (selectedUserOption) setGuests([]);
    //   else if (user) setGuests([]);
    // }
  }, [apartmentReservation]);

  useEffect(() => {
    console.log(dateEntries);
    if (dateEntries.length === 1) setDate(dateEntries[0].date);
  }, [dateEntries]);
  useEffect(() => {
    setDateEntries((oldDateEntries) =>
      oldDateEntries.map((dateEntry) => {
        let matchedBreakfastReservation = personalFoodReservations.find(
          (foodReservation) => {
            return (
              foodReservation.foodReservationType ===
                FoodReservationType.BREAKFAST &&
              new Date(foodReservation.date).getTime() ===
                new Date(
                  dateToTimelessUTCDateKeepValues(dateEntry.date)
                ).getTime()
            );
          }
        );
        return Object.assign({}, dateEntry, {
          breakfast: matchedBreakfastReservation
            ? dateEntry.breakfast
            : breakfast
            ? guests.map((guest) => guest.name)
            : [],
        });
      })
    );
  }, [breakfast]);
  useEffect(() => {
    setDateEntries((oldDateEntries) =>
      oldDateEntries.map((dateEntry) => {
        let matchedLunchReservation = personalFoodReservations.find(
          (foodReservation) =>
            foodReservation.foodReservationType === FoodReservationType.LUNCH &&
            new Date(foodReservation.date).getTime() ===
              new Date(
                dateToTimelessUTCDateKeepValues(dateEntry.date)
              ).getTime()
        );
        return Object.assign({}, dateEntry, {
          lunch: matchedLunchReservation
            ? dateEntry.lunch
            : lunch
            ? guests.map((guest) => guest.name)
            : [],
        });
      })
    );
  }, [lunch]);
  useEffect(() => {
    setDateEntries((oldDateEntries) =>
      oldDateEntries.map((dateEntry) => {
        let matchedDinnerReservation = personalFoodReservations.find(
          (foodReservation) =>
            foodReservation.foodReservationType ===
              FoodReservationType.DINNER &&
            new Date(foodReservation.date).getTime() ===
              new Date(
                dateToTimelessUTCDateKeepValues(dateEntry.date)
              ).getTime()
        );
        return Object.assign({}, dateEntry, {
          dinner: matchedDinnerReservation
            ? dateEntry.dinner
            : dinner
            ? guests.map((guest) => guest.name)
            : [],
        });
      })
    );
  }, [dinner]);

  function toggleBreakfast(date: Date, guest: string) {
    let matchedBreakfastReservation = personalFoodReservations.find(
      (foodReservation) => {
        return (
          foodReservation.foodReservationType ===
            FoodReservationType.BREAKFAST &&
          new Date(foodReservation.date).getTime() ===
            new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
        );
      }
    );
    if (matchedBreakfastReservation) return;
    setDateEntries((oldDateEntries) => {
      return oldDateEntries.map((entry) => {
        if (entry.date === date) {
          if (entry.breakfast.includes(guest)) {
            return Object.assign({}, entry, {
              breakfast: entry.breakfast.filter((name) => name !== guest),
            });
          }
          return Object.assign({}, entry, {
            breakfast: [...entry.breakfast, guest],
          });
        }
        return entry;
      });
    });
  }
  function toggleLunch(date: Date, guest: string) {
    let matchedLunchReservation = personalFoodReservations.find(
      (foodReservation) =>
        foodReservation.foodReservationType === FoodReservationType.LUNCH &&
        new Date(foodReservation.date).getTime() ===
          new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
    );
    if (matchedLunchReservation) return;
    setDateEntries((oldDateEntries) => {
      return oldDateEntries.map((entry) => {
        if (entry.date === date) {
          if (entry.lunch.includes(guest)) {
            return Object.assign({}, entry, {
              lunch: entry.lunch.filter((name) => name !== guest),
            });
          }
          return Object.assign({}, entry, {
            lunch: [...entry.lunch, guest],
          });
        }
        return entry;
      });
    });
  }
  function toggleDinner(date: Date, guest: string) {
    let matchedDinnerReservation = personalFoodReservations.find(
      (foodReservation) =>
        foodReservation.foodReservationType === FoodReservationType.DINNER &&
        new Date(foodReservation.date).getTime() ===
          new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
    );
    if (matchedDinnerReservation) return;
    setDateEntries((oldDateEntries) => {
      return oldDateEntries.map((entry) => {
        if (entry.date === date) {
          if (entry.dinner.includes(guest)) {
            return Object.assign({}, entry, {
              dinner: entry.dinner.filter((name) => name !== guest),
            });
          }
          return Object.assign({}, entry, {
            dinner: [...entry.dinner, guest],
          });
        }
        return entry;
      });
    });
  }
  function handleClose() {
    if (apartmentReservationId) navigate("/food");
    setDialogOpened(false);
    onClose();
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        handleClose();
      }}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <Restaurant />
          </Avatar>
          <Typography>{t("food.reservationCreateDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          {user && user.guests && user.guests.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <UserSelect
                selectedUserOption={selectedUserOption}
                setSelectedUserOption={setSelectedUserOption}
                userFilterFunction={(innerUser) => {
                  return (
                    user.guests.some((guest) => guest.id === innerUser.id) ||
                    user.id === innerUser.id
                  );
                }}
                label={"Reservation for user"}
              />
            </Box>
          )}
          {dateEntries.length > 1 || true ? (
            <>{renderDateList()}</>
          ) : (
            <DatePicker
              onChange={(event) => {
                if (event) setDate(event);
              }}
              value={date}
              label={`${t("food.dateLabel")}`}
              renderInput={(props) => (
                <TextField
                  sx={{ mb: 2 }}
                  required={true}
                  fullWidth={true}
                  {...props}
                />
              )}
            />
          )}

          {dateEntries.length > 1 && (
            <Typography>{t("food.reservationChangeAllLabel")}</Typography>
          )}
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  dateEntries.length < 2 &&
                  !!personalFoodReservations.find((foodReservation) => {
                    return (
                      foodReservation.foodReservationType ===
                        FoodReservationType.BREAKFAST &&
                      new Date(foodReservation.date).getTime() ===
                        new Date(
                          dateToTimelessUTCDateKeepValues(date)
                        ).getTime()
                    );
                  })
                }
                checked={breakfast}
                onChange={(event) => setBreakfast(event.target.checked)}
              />
            }
            label={`${t("food.breakfastLabel")}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  dateEntries.length < 2 &&
                  !!personalFoodReservations.find((foodReservation) => {
                    return (
                      foodReservation.foodReservationType ===
                        FoodReservationType.LUNCH &&
                      new Date(foodReservation.date).getTime() ===
                        new Date(
                          dateToTimelessUTCDateKeepValues(date)
                        ).getTime()
                    );
                  })
                }
                checked={lunch}
                onChange={(event) => setLunch(event.target.checked)}
              />
            }
            label={`${t("food.lunchLabel")}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  dateEntries.length < 2 &&
                  !!personalFoodReservations.find((foodReservation) => {
                    return (
                      foodReservation.foodReservationType ===
                        FoodReservationType.DINNER &&
                      new Date(foodReservation.date).getTime() ===
                        new Date(
                          dateToTimelessUTCDateKeepValues(date)
                        ).getTime()
                    );
                  })
                }
                checked={dinner}
                onChange={(event) => setDinner(event.target.checked)}
              />
            }
            label={`${t("food.dinnerLabel")}`}
          />
          {/* {guests.map((guest, index) => { */}
          {/*   return ( */}
          {/*     <Box key={index}> */}
          {/*       <Typography>{guest}</Typography> */}
          {/*       <FormControlLabel */}
          {/*         control={ */}
          {/*           <Checkbox */}
          {/*             disabled={ */}
          {/*               dateEntries.length < 2 && */}
          {/*               !!personalFoodReservations.find((foodReservation) => { */}
          {/*                 return ( */}
          {/*                   foodReservation.foodReservationType === */}
          {/*                     FoodReservationType.BREAKFAST && */}
          {/*                   new Date(foodReservation.date).getTime() === */}
          {/*                     new Date( */}
          {/*                       dateToTimelessUTCDateKeepValues(date) */}
          {/*                     ).getTime() */}
          {/*                 ); */}
          {/*               }) */}
          {/*             } */}
          {/*             checked={breakfast} */}
          {/*             onChange={(event) => setBreakfast(event.target.checked)} */}
          {/*           /> */}
          {/*         } */}
          {/*         label={`${t("food.breakfastLabel")}`} */}
          {/*       /> */}
          {/*       <FormControlLabel */}
          {/*         control={ */}
          {/*           <Checkbox */}
          {/*             disabled={ */}
          {/*               dateEntries.length < 2 && */}
          {/*               !!personalFoodReservations.find((foodReservation) => { */}
          {/*                 return ( */}
          {/*                   foodReservation.foodReservationType === */}
          {/*                     FoodReservationType.LUNCH && */}
          {/*                   new Date(foodReservation.date).getTime() === */}
          {/*                     new Date( */}
          {/*                       dateToTimelessUTCDateKeepValues(date) */}
          {/*                     ).getTime() */}
          {/*                 ); */}
          {/*               }) */}
          {/*             } */}
          {/*             checked={lunch} */}
          {/*             onChange={(event) => setLunch(event.target.checked)} */}
          {/*           /> */}
          {/*         } */}
          {/*         label={`${t("food.lunchLabel")}`} */}
          {/*       /> */}
          {/*       <FormControlLabel */}
          {/*         control={ */}
          {/*           <Checkbox */}
          {/*             disabled={ */}
          {/*               dateEntries.length < 2 && */}
          {/*               !!personalFoodReservations.find((foodReservation) => { */}
          {/*                 return ( */}
          {/*                   foodReservation.foodReservationType === */}
          {/*                     FoodReservationType.DINNER && */}
          {/*                   new Date(foodReservation.date).getTime() === */}
          {/*                     new Date( */}
          {/*                       dateToTimelessUTCDateKeepValues(date) */}
          {/*                     ).getTime() */}
          {/*                 ); */}
          {/*               }) */}
          {/*             } */}
          {/*             checked={dinner} */}
          {/*             onChange={(event) => setDinner(event.target.checked)} */}
          {/*           /> */}
          {/*         } */}
          {/*         label={`${t("food.dinnerLabel")}`} */}
          {/*       /> */}
          {/*     </Box> */}
          {/*   ); */}
          {/* })} */}

          {/*<FoodReservationTypeSelect*/}
          {/*  sx={{ mb: 2 }}*/}
          {/*  selectedFoodReservationTypeOption={*/}
          {/*    selectedFoodReservationTypeOption*/}
          {/*  }*/}
          {/*  setSelectedFoodReservationTypeOption={*/}
          {/*    setSelectedFoodReservationTypeOption*/}
          {/*  }*/}
          {/*  required={true}*/}
          {/*/>*/}

          <Box>
            <Button fullWidth={true} variant={"contained"} type={"submit"}>
              {t("createButtonText")}
            </Button>
          </Box>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function renderDateList() {
    return (
      <List>
        {dateEntries.map((dateEntry, index) => {
          let matchedBreakfastReservation = personalFoodReservations.find(
            (foodReservation) => {
              return (
                foodReservation.foodReservationType ===
                  FoodReservationType.BREAKFAST &&
                new Date(foodReservation.date).getTime() ===
                  new Date(
                    dateToTimelessUTCDateKeepValues(dateEntry.date)
                  ).getTime()
              );
            }
          );
          let matchedLunchReservation = personalFoodReservations.find(
            (foodReservation) =>
              foodReservation.foodReservationType ===
                FoodReservationType.LUNCH &&
              new Date(foodReservation.date).getTime() ===
                new Date(
                  dateToTimelessUTCDateKeepValues(dateEntry.date)
                ).getTime()
          );
          let matchedDinnerReservation = personalFoodReservations.find(
            (foodReservation) =>
              foodReservation.foodReservationType ===
                FoodReservationType.DINNER &&
              new Date(foodReservation.date).getTime() ===
                new Date(
                  dateToTimelessUTCDateKeepValues(dateEntry.date)
                ).getTime()
          );
          return (
            <ListItem
              divider={true}
              key={dateEntry.date.toDateString()}
              secondaryAction={
                index !== 0 ? (
                  <Tooltip title={`${t("food.removeDateTooltip")}`}>
                    <IconButton
                      color={"error"}
                      onClick={() =>
                        setDateEntries((oldDates) =>
                          oldDates.filter(
                            (innerDateEntry) =>
                              innerDateEntry.date !== dateEntry.date
                          )
                        )
                      }
                      edge="end"
                      aria-label="comments"
                    >
                      <Remove />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )
              }
              disablePadding
            >
              <ListItemText
                primary={dateEntry.date.toDateString()}
                disableTypography={true}
                secondary={
                  <Box>
                    {guests
                      .filter((guest) => {
                        if (!guest.start || !guest.end) return true;
                        if (
                          dateToTimelessUTCDateKeepValues(
                            guest.start
                          ).getTime() <=
                            dateToTimelessUTCDateKeepValues(
                              dateEntry.date
                            ).getTime() &&
                          dateToTimelessUTCDateKeepValues(
                            guest.end
                          ).getTime() >=
                            dateToTimelessUTCDateKeepValues(
                              dateEntry.date
                            ).getTime()
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .map((guest, index) => {
                        return (
                          <Box key={index}>
                            <Typography>{guest.name}</Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!!matchedBreakfastReservation}
                                  checked={
                                    matchedBreakfastReservation
                                      ? matchedBreakfastReservation.guests.includes(
                                          guest.name
                                        )
                                      : dateEntry.breakfast.includes(guest.name)
                                  }
                                  onChange={(event) =>
                                    toggleBreakfast(dateEntry.date, guest.name)
                                  }
                                />
                              }
                              label={`${t("food.breakfastLabel")}`}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!!matchedLunchReservation}
                                  checked={
                                    matchedLunchReservation
                                      ? matchedLunchReservation.guests.includes(
                                          guest.name
                                        )
                                      : dateEntry.lunch.includes(guest.name)
                                  }
                                  onChange={(event) =>
                                    toggleLunch(dateEntry.date, guest.name)
                                  }
                                />
                              }
                              label={`${t("food.lunchLabel")}`}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!!matchedDinnerReservation}
                                  checked={
                                    matchedDinnerReservation
                                      ? matchedDinnerReservation.guests.includes(
                                          guest.name
                                        )
                                      : dateEntry.dinner.includes(guest.name)
                                  }
                                  onChange={(event) =>
                                    toggleDinner(dateEntry.date, guest.name)
                                  }
                                />
                              }
                              label={`${t("food.dinnerLabel")}`}
                            />
                          </Box>
                        );
                      })}

                    {/* <FormControlLabel */}
                    {/*   disabled={!!matchedBreakfastReservation} */}
                    {/*   control={ */}
                    {/*     <Checkbox */}
                    {/*       checked={dateEntry.breakfast} */}
                    {/*       onChange={(event) => toggleBreakfast(dateEntry.date)} */}
                    {/*     /> */}
                    {/*   } */}
                    {/*   label={`${t("food.breakfastLabel")}`} */}
                    {/* /> */}
                    {/* <FormControlLabel */}
                    {/*   disabled={!!matchedLunchReservation} */}
                    {/*   control={ */}
                    {/*     <Checkbox */}
                    {/*       checked={dateEntry.lunch} */}
                    {/*       onChange={(event) => toggleLunch(dateEntry.date)}
                    {/*     /> */}
                    {/*   } */}
                    {/*   label={`${t("food.lunchLabel")}`} */}
                    {/* /> */}
                    {/* <FormControlLabel */}
                    {/*   disabled={!!matchedDinnerReservation} */}
                    {/*   control={ */}
                    {/*     <Checkbox */}
                    {/*       checked={dateEntry.dinner} */}
                    {/*       onChange={(event) => toggleDinner(dateEntry.date)} */}
                    {/*     /> */}
                    {/*   } */}
                    {/*   label={`${t("food.dinnerLabel")}`} */}
                    {/* /> */}
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    // if (!breakfast && !lunch && !dinner) {
    //   setError(`${t("food.noFoodReservationTypeSelectedError")}`);
    //   return;
    // }

    if (!date) {
      setError(`${t("food.noDateSelectedError")}`);
      return;
    }

    let foodReservationCreationBodies: FoodReservationCreationBody[] = [];

    console.log(dateEntries.length);
    if (dateEntries.length > 1) {
      for (let dateEntry of dateEntries) {
        let matchedBreakfastReservation = personalFoodReservations.find(
          (foodReservation) => {
            return (
              foodReservation.foodReservationType ===
                FoodReservationType.BREAKFAST &&
              new Date(foodReservation.date).getTime() ===
                new Date(
                  dateToTimelessUTCDateKeepValues(dateEntry.date)
                ).getTime()
            );
          }
        );
        let matchedLunchReservation = personalFoodReservations.find(
          (foodReservation) =>
            foodReservation.foodReservationType === FoodReservationType.LUNCH &&
            new Date(foodReservation.date).getTime() ===
              new Date(
                dateToTimelessUTCDateKeepValues(dateEntry.date)
              ).getTime()
        );
        let matchedDinnerReservation = personalFoodReservations.find(
          (foodReservation) =>
            foodReservation.foodReservationType ===
              FoodReservationType.DINNER &&
            new Date(foodReservation.date).getTime() ===
              new Date(
                dateToTimelessUTCDateKeepValues(dateEntry.date)
              ).getTime()
        );
        if (dateEntry.breakfast.length > 0 && !matchedBreakfastReservation)
          foodReservationCreationBodies.push({
            date: dateToTimelessUTCDateKeepValues(dateEntry.date),
            foodReservationType: FoodReservationType.BREAKFAST,
            guests: dateEntry.breakfast,
            reservedForUserId: selectedUserOption
              ? selectedUserOption.id
              : null,
          });
        if (dateEntry.lunch.length > 0 && !matchedLunchReservation)
          foodReservationCreationBodies.push({
            date: dateToTimelessUTCDateKeepValues(dateEntry.date),
            foodReservationType: FoodReservationType.LUNCH,
            guests: dateEntry.lunch,
            reservedForUserId: selectedUserOption
              ? selectedUserOption.id
              : null,
          });
        if (dateEntry.dinner.length > 0 && !matchedDinnerReservation)
          foodReservationCreationBodies.push({
            date: dateToTimelessUTCDateKeepValues(dateEntry.date),
            foodReservationType: FoodReservationType.DINNER,
            guests: dateEntry.dinner,
            reservedForUserId: selectedUserOption
              ? selectedUserOption.id
              : null,
          });
      }
    } else {
      let matchedBreakfastReservation = personalFoodReservations.find(
        (foodReservation) => {
          return (
            foodReservation.foodReservationType ===
              FoodReservationType.BREAKFAST &&
            new Date(foodReservation.date).getTime() ===
              new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
          );
        }
      );
      let matchedLunchReservation = personalFoodReservations.find(
        (foodReservation) =>
          foodReservation.foodReservationType === FoodReservationType.LUNCH &&
          new Date(foodReservation.date).getTime() ===
            new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
      );
      let matchedDinnerReservation = personalFoodReservations.find(
        (foodReservation) =>
          foodReservation.foodReservationType === FoodReservationType.DINNER &&
          new Date(foodReservation.date).getTime() ===
            new Date(dateToTimelessUTCDateKeepValues(date)).getTime()
      );
      if (dateEntries[0].breakfast.length > 0 && !matchedBreakfastReservation)
        foodReservationCreationBodies.push({
          date: dateToTimelessUTCDateKeepValues(date),
          foodReservationType: FoodReservationType.BREAKFAST,
          guests: dateEntries[0].breakfast,
          reservedForUserId: selectedUserOption ? selectedUserOption.id : null,
        });
      if (dateEntries[0].lunch.length > 0 && !matchedLunchReservation)
        foodReservationCreationBodies.push({
          date: dateToTimelessUTCDateKeepValues(date),
          foodReservationType: FoodReservationType.LUNCH,
          guests: dateEntries[0].lunch,
          reservedForUserId: selectedUserOption ? selectedUserOption.id : null,
        });
      if (dateEntries[0].dinner.length > 0 && !matchedDinnerReservation)
        foodReservationCreationBodies.push({
          date: dateToTimelessUTCDateKeepValues(date),
          foodReservationType: FoodReservationType.DINNER,
          guests: dateEntries[0].dinner,
          reservedForUserId: selectedUserOption ? selectedUserOption.id : null,
        });
    }
    if (foodReservationCreationBodies.length < 1) {
      setError(`${t("food.noFoodReservationsSelected")}`);
      return;
    }
    postFoodReservations(foodReservationCreationBodies)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("food.reservationCreatedSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getFoodReservationsAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
