import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Document } from "../entities/document";

export type DocumentsState = {
  documents: Document[];
  documentsFetched: boolean;
};

const initialState = {
  documents: [],
  documentsFetched: false,
} as DocumentsState;

export const documentsStateSlice = createSlice({
  name: "documentsState",
  initialState,
  reducers: {
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.documents = action.payload;
      state.documentsFetched = true;
    },
  },
});

export const documentsState = documentsStateSlice.reducer;
export const { setDocuments } = documentsStateSlice.actions;
