import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, DirectionsCar } from "@mui/icons-material";
import { CarCreationBody } from "../../request-bodies/car-creation-body";
import { postCar } from "../../requests/car-requests";
import { useDispatch } from "react-redux";
import { getCarsAction } from "../../actions/car-actions";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function CarCreateDialog({ onClose }: { onClose: () => any }) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [model, setModel] = useState<string>();
  const [licensePlate, setLicensePlate] = useState<string>();

  const [error, setError] = useState<string | null>(null);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <DirectionsCar />
          </Avatar>
          <Typography>{t("car.createDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            sx={{ mb: 2 }}
            value={model}
            onChange={(event) => setModel(event.target.value)}
            label={`${t("car.modelLabel")}`}
            required={true}
            fullWidth={true}
          />

          <TextField
            sx={{ mb: 2 }}
            value={licensePlate}
            onChange={(event) => setLicensePlate(event.target.value)}
            label={`${t("car.licensePlateLabel")}`}
            required={true}
            fullWidth={true}
          />

          <Button type={"submit"} fullWidth={true} variant={"contained"}>
            {t("createButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    let carCreationBody = { model, licensePlate } as CarCreationBody;

    postCar(carCreationBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("car.createdSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getCarsAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
