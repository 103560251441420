import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import useTitle from "../../hooks/use-title";
import MixedCalendar from "../mixed/mixed-calendar";
import MixedReservationList from "../mixed/mixed-reservation-list";
import { useTranslation } from "react-i18next";
import { AddCircle, Close, HelpOutline, OpenInNew } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { FoodReservation } from "../../entities/food-reservation";
import { OfficeReservation } from "../../entities/office-reservation";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import { CarReservation } from "../../entities/car-reservation";
import useUser from "../../hooks/use-user";
import { getTodayNoTime } from "../../functions/date-functions";
import CustomReservationList from "../custom-reservations/custom-reservation-list";

export default function Home() {
  const { t } = useTranslation();
  useTitle(`${t("home.title")}`);

  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (location && location.pathname.includes("fullscreen-calendar"))
      setFullscreenCalendarDialogOpen(true);
    else {
      setFullscreenCalendarDialogOpen(false);
    }
  }, [location]);

  const [fullscreenCalendarDialogOpen, setFullscreenCalendarDialogOpen] =
    useState<boolean>(false);

  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [showOthers, setShowOthers] = useState<boolean>(false);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>{renderCalendar()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {t("home.reservationListTitle")}
            </Typography>

            <MixedReservationList
              filter={(reservation) => {
                if (!showExpired) {
                  if (
                    reservation instanceof FoodReservation ||
                    (reservation instanceof CarReservation &&
                      reservation.date < getTodayNoTime())
                  )
                    return false;
                  else if (
                    !(
                      reservation instanceof FoodReservation ||
                      reservation instanceof CarReservation
                    ) &&
                    reservation.start < getTodayNoTime()
                  )
                    return false;
                }
                if (!showOthers) {
                  if (
                    reservation instanceof OfficeReservation ||
                    (reservation instanceof ApartmentReservation &&
                      user &&
                      reservation.userId !== user.id)
                  )
                    return false;
                  else if (
                    (reservation instanceof FoodReservation ||
                      reservation instanceof CarReservation) &&
                    user &&
                    reservation.apartmentReservation.userId !== user.id
                  )
                    return false;
                }
                return true;
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {t("customReservation.listTitle")}
              </Typography>
            </Box>
            <CustomReservationList />
          </Paper>
        </Grid>
      </Grid>
      {fullscreenCalendarDialogOpen && (
        <Dialog fullScreen open={fullscreenCalendarDialogOpen}>
          <DialogContent>{renderCalendar()}</DialogContent>
        </Dialog>
      )}
    </Container>
  );

  function renderCalendar() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {t("home.calendarTitle")}
            </Typography>
            {!fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.openInFullscreen")}`}>
                <IconButton
                  onClick={() => {
                    navigate("/fullscreen-calendar");
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            {/* <Tooltip title={`${t("tooltips.deleteOnlyCalendarHelp")}`}> */}
            {/*   <IconButton> */}
            {/*     <HelpOutline /> */}
            {/*   </IconButton> */}
            {/* </Tooltip> */}
            {fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.close")}`}>
                <IconButton
                  onClick={() => setFullscreenCalendarDialogOpen(false)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box sx={{ height: fullscreenCalendarDialogOpen ? "90%" : 500 }}>
          <MixedCalendar
            showFoodReservations={true}
            showApartmentReservations={true}
            showOfficeReservations={true}
            showCarReservations={true}
            showCustomReservations={true}
          />
        </Box>
      </>
    );
  }
}
