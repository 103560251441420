import React from "react";
import {
  Calendar as BigC,
  momentLocalizer,
  Event,
  SlotInfo,
} from "react-big-calendar";
import moment from "moment";
import "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarDate } from "../../reducers/misc-reducer";
import { CustomReservation } from "../../entities/custom-reservation";
import {
  compareDatesIrrespectiveOfTime,
  dateToTimelessDate,
} from "../../functions/date-functions";

// moment.tz.setDefault("UTC");

const localizer = momentLocalizer(moment);

export default function Calendar({
  events,
  onSelectSlot,
  onSelectEvent,
  customEvent,
}: {
  events: Event[];
  onSelectSlot: (slotInfo: SlotInfo) => any;
  onSelectEvent?: (event: Event) => any;
  customEvent?: ({ event }: { event: Event }) => JSX.Element;
}) {
  const calendarDate = useSelector(
    (state: RootState) => state.miscState.calendarDate
  );

  const dispatch = useDispatch();

  return (
    <BigC
      defaultDate={new Date(calendarDate)}
      date={new Date(calendarDate)}
      // onRangeChange={(range: any) => {
      //   if (range.length) {
      //     dispatch(setCalendarDate(range[0].toUTCString()));
      //   } else if (range.start) {
      //     dispatch(setCalendarDate(range.start.toUTCString()));
      //   }
      // }}
      onNavigate={(newDate) => {
        dispatch(setCalendarDate(newDate.toUTCString()));
      }}
      popup={true}
      selectable
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      dayPropGetter={(date) => {
        let dateObject = new Date(date);
        dateObject = dateToTimelessDate(dateObject);
        let customEventsTouchingDate = events.filter((event) => {
          if (!event.start || !event.end) return false;
          let startTimelessDate = dateToTimelessDate(event.start);
          let endTimelessDate = dateToTimelessDate(event.end);
          return (
            dateObject.getTime() >= startTimelessDate.getTime() &&
            dateObject.getTime() <= endTimelessDate.getTime() &&
            event.resource instanceof CustomReservation
          );
        });
        if (customEventsTouchingDate.length > 0)
          return {
            style: {
              boxShadow: "0px 0px 0px 3px rgba(255,0,0,0.5) inset",
              // boxSizing: "border-box",
            },
          };
        return {};
      }}
      eventPropGetter={(event) => {
        if (event.resource instanceof CustomReservation) {
          return { style: { backgroundColor: event.resource.color } };
        }
        // else if (event.resource instanceof ApartmentReservation) {
        //   return { style: { filter: "opacity(30%)" } };
        // }
        return {};
      }}
      components={customEvent && { event: customEvent }}
    />
  );
}
