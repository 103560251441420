import { Office } from "../entities/office";
import {
  deleteOfficeByIdEndpoint,
  deleteOfficeReservationByIdEndpoint,
  getOfficeByIdEndpoint,
  getOfficeReservationByIdEndpoint,
  getOfficeReservationsEndpoint,
  getOfficesEndpoint,
  postOfficeEndpoint,
  postOfficeReservationEndpoint,
  putOfficeByIdEndpoint,
  putOfficeReservationByIdEndpoint,
} from "./endpoints";
import axios, { AxiosResponse } from "axios";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { OfficeReservation } from "../entities/office-reservation";
import { OfficeCreationBody } from "../request-bodies/office-creation-body";
import { OfficeUpdateBody } from "../request-bodies/office-update-body";
import { OfficeReservationCreationBody } from "../request-bodies/office-reservation-creation-body";

export function getOffices(): Promise<
  AxiosResponse<ReadResponseBody<Office[]>>
> {
  return axios.get(getOfficesEndpoint);
}

export function getOfficeById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<Office>>> {
  return axios.get(getOfficeByIdEndpoint(id));
}

export function postOffice(
  office: OfficeCreationBody
): Promise<AxiosResponse<CreateResponseBody<Office>>> {
  return axios.post(postOfficeEndpoint, office);
}

export function putOfficeById(
  id: number,
  office: OfficeUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<Office>>> {
  return axios.put(putOfficeByIdEndpoint(id), office);
}

export function deleteOfficeById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteOfficeByIdEndpoint(id));
}

export function getOfficeReservations(): Promise<
  AxiosResponse<ReadResponseBody<OfficeReservation[]>>
> {
  return axios.get(getOfficeReservationsEndpoint);
}

export function getOfficeReservationById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<OfficeReservation>>> {
  return axios.get(getOfficeReservationByIdEndpoint(id));
}

export function postOfficeReservation(
  officeReservation: OfficeReservationCreationBody
): Promise<AxiosResponse<CreateResponseBody<OfficeReservation>>> {
  return axios.post(postOfficeReservationEndpoint, officeReservation);
}

export function putOfficeReservationById(
  id: number,
  officeReservation: OfficeReservation
): Promise<AxiosResponse<UpdateResponseBody<OfficeReservation>>> {
  return axios.put(putOfficeReservationByIdEndpoint(id), officeReservation);
}

export function deleteOfficeReservationById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteOfficeReservationByIdEndpoint(id));
}
