import axios, { AxiosResponse } from "axios";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { Document } from "../entities/document";
import {
  deleteDocumentByIdEndpoint,
  getDocumentByIdEndpoint,
  getDocumentByTitleEndpoint,
  getDocumentsEndpoint,
  getDocumentsWithoutHtmlEndpoint,
  postDocumentEndpoint,
  putDocumentByIdEndpoint,
} from "./endpoints";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { DocumentCreationBody } from "../request-bodies/document-creation-body";
import { DocumentUpdateBody } from "../request-bodies/document-update-body";

export function getDocumentsWithoutHtml(): Promise<
  AxiosResponse<ReadResponseBody<Document[]>>
> {
  return axios.get(getDocumentsWithoutHtmlEndpoint);
}

export function getDocuments(): Promise<
  AxiosResponse<ReadResponseBody<Document[]>>
> {
  return axios.get(getDocumentsEndpoint);
}

export function getDocumentById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<Document>>> {
  return axios.get(getDocumentByIdEndpoint(id));
}

export function getDocumentByTitle(
  title: string
): Promise<AxiosResponse<ReadResponseBody<Document>>> {
  return axios.get(getDocumentByTitleEndpoint(title));
}

export function postDocument(
  document: DocumentCreationBody
): Promise<AxiosResponse<CreateResponseBody<Document>>> {
  return axios.post(postDocumentEndpoint, document);
}

export function putDocumentById(
  id: number,
  document: DocumentUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<Document>>> {
  return axios.put(putDocumentByIdEndpoint(id), document);
}

export function deleteDocumentById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteDocumentByIdEndpoint(id));
}
