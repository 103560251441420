import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Problem } from "../entities/problem";

export type ProblemsState = {
  problems: Problem[];
  problemsFetched: boolean;
};

const initialState = { problems: [], problemsFetched: false } as ProblemsState;

export const problemsStateSlice = createSlice({
  name: "problemsState",
  initialState,
  reducers: {
    setProblems(state, action: PayloadAction<Problem[]>) {
      state.problems = action.payload;
      state.problemsFetched = true;
    },
  },
});

export const problemsState = problemsStateSlice.reducer;
export const { setProblems } = problemsStateSlice.actions;
