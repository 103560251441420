import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MixedReservationList from "../mixed/mixed-reservation-list";
import { FoodReservation } from "../../entities/food-reservation";
import { OfficeReservation } from "../../entities/office-reservation";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import { CarReservation } from "../../entities/car-reservation";
import { compareDatesIrrespectiveOfTime } from "../../functions/date-functions";

export default function ReservationsEndingToday() {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {t("dashboard.reservationsEndingTodayTitle")}
      </Typography>
      <MixedReservationList
        filter={(reservation) => {
          if (
            reservation instanceof FoodReservation ||
            reservation instanceof CarReservation
          )
            return false;

          let today = new Date();
          return compareDatesIrrespectiveOfTime(today, reservation.end);
        }}
        emptyMessage={`${t("dashboard.noReservationsEndingToday")}`}
      />
    </>
  );
}
