import React, { FormEvent, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowForward,
  Close,
  Create,
  DirectionsCar,
  PersonAddAlt1,
  PersonRemoveAlt1,
  Save,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import CarSelect from "../selects/car-select";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { CarReservation } from "../../entities/car-reservation";
import { CarReservationCreationBody } from "../../request-bodies/car-reservation-creation-body";
import { postCarReservation } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { dateToTimelessUTCDateKeepValues } from "../../functions/date-functions";
import UserSelect, { UserOption } from "../selects/user-select";
import { Guest } from "../../entities/guest";

export default function CarReservationCreateDialog({
  initialDate,
  onClose,
}: {
  initialDate: Date;
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(initialDate);
  const [selectedCarOption, setSelectedCarOption] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const user = useSelector((state: RootState) => state.authorizationState.user);
  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(
      user ? { id: user.id, label: user.username } : null
    );
  const [guests, setGuests] = useState<string[]>(
    selectedUserOption ? [selectedUserOption.label] : [""]
  );
  const emitSnackbarEvent = useGlobalSnackbar();

  const apartmentReservation = useSelector((state: RootState) =>
    state.apartmentsState.apartmentReservations.find((apartmentReservation) => {
      return (
        selectedUserOption &&
        apartmentReservation.userId === selectedUserOption.id &&
        dateToTimelessUTCDateKeepValues(apartmentReservation.end).getTime() >=
          dateToTimelessUTCDateKeepValues(date).getTime() &&
        dateToTimelessUTCDateKeepValues(apartmentReservation.start).getTime() <=
          dateToTimelessUTCDateKeepValues(date).getTime()
      );
    })
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (selectedUserOption) setGuests([selectedUserOption.label]);
  // }, [selectedUserOption]);

  useEffect(() => {
    console.log(apartmentReservation);
    if (apartmentReservation)
      setGuests(
        apartmentReservation.guests.map((guestString) => {
          return Guest.fromString(guestString).name;
        })
      );
    else {
      if (selectedUserOption) setGuests([selectedUserOption.label]);
      else if (user) setGuests([user.username]);
    }
  }, [apartmentReservation]);
  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        handleClose();
      }}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <DirectionsCar />
          </Avatar>
          <Typography>{t("car.reservationCreateDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <DatePicker
              onChange={(event) => {
                if (event) setDate(event);
              }}
              value={date}
              label={`${t("dateLabel")}`}
              renderInput={(props) => (
                <TextField fullWidth={true} required={true} {...props} />
              )}
            />
          </Box>
          {user && user.guests && user.guests.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <UserSelect
                selectedUserOption={selectedUserOption}
                setSelectedUserOption={setSelectedUserOption}
                userFilterFunction={(innerUser) => {
                  return (
                    user.guests.some((guest) => guest.id === innerUser.id) ||
                    user.id === innerUser.id
                  );
                }}
                label={"Reservation for user"}
              />
            </Box>
          )}
          <Box sx={{ mb: 2 }}>
            <CarSelect
              selectedCarOption={selectedCarOption}
              setSelectedCarOption={setSelectedCarOption}
              required={true}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            {guests.map((guest, index) => {
              return (
                <Stack key={index} direction={"row"} alignItems={"center"}>
                  <TextField
                    size={"small"}
                    fullWidth={false}
                    margin={"normal"}
                    required={true}
                    label={"Guest " + (index + 1)}
                    value={guest}
                    onChange={(event) => {
                      setGuests((old) => {
                        const updatedGuests = [...old];
                        updatedGuests[index] = event.target.value;
                        return updatedGuests;
                      });
                    }}
                  />
                  <Box>
                    <Tooltip title={"Delete guest"}>
                      <IconButton
                        color={"error"}
                        onClick={() => {
                          setGuests((old) => {
                            const updatedGuests = [...old];
                            updatedGuests.splice(index, 1);
                            return updatedGuests;
                          });
                        }}
                      >
                        <PersonRemoveAlt1 />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              );
            })}
            <Box>
              <Tooltip title={"Add guest"}>
                <IconButton
                  onClick={() => {
                    setGuests((old) => {
                      const updatedGuests = [...old, ""];
                      return updatedGuests;
                    });
                  }}
                >
                  <PersonAddAlt1 />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button fullWidth={true} variant={"contained"} type={"submit"}>
              {t("createButtonText")}
            </Button>
          </Box>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (validateCarReservation()) {
      let carReservation = {
        date: dateToTimelessUTCDateKeepValues(date),
        carId: selectedCarOption.id,
        reservedForUserId: selectedUserOption ? selectedUserOption.id : null,
        guests,
      } as CarReservationCreationBody;

      postCarReservation(carReservation)
        .then((response) => {
          emitSnackbarEvent({
            message: `${t("car.reservationCreatedSnackbarMessage")}`,
            severity: "success",
          });
          dispatch(getCarReservationsAction());
          handleClose();
        })
        .catch((error) => setError(errorString(error)));
    }
  }

  function validateCarReservation() {
    if (!selectedCarOption) {
      setError(`${t("car.noCarSelectedError")}`);
      return false;
    }
    setError(null);
    return true;
  }
}
