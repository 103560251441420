import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../index";
import { Role } from "../enums/role";

export default function useIsWorker() {
  const isWorker = useSelector((state: RootState) => {
    if (state.authorizationState.user)
      return state.authorizationState.user.role === Role.WORKER;
    return false;
  });

  return isWorker;
}
