import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import md5 from "md5";
import { LoginBody } from "../../request-bodies/login-body";
import { healthRequest, login } from "../../requests/authentication-requests";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import axios, { AxiosError, AxiosResponse } from "axios";
import { getSelf } from "../../requests/user-requests";
import { setAuthorizationData } from "../../reducers/authorization-reducer";
import { Castle } from "@mui/icons-material";
import {
  errorString,
  isErrorResponseWithData,
} from "../../functions/error-functions";
import { useTranslation } from "react-i18next";
import PasswordInput from "../inputs/password-input";

export default function Login() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "refreshToken",
  ]);

  const dispatch = useDispatch();

  const [error, setError] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (cookies.token) {
      onTokenInCookie(cookies.token)
        .then()
        .catch((error) => {
          removeCookie("token");
          setError(errorString(error));
        });
    }
  }, []);

  return (
    <Container maxWidth={"xs"} sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Paper sx={{ p: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Castle fontSize={"large"} />
          </Box>

          <Typography
            component={"h4"}
            variant={"h4"}
            sx={{ textAlign: "center" }}
          >
            Login
          </Typography>
          <Box component={"form"} onSubmit={handleSubmit}>
            <TextField
              margin={"normal"}
              required={true}
              fullWidth={true}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              id={"username"}
              label={`${t("login.usernameLabel")}`}
              name={"username"}
              autoComplete={"username"}
              autoFocus={true}
            />
            <PasswordInput
              margin={"normal"}
              sx={{ mb: 2 }}
              password={password}
              setPassword={setPassword}
              required={true}
              fullWidth={true}
              name={"password"}
              label={`${t("login.passwordLabel")}`}
              id={"password"}
              autoComplete={"current-password"}
            />
            {/*<TextField*/}
            {/*  margin={"normal"}*/}
            {/*  required={true}*/}
            {/*  fullWidth={true}*/}
            {/*  name={"password"}*/}
            {/*  label={`${t("login.passwordLabel")}`}*/}
            {/*  type={"password"}*/}
            {/*  id={"password"}*/}
            {/*  autoComplete={"current-password"}*/}
            {/*/>*/}
            <Button type={"submit"} fullWidth={true} variant={"contained"}>
              {t("login.signInButtonText")}
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
        </Paper>
      </Box>
    </Container>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let data = new FormData(event.currentTarget);

    await handleLogin(username, password);
  }

  async function handleLogin(username: string, password: string) {
    try {
      let response = await login({ username, password: md5(password) });
      await onSuccessfulLogin(response);
    } catch (e: any) {
      setError(errorString(e));
    }
  }

  async function onTokenInCookie(token: string) {
    let healthResponse = await healthRequest(token);

    setAxiosHeader(token);

    let userResponse = await getSelf();
    dispatch(setAuthorizationData(userResponse.data.data));
  }

  async function onSuccessfulLogin(response: AxiosResponse) {
    let token = response.data.token;
    let refreshToken = response.data.refreshToken;

    let healthResponse = await healthRequest(token);

    setTokenCookies(token, refreshToken);
    setAxiosHeader(token);

    let userResponse = await getSelf();
    dispatch(setAuthorizationData(userResponse.data.data));
  }

  function setTokenCookies(token: string, refreshToken: string) {
    setCookie("token", token, {
      path: "/",
      expires: new Date(Date.now() + 3600000 * 10),
    });
    setCookie("refreshToken", refreshToken, {
      path: "/",
      expires: new Date(Date.now() + 3600000 * 24 * 7),
    });
  }

  function setAxiosHeader(token: string) {
    axios.defaults.headers.common["Authorization"] = token;
  }
}
