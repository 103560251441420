import React from "react";
import { useDispatch } from "react-redux";
import { setSnackbarEvent, SnackbarEvent } from "../reducers/misc-reducer";

export default function useGlobalSnackbar() {
  const dispatch = useDispatch();

  function emitSnackbarEvent(snackbarEvent: SnackbarEvent) {
    dispatch(setSnackbarEvent(snackbarEvent));
  }

  return emitSnackbarEvent;
}
