import { Dispatch } from "redux";
import { getCustomReservations } from "../requests/custom-reservations-requests";
import { setCustomReservations } from "../reducers/custom-reservations-reducer";

export function getCustomReservationsAction() {
  return (dispatch: Dispatch) => {
    getCustomReservations().then((response) =>
      dispatch(setCustomReservations(response.data.data))
    );
  };
}
