import React from "react";
import { Box, ButtonBase, SxProps } from "@mui/material";

export default function ColorButton({
  color,
  size,
  sx,
  onChange,
  selected,
}: {
  color: string;
  size: number;
  sx?: SxProps;
  onChange: (color: string) => any;
  selected: boolean;
}) {
  return (
    <ButtonBase
      onClick={() => {
        onChange(color);
      }}
      sx={{
        border: selected ? "2px solid black" : "",
        backgroundColor: color,
        width: size,
        height: size,
        borderRadius: 1,
        ...sx,
      }}
    />
  );
}
