import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useIsWorker from "../../hooks/use-is-worker";

export default function HelpDialog({ onClose }: { onClose: () => any }) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const isWorker = useIsWorker();
  const isHousekeeper = useIsHousekeeper();
  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{t("helpDialog.title")}</Typography>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        {isWorker ? (
          <OfficeReservationHelp />
        ) : (
          <>
            <ApartmentReservationHelp />
            <Divider />
            <FoodReservationHelp />
            <Divider />
            <CarReservationHelp />
            <Divider />
            <ProblemHelp />
            {isHousekeeper && (
              <>
                <Divider />
                <OfficeReservationHelp />
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

function ApartmentReservationHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>
        {t("helpDialog.apartmentReservationHelpSubtitle")}
      </Typography>
      {/* <Typography>{t("helpDialog.apartmentReservationHelpP1")}</Typography> */}
      <Video src={"/create-apartment-reservation.webm"} />
    </Box>
  );
}

function CarReservationHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>{t("helpDialog.carReservationHelpSubtitle")}</Typography>
      <Video src={"/create-car-reservation.webm"} />
    </Box>
  );
}

function FoodReservationHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>{t("helpDialog.foodReservationHelpSubtitle")}</Typography>
      <Video src={"/create-food-reservation.webm"} />
    </Box>
  );
}

function OfficeReservationHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>{t("helpDialog.officeReservationHelpSubtitle")}</Typography>
      <Video src={"/create-office-reservation.webm"} />
    </Box>
  );
}

function ProblemHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>{t("helpDialog.howToReportAProblemSubtitle")}</Typography>
      <Video src={"/report-problem.webm"} />
    </Box>
  );
}
function DeleteReservationHelp() {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Typography>{t("helpDialog.howToDeleteAReservationSubtitle")}</Typography>
      <Video src={"/create-custom-reservation.webm"} />
    </Box>
  );
}
function Video({ src }: { src: string }) {
  return (
    <video controls loop style={{ objectFit: "fill", width: "100%" }}>
      <source src={src}></source>
    </video>
  );
}
