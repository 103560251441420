import React, { useEffect, useState } from "react";
import { Box, Container, Snackbar, Toolbar } from "@mui/material";
import DrawerLeft from "./nav/drawer-left";
import Router from "./router";
import TopBar from "./top-bar/top-bar";
import useApplicationState from "../hooks/use-application-state";
import EntitySpeedDial from "./speed-dials/entity-speed-dial";
import GlobalSnackbar from "./global-snackbar/global-snackbar";
import { useIdleTimer } from "react-idle-timer";
import useLogout from "../hooks/use-logout";

export default function Main() {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  const [stateFetched] = useApplicationState();
  const logout = useLogout();

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      logout();
    },
    debounce: 500,
  });

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        // overflow: "scroll",
      }}
    >
      <TopBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <DrawerLeft drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          p: 3,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        {stateFetched ? (
          <>
            <Router />
            <EntitySpeedDial />
            <GlobalSnackbar />
          </>
        ) : (
          <div>Fetching state</div>
        )}
      </Box>
    </Box>
  );
}
