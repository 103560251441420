import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";

export default function DocumentEditor({
  html,
  setHtml,
}: {
  html: string;
  setHtml: Dispatch<SetStateAction<string>>;
}) {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  return (
    <Editor
      ref={editorRef}
      editorState={editorState}
      onEditorStateChange={(editorState: EditorState) => {
        setEditorState(editorState);
        setHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }}
    />
  );
}
