import React, { useState } from "react";
import Calendar from "../calendar/calendar";
import { Event, DateRange, SlotInfo } from "react-big-calendar";
import CarReservationCreateDialog from "./car-reservation-create-dialog";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  apartmentReservationsToCalendarEvents,
  carReservationsToCalendarEvents,
  customReservationsToCalendarEvents,
} from "../../functions/calendar-events-functions";
import { apartmentsState } from "../../reducers/apartments-reducer";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { useTranslation } from "react-i18next";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import useUser from "../../hooks/use-user";
import { CarReservation } from "../../entities/car-reservation";
import { deleteCarReservationById } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import {
  Apartment,
  Bookmark,
  DirectionsCar,
  Favorite,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { VariableCustomEvent } from "../mixed/mixed-calendar";

const MS_IN_MINUTE = 60000;

export default function CarReservationsCalendar({
  hideHousekeeperEvents,
}: {
  hideHousekeeperEvents: boolean;
}) {
  const [slotInfo, setSlotInfo] = useState<SlotInfo | null>();
  const user = useUser();

  const carReservationEvents = useSelector((state: RootState) =>
    carReservationsToCalendarEvents(
      state.carsState.carReservations,
      state.apartmentsState.apartments
    )
  );

  const apartmentReservationEvents = useSelector((state: RootState) =>
    apartmentReservationsToCalendarEvents(
      [...state.apartmentsState.apartmentReservations].filter(
        (apartmentReservation) =>
          apartmentReservation.userId === state.authorizationState.user?.id ||
          user?.guests.some(
            (innerUser) => innerUser.id === apartmentReservation.userId
          )
      )
    )
  );
  const customReservationEvents = useSelector((state: RootState) => {
    return customReservationsToCalendarEvents([
      ...state.customReservationsState.customReservations,
    ]);
  });

  return (
    <>
      <Calendar
        onSelectSlot={(slotInfo) => {
          setSlotInfo(slotInfo);
        }}
        events={[
          ...carReservationEvents,
          ...apartmentReservationEvents,
          ...(hideHousekeeperEvents ? [] : customReservationEvents),
        ]}
        customEvent={VariableCustomEvent}
      />
      {slotInfo && (
        <CarReservationCreateDialog
          initialDate={new Date(slotInfo.start)}
          onClose={() => {
            setSlotInfo(null);
          }}
        />
      )}
    </>
  );
}

export function CustomCarReservationEvent({ event }: { event: Event }) {
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const { t } = useTranslation();

  let carReservation: CarReservation = event.resource;

  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <DirectionsCar sx={{ height: 24 }} />
      {user && user.id === carReservation.apartmentReservation.userId && (
        <Bookmark sx={{ height: 24 }} />
      )}
      <Typography
        sx={{
          ml: 1,
          maxWidth: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title}
      </Typography>
      {((hovering && isHousekeeper) ||
        (hovering &&
          user &&
          (user.id === carReservation.apartmentReservation.userId ||
            user.guests.some(
              (innerUser) =>
                innerUser.id === carReservation.apartmentReservation.userId
            )))) && (
        <>
          <DoubleClickDelete
            sx={{ ml: 1, height: 24, width: 24 }}
            onClick={() => {
              deleteCarReservationById(carReservation.id)
                .then((response) => {
                  emitSnackbarEvent({
                    message: `${t("car.reservationDeletedSnackbarMessage")}`,
                    severity: "warning",
                  });
                  dispatch(getCarReservationsAction());
                })
                .catch((error) =>
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  })
                );
            }}
          />
        </>
      )}
    </div>
  );
}
