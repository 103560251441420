import React, { useEffect, useState } from "react";
import { Avatar, IconButton, SxProps } from "@mui/material";
import { stringToColor } from "../../functions/string-functions";
import { RootState } from "../../index";
import useUser from "../../hooks/use-user";

export default function UserAvatar({ sx }: { sx?: SxProps }) {
  const [colorString, setColorString] = useState<string>("");

  const user = useUser();

  useEffect(() => {
    if (user) setColorString(stringToColor(user.username));
  }, [user]);

  if (user)
    return (
      <Avatar sx={{ backgroundColor: colorString, ...sx }}>
        {user.username.substring(0, 1).toUpperCase()}
      </Avatar>
    );

  return <Avatar></Avatar>;
}
