import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CarReservation } from "../entities/car-reservation";
import { Car } from "../entities/car";

export type CarsState = {
  cars: Car[];
  carsFetched: boolean;
  carReservations: CarReservation[];
  carReservationsFetched: boolean;
};

const initialState = {
  cars: [],
  carsFetched: false,
  carReservations: [],
  carReservationsFetched: false,
} as CarsState;

export const carsStateSlice = createSlice({
  name: "carsState",
  initialState,
  reducers: {
    setCars(state, action: PayloadAction<Car[]>) {
      state.cars = action.payload;
      state.carsFetched = true;
    },
    setCarReservations(state, action: PayloadAction<CarReservation[]>) {
      state.carReservations = action.payload;
      state.carReservationsFetched = true;
    },
  },
});

export const carsState = carsStateSlice.reducer;
export const { setCars, setCarReservations } = carsStateSlice.actions;
