let apiUrl = "http://localhost:3000/";
if (process.env.REACT_APP_ENV === "production") {
  apiUrl = "https://app.rancdebanes.com/api/";
}

export const loginEndpoint = `${apiUrl}authentication/login`;

export const healthEndpoint = `${apiUrl}health/`;

//Users

export const getSelfEndpoint = `${apiUrl}users/self`;

export const getUsersEndpoint = `${apiUrl}users/`;

export const postUserEndpoint = `${apiUrl}users/`;

export function putUserByIdEndpoint(userId: number) {
  return `${apiUrl}users/${userId}`;
}
export function toggleUserByIdEndpoint(userId: number) {
  return `${apiUrl}users/${userId}/toggle`;
}

export function deleteUserByIdEndpoint(userId: number) {
  return `${apiUrl}users/${userId}`;
}

//Cars

export const getCarsEndpoint = `${apiUrl}cars/`;

export function getCarByIdEndpoint(id: number) {
  return `${apiUrl}cars/${id}`;
}

export function putCarByIdEndpoint(id: number) {
  return `${apiUrl}cars/${id}`;
}

export function deleteCarByIdEndpoint(id: number) {
  return `${apiUrl}cars/${id}`;
}

export const postCarEndpoint = `${apiUrl}cars/`;

//Car reservations

export const getCarReservationsEndpoint = `${apiUrl}car-reservations/`;

export function getCarReservationByIdEndpoint(id: number) {
  return `${apiUrl}car-reservations/${id}`;
}

export function putCarReservationByIdEndpoint(id: number) {
  return `${apiUrl}car-reservations/${id}`;
}

export function deleteCarReservationByIdEndpoint(id: number) {
  return `${apiUrl}car-reservations/${id}`;
}

export const postCarReservationEndpoint = `${apiUrl}car-reservations/`;

//Documents

export const getDocumentsWithoutHtmlEndpoint = `${apiUrl}documents/without-html`;

export const getDocumentsEndpoint = `${apiUrl}documents/`;

export function getDocumentByIdEndpoint(id: number) {
  return `${apiUrl}documents/${id}`;
}

export function getDocumentByTitleEndpoint(title: string) {
  return `${apiUrl}documents/title/${title}`;
}

export function putDocumentByIdEndpoint(id: number) {
  return `${apiUrl}documents/${id}`;
}

export function deleteDocumentByIdEndpoint(id: number) {
  return `${apiUrl}documents/${id}`;
}

export const postDocumentEndpoint = `${apiUrl}documents/`;

//Offices

export const getOfficesEndpoint = `${apiUrl}offices/`;

export function getOfficeByIdEndpoint(id: number) {
  return `${apiUrl}offices/${id}`;
}

export function putOfficeByIdEndpoint(id: number) {
  return `${apiUrl}offices/${id}`;
}

export function deleteOfficeByIdEndpoint(id: number) {
  return `${apiUrl}offices/${id}`;
}

export const postOfficeEndpoint = `${apiUrl}offices/`;

//Office reservations

export const getOfficeReservationsEndpoint = `${apiUrl}office-reservations/`;

export function getOfficeReservationByIdEndpoint(id: number) {
  return `${apiUrl}office-reservations/${id}`;
}

export function putOfficeReservationByIdEndpoint(id: number) {
  return `${apiUrl}office-reservations/${id}`;
}

export function deleteOfficeReservationByIdEndpoint(id: number) {
  return `${apiUrl}office-reservations/${id}`;
}

export const postOfficeReservationEndpoint = `${apiUrl}office-reservations/`;

//Food reservations

export const getFoodReservationsEndpoint = `${apiUrl}food-reservations/`;

export function getFoodReservationByIdEndpoint(id: number) {
  return `${apiUrl}food-reservations/${id}`;
}

export function putFoodReservationByIdEndpoint(id: number) {
  return `${apiUrl}food-reservations/${id}`;
}

export function deleteFoodReservationByIdEndpoint(id: number) {
  return `${apiUrl}food-reservations/${id}`;
}

export const postFoodReservationEndpoint = `${apiUrl}food-reservations/`;

//Apartments

export const getApartmentsEndpoint = `${apiUrl}apartments/`;

export function getApartmentByIdEndpoint(id: number) {
  return `${apiUrl}apartments/${id}`;
}

export function putApartmentByIdEndpoint(id: number) {
  return `${apiUrl}apartments/${id}`;
}

export function deleteApartmentByIdEndpoint(id: number) {
  return `${apiUrl}apartments/${id}`;
}

export const postApartmentEndpoint = `${apiUrl}apartments/`;

//Apartment reservations

export const getApartmentReservationsEndpoint = `${apiUrl}apartment-reservations/`;

export function getApartmentReservationByIdEndpoint(id: number) {
  return `${apiUrl}apartment-reservations/${id}`;
}

export function putApartmentReservationByIdEndpoint(id: number) {
  return `${apiUrl}apartment-reservations/${id}`;
}

export function deleteApartmentReservationByIdEndpoint(id: number) {
  return `${apiUrl}apartment-reservations/${id}`;
}

export const postApartmentReservationEndpoint = `${apiUrl}apartment-reservations/`;

//Problems

export const getProblemsEndpoint = `${apiUrl}problems/`;

export function getProblemByIdEndpoint(id: number) {
  return `${apiUrl}problems/${id}`;
}

export function putProblemByIdEndpoint(id: number) {
  return `${apiUrl}problems/${id}`;
}

export function deleteProblemByIdEndpoint(id: number) {
  return `${apiUrl}problems/${id}`;
}

export function resolveProblemByIdEndpoint(id: number) {
  return `${apiUrl}problems/${id}/resolve`;
}

export const postProblemEndpoint = `${apiUrl}problems/`;

//Custom reservations
export const getCustomReservationsEndpoint = `${apiUrl}custom-reservations/`;

export function deleteCustomReservationByIdEndpoint(id: number) {
  return `${apiUrl}custom-reservations/${id}`;
}

export const postCustomReservationEndpoint = `${apiUrl}custom-reservations/`;
