import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReservationMode } from "../../enums/reservation-mode";

export type ReservationModeOption = {
  label: string;
  reservationMode: ReservationMode;
};

export const reservationModeOptions = Object.values(ReservationMode).map(
  (value) => {
    return {
      label: value,
      reservationMode: value,
    } as ReservationModeOption;
  }
);

export default function ReservationModeSelect({
  selectedReservationModeOption,
  setSelectedReservationModeOption,
  label,
  required = false,
  autoFocus,
  sx,
}: {
  sx?: any;
  selectedReservationModeOption: ReservationModeOption;
  setSelectedReservationModeOption: Dispatch<
    SetStateAction<ReservationModeOption>
  >;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      // sx={{ display: "inline-flex" }}

      disableClearable={true}
      fullWidth={true}
      value={selectedReservationModeOption}
      onChange={(event, value) => {
        if (value) {
          setSelectedReservationModeOption(value);
        }
      }}
      isOptionEqualToValue={(option, value) =>
        option.reservationMode === value.reservationMode
      }
      disablePortal
      options={reservationModeOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          required={required}
          label={label ? label : `${t("foodReservationTypeLabel")}`}
          autoFocus={autoFocus}
          fullWidth={true}
        />
      )}
    />
  );
}
