import React, { useState } from "react";
import Calendar from "../calendar/calendar";
import { Event, SlotInfo } from "react-big-calendar";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  apartmentReservationsToCalendarEvents,
  carReservationsToCalendarEvents,
  CustomEvent,
  customReservationsToCalendarEvents,
  foodReservationsToCalendarEvents,
  officeReservationsToCalendarEvents,
} from "../../functions/calendar-events-functions";
import { sortByDate, sortByNumber } from "../../functions/sort-functions";
import { FoodReservation } from "../../entities/food-reservation";
import { CustomFoodReservationEvent } from "../food/food-reservations-calendar";
import { CarReservation } from "../../entities/car-reservation";
import { CustomCarReservationEvent } from "../cars/car-reservations-calendar";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import { CustomApartmentReservationEvent } from "../apartments/apartment-reservations-calendar";
import { CustomOfficeReservationEvent } from "../offices/office-reservations-calendar";
import { CustomReservation } from "../../entities/custom-reservation";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import DoubleClickDelete from "../buttons/double-click-delete";
import { errorString } from "../../functions/error-functions";
import { deleteCustomReservationById } from "../../requests/custom-reservations-requests";
import { getCustomReservationsAction } from "../../actions/custom-reservation-actions";
import { SortType } from "../../enums/sort-type";

export default function MixedCalendar({
  showFoodReservations,
  showApartmentReservations,
  showOfficeReservations,
  showCarReservations,
  showCustomReservations,
  onSelectSlot = (slotInfo: SlotInfo) => {},
}: {
  onSelectSlot?: (slotInfo: SlotInfo) => any;
  showFoodReservations: boolean;
  showApartmentReservations: boolean;
  showOfficeReservations: boolean;
  showCarReservations: boolean;
  showCustomReservations: boolean;
}) {
  const events = useSelector((state: RootState) => {
    let apartments = state.apartmentsState.apartments;
    let events: CustomEvent[] = [];
    if (showCustomReservations) {
      events = events.concat(
        customReservationsToCalendarEvents(
          state.customReservationsState.customReservations
        )
      );
    }
    if (showApartmentReservations)
      events = events.concat(
        apartmentReservationsToCalendarEvents(
          state.apartmentsState.apartmentReservations
        )
      );
    if (showCarReservations)
      events = events.concat(
        carReservationsToCalendarEvents(
          state.carsState.carReservations,
          apartments
        )
      );
    if (showFoodReservations)
      events = events.concat(
        foodReservationsToCalendarEvents(
          state.foodState.foodReservations,
          apartments
        )
      );
    if (showOfficeReservations)
      events = events.concat(
        officeReservationsToCalendarEvents(
          state.officesState.officeReservations
        )
      );

    return events.sort((a, b) => {
      let aValue = a.resource instanceof CustomReservation ? 1 : 0;
      let bValue = b.resource instanceof CustomReservation ? 1 : 0;

      if (aValue === bValue) return sortByDate(a.start, b.start);
      return sortByNumber(aValue, bValue, SortType.ASC);
    });
  });

  return (
    <>
      <Calendar
        events={events}
        onSelectSlot={onSelectSlot}
        customEvent={VariableCustomEvent}
      />
    </>
  );
}

export function VariableCustomEvent({ event }: { event: Event }) {
  if (event.resource instanceof FoodReservation) {
    return <CustomFoodReservationEvent event={event} />;
  } else if (event.resource instanceof CarReservation) {
    return <CustomCarReservationEvent event={event} />;
  } else if (event.resource instanceof ApartmentReservation) {
    return <CustomApartmentReservationEvent event={event} />;
  } else if (event.resource instanceof CustomReservation) {
    return <CustomReservationEvent event={event} />;
  }
  return <CustomOfficeReservationEvent event={event} />;
}

export function CustomReservationEvent({ event }: { event: Event }) {
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const { t } = useTranslation();

  let customReservation: CustomReservation = event.resource;

  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {/*<MeetingRoom sx={{ height: 24 }} />*/}
      {/*{user && user.id === officeReservation.userId && (*/}
      {/*    <Bookmark sx={{ height: 24 }} />*/}
      {/*)}*/}
      <Typography
        sx={{
          ml: 1,
          maxWidth: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title}
      </Typography>
      {((hovering && isHousekeeper) ||
        (hovering && user && user.id === customReservation.userId)) && (
        <>
          <DoubleClickDelete
            sx={{ ml: 1, height: 24, width: 24 }}
            firstColor={"inherit"}
            secondColor={"inherit"}
            onClick={() => {
              deleteCustomReservationById(customReservation.id)
                .then((response) => {
                  emitSnackbarEvent({
                    message: `${t("customReservation.deletedSnackbarMessage")}`,
                    severity: "warning",
                  });
                  dispatch(getCustomReservationsAction());
                })
                .catch((error) =>
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  })
                );
            }}
          />
        </>
      )}
    </div>
  );
}
