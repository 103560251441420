import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function CarSelect({
  selectedCarOption,
  setSelectedCarOption,
  required = false,
  autoFocus,
}: {
  selectedCarOption: any;
  setSelectedCarOption: any;
  required?: boolean;
  autoFocus?: boolean;
}) {
  const carOptions = useSelector((state: RootState) =>
    state.carsState.cars.map((car) => {
      return { label: `${car.model},${car.licensePlate}`, id: car.id };
    })
  );

  const { t } = useTranslation();

  return (
    <Autocomplete
      value={selectedCarOption}
      onChange={(event, value) => {
        setSelectedCarOption(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      options={carOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          required={required}
          label={`${t("carLabel")}`}
        />
      )}
    />
  );
}
