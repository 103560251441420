import { Dispatch } from "redux";
import {
  getDocuments,
  getDocumentsWithoutHtml,
} from "../requests/document-requests";
import { setDocuments } from "../reducers/documents-reducer";

export function getDocumentsWithoutHtmlAction() {
  return (dispatch: Dispatch) => {
    getDocumentsWithoutHtml().then((response) =>
      dispatch(setDocuments(response.data.data))
    );
  };
}

export function getDocumentsAction() {
  return (dispatch: Dispatch) => {
    getDocuments().then((response) =>
      dispatch(setDocuments(response.data.data))
    );
  };
}
