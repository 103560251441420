import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { User } from "../../entities/user";
import { useTranslation } from "react-i18next";

export type UserOption = {
  id: number;
  label: string;
};

export default function UserSelect({
  selectedUserOption,
  setSelectedUserOption,
  userFilterFunction,
  label,
  autoFocus,
  required = false,
}: {
  selectedUserOption: UserOption | null;
  setSelectedUserOption: Dispatch<SetStateAction<UserOption | null>>;
  userFilterFunction?: (user: User) => boolean;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
}) {
  const { t } = useTranslation();

  const userOptions = useSelector((state: RootState) =>
    userFilterFunction
      ? state.usersState.users.filter(userFilterFunction).map((user) => {
          return { label: user.username, id: user.id };
        })
      : state.usersState.users.map((user) => {
          return { label: user.username, id: user.id };
        })
  );

  return (
    <Autocomplete
      value={selectedUserOption}
      onChange={(event, value) => {
        setSelectedUserOption(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      options={userOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ? label : `${t("userLabel")}`}
        />
      )}
    />
  );
}
