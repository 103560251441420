import { Apartment } from "../entities/apartment";
import axios, { AxiosResponse } from "axios";
import {
  deleteApartmentByIdEndpoint,
  deleteApartmentReservationByIdEndpoint,
  getApartmentByIdEndpoint,
  getApartmentReservationByIdEndpoint,
  getApartmentReservationsEndpoint,
  getApartmentsEndpoint,
  postApartmentEndpoint,
  postApartmentReservationEndpoint,
  putApartmentByIdEndpoint,
  putApartmentReservationByIdEndpoint,
} from "./endpoints";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { ApartmentReservation } from "../entities/apartment-reservation";
import { ApartmentCreationBody } from "../request-bodies/apartment-creation-body";
import { ApartmentReservationCreationBody } from "../request-bodies/apartment-reservation-creation-body";
import { ApartmentUpdateBody } from "../request-bodies/apartment-update-body";

export function getApartments(): Promise<
  AxiosResponse<ReadResponseBody<Apartment[]>>
> {
  return axios.get(getApartmentsEndpoint);
}

export function getApartmentById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<Apartment>>> {
  return axios.get(getApartmentByIdEndpoint(id));
}

export function postApartment(
  apartment: ApartmentCreationBody
): Promise<AxiosResponse<CreateResponseBody<Apartment>>> {
  return axios.post(postApartmentEndpoint, apartment);
}

export function putApartmentById(
  id: number,
  apartment: ApartmentUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<Apartment>>> {
  return axios.put(putApartmentByIdEndpoint(id), apartment);
}

export function deleteApartmentById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteApartmentByIdEndpoint(id));
}

export function getApartmentReservations(): Promise<
  AxiosResponse<ReadResponseBody<ApartmentReservation[]>>
> {
  return axios.get(getApartmentReservationsEndpoint);
}

export function getApartmentReservationById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<ApartmentReservation>>> {
  return axios.get(getApartmentReservationByIdEndpoint(id));
}

export function postApartmentReservation(
  apartmentReservation: ApartmentReservationCreationBody
): Promise<AxiosResponse<CreateResponseBody<ApartmentReservation>>> {
  return axios.post(postApartmentReservationEndpoint, apartmentReservation);
}

export function putApartmentReservationById(
  id: number,
  apartmentReservation: ApartmentReservation
): Promise<AxiosResponse<UpdateResponseBody<ApartmentReservation>>> {
  return axios.put(
    putApartmentReservationByIdEndpoint(id),
    apartmentReservation
  );
}

export function deleteApartmentReservationById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteApartmentReservationByIdEndpoint(id));
}
