import React, { Dispatch, SetStateAction } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DarkModeToggle from "../buttons/dark-mode-toggle";
import { Logout, Menu } from "@mui/icons-material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import AddMenuButton from "../buttons/add-menu-button";
import { useCookies } from "react-cookie";
import UserAvatarButton from "../buttons/user-avatar-button";
import SearchBar from "../search-bar/search-bar";
import HelpButton from "../buttons/help-button";

const drawerWidth = 240;

export default function TopBar({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const title = useSelector((state: RootState) => state.miscState.title);

  return (
    <AppBar
      position={"fixed"}
      sx={{
        width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
        ml: drawerOpen ? `${drawerWidth}px` : "0",
      }}
    >
      <Toolbar
        sx={{
          pr: "24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {!drawerOpen && (
            <IconButton
              color={"inherit"}
              sx={{ marginRight: "36px" }}
              onClick={() => setDrawerOpen(true)}
            >
              <Menu />
            </IconButton>
          )}
          <Typography variant={"h4"}>{title}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 4 }}>
            <HelpButton />
          </Box>
          <Box sx={{ mr: 4 }}>
            <SearchBar />
          </Box>
          {/*<AddMenuButton />*/}
          {/*<DarkModeToggle />*/}
          <UserAvatarButton />
          {/*<Tooltip title={"Logout"}>*/}
          {/*  <IconButton color={"secondary"} onClick={logout}>*/}
          {/*    <Logout />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
        </Box>
      </Toolbar>
    </AppBar>
  );

  function logout() {
    removeCookie("token");
    dispatch({ type: "LOGOUT_SUCCESS" });
  }
}
