import React, { FormEvent, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowForward,
  Close,
  Apartment as ApartmentIcon,
  Check,
  CheckOutlined,
  CheckCircle,
  AddCircle,
  Add,
  PersonAddAlt1,
  PersonRemoveAlt1,
} from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import ApartmentSelect, { ApartmentOption } from "../selects/apartment-select";
import { ApartmentReservationCreationBody } from "../../request-bodies/apartment-reservation-creation-body";
import { postApartmentReservation } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import useUser from "../../hooks/use-user";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dateToTimelessUTCDateKeepValues } from "../../functions/date-functions";
import { ReservationMode } from "../../enums/reservation-mode";
import UserSelect, { UserOption } from "../selects/user-select";
import { Guest } from "../../entities/guest";

export default function ApartmentReservationCreateDialog({
  initialStart,
  initialEnd,
  onClose,
}: {
  initialStart: Date;
  initialEnd: Date;
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [start, setStart] = useState<Date>(initialStart);
  const [end, setEnd] = useState<Date>(initialEnd);
  const emitSnackbarEvent = useGlobalSnackbar();
  const [selectedApartmentOption, setSelectedApartmentOption] =
    useState<ApartmentOption | null>(null);
  const [error, setError] = useState<string | null>(null);
  const user = useUser();
  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(
      user ? { id: user.id, label: user.username } : null
    );
  const [guests, setGuests] = useState<Guest[]>(
    user
      ? [new Guest(user.username, start, end)]
      : [new Guest("guest1", start, end)]
  );
  const [reservationId, setReservationId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }
  useEffect(() => {
    if (selectedUserOption)
      setGuests([new Guest(selectedUserOption.label, start, end)]);
  }, [selectedUserOption]);

  const { t } = useTranslation();
  if (reservationId)
    return (
      <Dialog
        open={dialogOpened}
        onClose={() => {
          handleClose();
        }}
        sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              badgeContent={<CheckCircle color={"success"} />}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Avatar sx={{ mr: 1 }}>
                <ApartmentIcon />
              </Avatar>
            </Badge>
            <Typography>
              {t("apartment.reservationCreatedSuccessfully")}
            </Typography>
          </Box>
          <Tooltip title={`${t("tooltips.close")}`}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers={true} sx={{ width: "600px" }}>
          <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
            <Button
              sx={{ mb: 2 }}
              onClick={() => {
                navigate(`/food/${reservationId}`);
              }}
              fullWidth={true}
              variant={"contained"}
            >
              {t("apartment.continueToFoodReservations")}
            </Button>
            <Button
              fullWidth={true}
              onClick={handleClose}
              color={"error"}
              variant={"contained"}
            >
              {t("apartment.noThanks")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        handleClose();
      }}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <ApartmentIcon />
          </Avatar>
          <Typography>{t("apartment.reservationCreateDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <DatePicker
              onChange={(event) => {
                if (event) {
                  setGuests((old) => {
                    const updatedGuests = [...old];
                    for (let entry of updatedGuests) {
                      entry.start = event;
                    }
                    return updatedGuests;
                  });
                  setStart(event);
                }
              }}
              value={start}
              label={`${t("startLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
            <ArrowForward />
            <DatePicker
              onChange={(event) => {
                if (event) {
                  setGuests((old) => {
                    const updatedGuests = [...old];
                    for (let entry of updatedGuests) {
                      entry.end = event;
                    }
                    return updatedGuests;
                  });

                  setEnd(event);
                }
              }}
              value={end}
              label={`${t("endLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
          </Box>
          {user && user.guests && user.guests.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <UserSelect
                selectedUserOption={selectedUserOption}
                setSelectedUserOption={setSelectedUserOption}
                userFilterFunction={(innerUser) => {
                  return (
                    user.guests.some((guest) => guest.id === innerUser.id) ||
                    user.id === innerUser.id
                  );
                }}
                label={"Reservation for user"}
              />
            </Box>
          )}

          <Box sx={{ mb: 2 }}>
            <ApartmentSelect
              apartmentFilterFunction={(apartment) => {
                if (apartment.reservationMode === ReservationMode.ANYONE) {
                  return true;
                } else if (
                  apartment.reservationMode === ReservationMode.OWNERS
                ) {
                  if (user) {
                    return apartment.owners.some(
                      (owner) => owner.id === user.id
                    );
                  } else {
                    return false;
                  }
                } else if (
                  apartment.reservationMode === ReservationMode.OWNERS_AND_USERS
                ) {
                  if (user) {
                    return (
                      apartment.users.some((owner) => owner.id === user.id) ||
                      apartment.owners.some((owner) => owner.id === user.id)
                    );
                  } else {
                    return false;
                  }
                } else if (apartment.inactive) return false;
                return false;
              }}
              selectedApartmentOption={selectedApartmentOption}
              setSelectedApartmentOption={setSelectedApartmentOption}
              required={true}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            {guests.map((guest, index) => {
              return (
                <Stack direction={"row"} alignItems={"center"}>
                  <TextField
                    key={index}
                    size={"small"}
                    fullWidth={false}
                    margin={"normal"}
                    required={true}
                    label={"Guest " + (index + 1)}
                    value={guest.name}
                    onChange={(event) => {
                      setGuests((old) => {
                        const updatedGuests = [...old];
                        updatedGuests[index].name = event.target.value;
                        return updatedGuests;
                      });
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      ml: 2,
                    }}
                  >
                    <DatePicker
                      onChange={(event) => {
                        setGuests((old) => {
                          const updatedGuests = [...old];
                          if (event) {
                            if (
                              new Date(event).getTime() <
                                new Date(start).getTime() ||
                              new Date(event).getTime() >
                                new Date(end).getTime()
                            ) {
                              setError(
                                `${t("apartment.invalidGuestDateRange")}`
                              );
                            } else {
                              updatedGuests[index].start = event;
                            }
                          }
                          return updatedGuests;
                        });
                      }}
                      value={guest.start}
                      label={`${t("startLabel")}`}
                      renderInput={(props) => (
                        <TextField
                          margin={"normal"}
                          size={"small"}
                          required={true}
                          {...props}
                        />
                      )}
                    />
                    <ArrowForward />
                    <DatePicker
                      onChange={(event) => {
                        setGuests((old) => {
                          const updatedGuests = [...old];
                          if (event) {
                            if (
                              new Date(event).getTime() >
                                new Date(end).getTime() ||
                              new Date(event).getTime() <
                                new Date(start).getTime()
                            ) {
                              setError(
                                `${t("apartment.invalidGuestDateRange")}`
                              );
                            } else {
                              updatedGuests[index].end = event;
                            }
                          }
                          return updatedGuests;
                        });
                      }}
                      value={guest.end}
                      label={`${t("endLabel")}`}
                      // maxDate={end}
                      // minDate={start}
                      renderInput={(props) => (
                        <TextField
                          margin={"normal"}
                          size={"small"}
                          required={true}
                          {...props}
                        />
                      )}
                    />
                  </Box>
                  {index !== 0 && (
                    <Box>
                      <Tooltip title={"Delete guest"}>
                        <IconButton
                          color={"error"}
                          onClick={() => {
                            setGuests((old) => {
                              const updatedGuests = [...old];
                              updatedGuests.splice(index, 1);
                              return updatedGuests;
                            });
                          }}
                        >
                          <PersonRemoveAlt1 />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Stack>
              );
            })}
            <Box>
              <Tooltip title={"Add guest"}>
                <IconButton
                  onClick={() => {
                    setGuests((old) => {
                      const updatedGuests = [...old, new Guest("", start, end)];
                      return updatedGuests;
                    });
                  }}
                >
                  <PersonAddAlt1 />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button fullWidth={true} variant={"contained"} type={"submit"}>
              {t("createButtonText")}
            </Button>
          </Box>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!start) {
      setError(`${t("apartment.noStartDateError")}`);
      return false;
    }
    if (new Date(start) > new Date(end)) {
      setError(`${t("apartment.startDateBeforeEndDateError")}`);
      return false;
    }
    if (!selectedApartmentOption) {
      setError(`${t("apartment.noApartmentSelectedError")}`);
      return false;
    }
    setError(null);
    for (let guest of guests) {
      if (
        !guest.start ||
        !guest.end ||
        new Date(guest.start).getTime() < new Date(start).getTime() ||
        new Date(guest.end).getTime() > new Date(end).getTime()
      ) {
        setError(`${t("apartment.invalidGuestDateRange")}`);
        return false;
      }
    }

    let apartmentReservationCreationBody = {
      start: dateToTimelessUTCDateKeepValues(start),
      end: dateToTimelessUTCDateKeepValues(end),
      apartmentId: selectedApartmentOption.id,
      reservedForUserId: selectedUserOption ? selectedUserOption.id : null,
      guests: guests.map((guest) => {
        guest.start = dateToTimelessUTCDateKeepValues(guest.start ?? start);
        guest.end = dateToTimelessUTCDateKeepValues(guest.end ?? start);
        return guest.toString();
      }),
    } as ApartmentReservationCreationBody;

    postApartmentReservation(apartmentReservationCreationBody)
      .then((response) => {
        dispatch(getApartmentReservationsAction());
        emitSnackbarEvent({
          message: `${t("apartment.reservationCreatedSnackbarMessage")}`,
          severity: "success",
        });
        // handleClose();
        if (!Array.isArray(response.data.id))
          setReservationId(response.data.id);
      })
      .catch((error) => setError(errorString(error)));
    // I don't validate inside of a function because then i still need to do null checks (next to standard form validation)
  }
}
