import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../index";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { Problem } from "../../entities/problem";
import { ProblemStatus } from "../../enums/problem-status";
import { SortType } from "../../enums/sort-type";
import { sortByDate } from "../../functions/sort-functions";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DoubleClickDelete from "../buttons/double-click-delete";
import {
  deleteProblemById,
  resolveProblemById,
} from "../../requests/problem-requests";
import { getProblemsAction } from "../../actions/problem-actions";
import { errorString } from "../../functions/error-functions";
import { CheckCircle, ReportProblem, TextSnippet } from "@mui/icons-material";
import DoubleClickResolve from "../buttons/double-click-resolve";
import ProblemDialog from "./problem-dialog";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

export default function ProblemList() {
  const problems = useSelector(
    (state: RootState) => state.problemsState.problems
  );

  const { problemId } = useParams();
  const location = useLocation();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const dispatch = useDispatch();

  const emitSnackbarEvent = useGlobalSnackbar();
  const [showOthers, setShowOthers] = useState<boolean>(isHousekeeper);
  const [showResolved, setShowResolved] = useState<boolean>(true);

  const [processedProblems, setProcessedProblems] = useState<Problem[]>([]);

  const [problemShownInDialog, setProblemShownInDialog] =
    useState<Problem | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedProblems(
      [...problems]
        .filter((problem) => {
          if (!showResolved && problem.problemStatus === ProblemStatus.RESOLVED)
            return false;
          if (!showOthers && user && user.id !== problem.userId) return false;
          return true;
        })
        .sort((a, b) => sortByDate(a.createdAt, b.createdAt, SortType.ASC))
    );
  }, [problems, showOthers, showResolved]);

  useEffect(() => {
    if (problemId) {
      let matchedProblem = problems.find(
        (problem) => problem.id === parseInt(problemId)
      );
      if (matchedProblem) setProblemShownInDialog(matchedProblem);
    }
  }, [problemId, location]);

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showResolved}
              onChange={(event) => setShowResolved(event.target.checked)}
            />
          }
          label={`${t("problem.showResolvedLabel")}`}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showOthers}
              onChange={(event) => setShowOthers(event.target.checked)}
            />
          }
          label={`${t("showOthersLabel")}`}
          labelPlacement="end"
        />
      </Stack>
      <List sx={{ overflowY: "auto", maxHeight: "600px" }}>
        {processedProblems.length === 0 && (
          <ListItem>
            <ListItemText
              primary={
                showResolved
                  ? `${t("problem.noProblems")}`
                  : `${t("problem.noUnresolvedProblems")}`
              }
            />
          </ListItem>
        )}
        {processedProblems.map((problem) => {
          let createdAt = new Date(problem.createdAt);
          let lastChangedAt = new Date(problem.lastChangedAt);

          return (
            <ListItem
              key={problem.id}
              divider={true}
              secondaryAction={
                isHousekeeper || (user && user.id === problem.userId) ? (
                  <>
                    <Tooltip title={`${t("problem.showDetailsDialogTooltip")}`}>
                      <IconButton
                        onClick={() => setProblemShownInDialog(problem)}
                      >
                        <TextSnippet />
                      </IconButton>
                    </Tooltip>
                    {problem.problemStatus === ProblemStatus.UNRESOLVED && (
                      <DoubleClickResolve
                        onClick={() => {
                          resolveProblemById(problem.id)
                            .then((response) => {
                              emitSnackbarEvent({
                                message: `${t(
                                  "problem.resolvedSnackbarMessage"
                                )}`,
                                severity: "success",
                              });
                              dispatch(getProblemsAction());
                            })
                            .catch((error) =>
                              emitSnackbarEvent({
                                message: errorString(error),
                                severity: "error",
                              })
                            );
                        }}
                      />
                    )}
                    <DoubleClickDelete
                      onClick={() => {
                        deleteProblemById(problem.id)
                          .then((response) => {
                            emitSnackbarEvent({
                              message: `${t("problem.deletedSnackbarMessage")}`,
                              severity: "warning",
                            });
                            dispatch(getProblemsAction());
                          })
                          .catch((error) =>
                            emitSnackbarEvent({
                              message: errorString(error),
                              severity: "error",
                            })
                          );
                      }}
                    />
                  </>
                ) : null
              }
            >
              <ListItemIcon>
                <Tooltip title={problem.problemStatus}>
                  {problem.problemStatus === ProblemStatus.UNRESOLVED ? (
                    <ReportProblem fontSize={"large"} color={"error"} />
                  ) : (
                    <CheckCircle fontSize={"large"} color={"success"} />
                  )}
                </Tooltip>
              </ListItemIcon>

              <ListItemText
                style={{ maxWidth: "420px", wordWrap: "break-word" }}
                primary={problem.subject}
                secondary={`${t("createdBy")}: ${problem.user.username}`}
              />
              <ListItemText
                primary={
                  <Stack>
                    <Typography>
                      {t("problem.dialogLastChangedAt")}:{" "}
                      {new Date(problem.lastChangedAt).toDateString()}
                    </Typography>
                    <Typography>
                      {t("problem.dialogCreatedAt")}:{" "}
                      {new Date(problem.createdAt).toDateString()}
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
          );
        })}
      </List>
      {problemShownInDialog && (
        <ProblemDialog
          onClose={() => setProblemShownInDialog(null)}
          problem={problemShownInDialog}
        />
      )}
    </>
  );
}
