import { CarReservation } from "../entities/car-reservation";
import { ApartmentReservation } from "../entities/apartment-reservation";
import { Apartment } from "../entities/apartment";
import { FoodReservation } from "../entities/food-reservation";
import { sortFoodReservation } from "./sort-functions";
import { SortType } from "../enums/sort-type";
import { OfficeReservation } from "../entities/office-reservation";
import { Event } from "react-big-calendar";
import i18next from "../i18n/config";
import { CustomReservation } from "../entities/custom-reservation";
import { UTCDateToTimelessDateKeepValues } from "./date-functions";

export type CustomEvent = {
  start: Date;
  end: Date;
  title: string;
  allDay: boolean;
  resource: any;
};

export function carReservationsToCalendarEvents(
  carReservations: CarReservation[],
  apartments: Apartment[]
): CustomEvent[] {
  return carReservations.map((carReservation) => {
    let matchedApartment = apartments.find(
      (apartment) =>
        apartment.id === carReservation.apartmentReservation.apartmentId
    );

    let apartmentNameString = matchedApartment
      ? matchedApartment.name
      : carReservation.apartmentReservation.apartmentId;
    return {
      start: new Date(UTCDateToTimelessDateKeepValues(carReservation.date)),
      end: new Date(UTCDateToTimelessDateKeepValues(carReservation.date)),
      title: `${carReservation.car.model} ${i18next.t(
        "reservedBy"
      )} ${apartmentNameString}`,
      allDay: true,
      resource: new CarReservation(carReservation),
    };
  });
}
export function foodReservationsToCalendarEvents(
  foodReservations: FoodReservation[],
  apartments: Apartment[]
): CustomEvent[] {
  return [...foodReservations]
    .sort((a, b) => sortFoodReservation(a, b, SortType.ASC))
    .map((foodReservation) => {
      let matchedApartment = apartments.find(
        (apartment) =>
          apartment.id === foodReservation.apartmentReservation.apartmentId
      );

      let apartmentNameString = matchedApartment
        ? matchedApartment.name
        : foodReservation.apartmentReservation.apartmentId;
      return {
        start: new Date(UTCDateToTimelessDateKeepValues(foodReservation.date)),
        end: new Date(UTCDateToTimelessDateKeepValues(foodReservation.date)),
        title: `${foodReservation.foodReservationType} ${i18next.t(
          "reservationMadeBy"
        )} ${apartmentNameString}`,
        allDay: true,
        resource: new FoodReservation(foodReservation),
      };
    });
}

export function apartmentReservationsToCalendarEvents(
  apartmentReservations: ApartmentReservation[]
): CustomEvent[] {
  return apartmentReservations.map((apartmentReservation) => {
    return {
      start: new Date(
        UTCDateToTimelessDateKeepValues(apartmentReservation.start)
      ),
      end: new Date(
        new Date(
          UTCDateToTimelessDateKeepValues(apartmentReservation.end)
        ).getTime() + 60000
      ),
      title: `${apartmentReservation.apartment.name} ${i18next.t(
        "reservedBy"
      )} ${apartmentReservation.user.username}`,
      allDay: true,
      resource: new ApartmentReservation(apartmentReservation),
    };
  });
}

export function officeReservationsToCalendarEvents(
  officeReservations: OfficeReservation[]
): CustomEvent[] {
  return officeReservations.map((officeReservation) => {
    return {
      start: new Date(officeReservation.start),
      end: new Date(officeReservation.end),
      title: `${officeReservation.office.name} ${i18next.t("reservedBy")} ${
        officeReservation.user.username
      }`,
      allDay: false,

      resource: new OfficeReservation(officeReservation),
    };
  });
}

export function customReservationsToCalendarEvents(
  customReservations: CustomReservation[]
): CustomEvent[] {
  return customReservations.map((customReservation) => {
    return {
      start: new Date(customReservation.start),
      end: new Date(customReservation.end),
      title: `${customReservation.title}`,
      allDay: false,
      resource: new CustomReservation(customReservation),
    };
  });
}
