import { setCarReservations, setCars } from "../reducers/cars-reducer";
import { getCarReservations, getCars } from "../requests/car-requests";
import { Dispatch } from "redux";

export function getCarsAction() {
  return (dispatch: Dispatch) => {
    getCars().then((response) => dispatch(setCars(response.data.data)));
  };
}

export function getCarReservationsAction() {
  return (dispatch: Dispatch) => {
    getCarReservations().then((response) =>
      dispatch(setCarReservations(response.data.data))
    );
  };
}
