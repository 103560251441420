import Interweave from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDocumentById,
  getDocumentByTitle,
} from "../../requests/document-requests";
import { Document } from "../../entities/document";
import useTitle from "../../hooks/use-title";
import { Container, Grid, Paper } from "@mui/material";

export default function DocumentReader() {
  const params = useParams();
  const resetTitle = useTitle("");

  const [document, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    if (params.documentTitle) {
      getDocumentByTitle(params.documentTitle).then((response) => {
        setDocument(response.data.data);
        resetTitle(response.data.data.title);
      });
    }
  }, [params]);

  if (document)
    return (
      <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Interweave
                allowAttributes={true}
                allowElements={true}
                // allowList={["iframe"]}
                content={document.html}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );

  return <div></div>;
}
