export class Guest {
  constructor(name: string, start: Date | null, end: Date | null) {
    this.name = name;
    this.start = start ? new Date(start) : null;
    this.end = end ? new Date(end) : null;
  }

  name: string;

  start: Date | null;

  end: Date | null;

  static fromString(string: string) {
    let correctSplit = string.split(";");
    if (correctSplit.length !== 3) {
      let name = string;
      let start = null;
      let end = null;
      return new Guest(name, start, end);
    }
    let name = correctSplit[0];
    let start = new Date(parseInt(correctSplit[1]));
    let end = new Date(parseInt(correctSplit[2]));
    return new Guest(name, start, end);
  }

  toString() {
    if (this.start && this.end) {
      return `${this.name};${new Date(this.start).getTime()};${new Date(
        this.end
      ).getTime()}`;
    }
    return this.name;
  }
  toPrettyString() {
    if (this.start && this.end) {
      return `${this.name} from ${new Date(
        this.start
      ).toLocaleDateString()} until ${new Date(this.end).toLocaleDateString()}`;
    }
    return this.name;
  }
}
