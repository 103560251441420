import { Help } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HelpDialog from "../dialogs/help-dialog";

export default function HelpButton() {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={`${t("tooltips.help")}`}>
        <IconButton onClick={() => setDialogOpen(true)} color={"inherit"}>
          <Help />
        </IconButton>
      </Tooltip>
      {dialogOpen && <HelpDialog onClose={() => setDialogOpen(false)} />}
    </>
  );
}
