import md5 from "md5";

export function stringToColor(string: string) {
  let hash = md5(string);
  let colorString = `#`;
  for (let i = 0; i <= 5; i++) {
    let char = hash[i];
    colorString += `${char}`;
  }

  return colorString;
}
