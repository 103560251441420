import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { User } from "../entities/user";

export type AuthorizationState = {
  user: User | null;
  healthy: boolean;
};

const initialState = {
  user: null,
  healthy: false,
} as AuthorizationState;

const authorizationStateSlice = createSlice({
  name: "authorizationSlice",
  initialState,
  reducers: {
    setAuthorizationData(state: any, action: PayloadAction<User>) {
      state.user = action.payload;
      state.healthy = true;
    },
  },
});

export const authorizationState = authorizationStateSlice.reducer;
export const { setAuthorizationData } = authorizationStateSlice.actions;
