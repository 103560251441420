import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApartmentReservation } from "../entities/apartment-reservation";
import { Apartment } from "../entities/apartment";

export type ApartmentsState = {
  apartments: Apartment[];
  apartmentsFetched: boolean;
  apartmentReservations: ApartmentReservation[];
  apartmentReservationsFetched: boolean;
};

const initialState = {
  apartments: [],
  apartmentsFetched: false,
  apartmentReservations: [],
  apartmentReservationsFetched: false,
} as ApartmentsState;

export const apartmentsStateSlice = createSlice({
  name: "apartmentsState",
  initialState,
  reducers: {
    setApartments(state, action: PayloadAction<Apartment[]>) {
      state.apartments = action.payload;
      state.apartmentsFetched = true;
    },
    setApartmentReservations(
      state,
      action: PayloadAction<ApartmentReservation[]>
    ) {
      state.apartmentReservations = action.payload;
      state.apartmentReservationsFetched = true;
    },
  },
});

export const apartmentsState = apartmentsStateSlice.reducer;
export const { setApartments, setApartmentReservations } =
  apartmentsStateSlice.actions;
