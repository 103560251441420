import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../index";
import {
  getApartmentReservationsAction,
  getApartmentsAction,
} from "../actions/apartment-actions";
import {
  getCarReservationsAction,
  getCarsAction,
} from "../actions/car-actions";
import {
  getOfficeReservationsAction,
  getOfficesAction,
} from "../actions/office-actions";
import { getFoodReservationsAction } from "../actions/food-actions";
import { getProblemsAction } from "../actions/problem-actions";
import {
  getDocumentsAction,
  getDocumentsWithoutHtmlAction,
} from "../actions/document-actions";
import useIsHousekeeper from "./use-is-housekeeper";
import { Role } from "../enums/role";
import { getUsersAction } from "../actions/user-actions";
import useIsWorker from "./use-is-worker";
import { getCustomReservationsAction } from "../actions/custom-reservation-actions";
import useIsOwner from "./use-is-owner";

export default function useApplicationState(): [boolean, () => any] {
  const dispatch = useDispatch();

  const isHousekeeper = useIsHousekeeper();
  const isOwner = useIsOwner();

  const isWorker = useIsWorker();

  useEffect(() => {
    if (isWorker) {
      dispatch(getOfficesAction());
      dispatch(getOfficeReservationsAction());
      dispatch(getDocumentsAction());
      dispatch(getCustomReservationsAction());
    } else {
      dispatch(getApartmentReservationsAction());
      dispatch(getApartmentsAction());
      dispatch(getCarsAction());
      dispatch(getCarReservationsAction());
      dispatch(getFoodReservationsAction());
      dispatch(getDocumentsAction());
      dispatch(getProblemsAction());
      dispatch(getCustomReservationsAction());
    }
    if (isOwner) {
      dispatch(getUsersAction());
    }
    if (isHousekeeper) {
      dispatch(getProblemsAction());
      dispatch(getOfficesAction());
      dispatch(getUsersAction());
      dispatch(getOfficeReservationsAction());
    }
  }, [isHousekeeper, isWorker]);

  function refetchAll() {
    if (isWorker) {
      dispatch(getOfficesAction());
      dispatch(getOfficeReservationsAction());
      dispatch(getDocumentsAction());
      dispatch(getCustomReservationsAction());
    } else {
      dispatch(getApartmentReservationsAction());
      dispatch(getApartmentsAction());
      dispatch(getCarsAction());
      dispatch(getCarReservationsAction());
      dispatch(getFoodReservationsAction());
      dispatch(getDocumentsAction());
      dispatch(getProblemsAction());
      dispatch(getCustomReservationsAction());
    }
    if (isOwner) {
      dispatch(getUsersAction());
    }
    if (isHousekeeper) {
      dispatch(getProblemsAction());
      dispatch(getOfficesAction());
      dispatch(getUsersAction());
      dispatch(getOfficeReservationsAction());
    }
  }

  return [
    useSelector((state: RootState) => {
      if (!state.authorizationState.user) return false;

      if (state.authorizationState.user.role === Role.WORKER)
        return (
          state.officesState.officesFetched &&
          state.officesState.officeReservationsFetched &&
          state.customReservationsState.customReservationsFetched
        );

      return (
        state.carsState.carsFetched &&
        state.carsState.carReservationsFetched &&
        state.foodState.foodReservationsFetched &&
        state.apartmentsState.apartmentsFetched &&
        state.apartmentsState.apartmentReservationsFetched &&
        state.documentsState.documentsFetched &&
        state.customReservationsState.customReservationsFetched &&
        state.problemsState.problemsFetched &&
        (state.authorizationState.user.role === Role.HOUSEKEEPER
          ? state.usersState.usersFetched &&
            state.officesState.officeReservationsFetched &&
            state.officesState.officesFetched
          : true)
      );
    }),
    refetchAll,
  ];
}
