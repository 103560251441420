import { FoodReservationType } from "../enums/food-reservation-type";
import { User } from "./user";
import { ApartmentReservation } from "./apartment-reservation";

export class FoodReservation {
  constructor(foodReservation: FoodReservation) {
    this.id = foodReservation.id;
    this.date = new Date(foodReservation.date);
    this.foodReservationType = foodReservation.foodReservationType;
    this.apartmentReservationId = foodReservation.apartmentReservationId;
    this.apartmentReservation = foodReservation.apartmentReservation;
    this.createdAt = new Date(foodReservation.createdAt);
    this.guests = foodReservation.guests;
  }

  id: number;

  date: Date;

  foodReservationType: FoodReservationType;

  apartmentReservationId: number;

  apartmentReservation: ApartmentReservation;

  createdAt: Date;

  guests: string[];
}
