import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  InputBase,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  Apartment,
  Article,
  CheckCircle,
  DirectionsCar,
  MeetingRoom,
  Person,
  ReportProblem,
  Search,
} from "@mui/icons-material";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import { compareTwoStrings } from "string-similarity";
import { distance } from "fastest-levenshtein";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProblemStatus } from "../../enums/problem-status";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

type SearchResult = {
  icon: JSX.Element;
  to: string;
  primary: string;
  secondary?: string;
};

export default function SearchBar() {
  const [value, setValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const firstMenuItemRef = useRef<any>(null);
  const paperRef = useRef<any>(null);
  const open = Boolean(anchorEl);
  const isHousekeeper = useIsHousekeeper();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const documents = useSelector(
    (state: RootState) => state.documentsState.documents
  );

  const cars = useSelector((state: RootState) => state.carsState.cars);

  const apartments = useSelector(
    (state: RootState) => state.apartmentsState.apartments
  );

  const offices = useSelector((state: RootState) => state.officesState.offices);

  const problems = useSelector(
    (state: RootState) => state.problemsState.problems
  );

  const users = useSelector((state: RootState) => state.usersState.users);

  useEffect(() => {
    let searchResults: SearchResult[] = [];
    if (value) {
      //documents
      for (let document of documents) {
        let strings = [document.html, document.title];
        if (document.parentTopic) strings.push(document.parentTopic);
        if (stringSimilarity(strings)) {
          searchResults.push({
            icon: <Article />,
            to: `/${document.title}`,
            primary: document.title,
          });
        }
      }
      //problems
      for (let problem of problems) {
        if (
          stringSimilarity([
            problem.user.username,
            problem.subject,
            problem.text,
          ])
        ) {
          // for (let i = 0; i < 100; i++)
          searchResults.push({
            icon:
              problem.problemStatus === ProblemStatus.UNRESOLVED ? (
                <ReportProblem color={"error"} />
              ) : (
                <CheckCircle color={"success"} />
              ),
            to: `/problems/${problem.id}`,
            primary: problem.subject,
          });
        }
      }
      //users
      if (isHousekeeper) {
        for (let user of users) {
          if (stringSimilarity([user.username, user.role])) {
            searchResults.push({
              icon: <Person />,
              to: "/users",
              primary: user.username,
            });
          }
        }
      }
      //apartments
      for (let apartment of apartments) {
        if (
          stringSimilarity([
            apartment.name,
            apartment.number.toString(),
            ...apartment.owners.map((user) => user.username),
            ...apartment.users.map((user) => user.username),
          ])
        ) {
          searchResults.push({
            icon: <Apartment />,
            to: "/apartments",
            primary: apartment.name,
          });
        }
      }
      //cars
      for (let car of cars) {
        if (stringSimilarity([car.model, car.licensePlate])) {
          searchResults.push({
            icon: <DirectionsCar />,
            to: "/cars",
            primary: car.model,
            secondary: car.licensePlate,
          });
        }
      }
      //offices
      for (let office of offices) {
        if (stringSimilarity([office.name, office.number.toString()])) {
          searchResults.push({
            icon: <MeetingRoom />,
            to: "/offices",
            primary: office.name,
          });
        }
      }
    }

    setSearchResults(searchResults);
  }, [value, documents, cars, apartments, offices, problems, users]);

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const handleClose = () => {
    setAnchorEl(null);
    setValue("");
  };

  function stringSimilarity(strings: string[]) {
    return (
      strings.filter(
        (string) =>
          string.toLowerCase().includes(value.toLowerCase()) ||
          compareTwoStrings(value.toLowerCase(), string.toLowerCase()) > 0.8
      ).length > 0
    );
  }

  return (
    <>
      <Paper
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 250,
          height: 32,
        }}
        ref={paperRef}
      >
        <InputBase
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
            setAnchorEl(paperRef.current);
          }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search..."
          inputProps={{ "aria-label": "search" }}
          onKeyDown={(ev) => {
            if (ev.code === "ArrowDown" && firstMenuItemRef.current) {
              firstMenuItemRef.current.focus();
            } else if (ev.code === "Escape") {
              handleClose();
            }
          }}
        />

        <Search />
      </Paper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            p: 0,
          },
        }}
        autoFocus={false}
        disableAutoFocus={true}
        disableAutoFocusItem={true}
        PaperProps={{
          sx: {
            width: 250,
          },
        }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {searchResults.length === 0 && (
          <MenuItem disabled={true} dense={true} key={0}>
            <ListItemText
              primary={<Typography noWrap={true}>{t("noResults")}</Typography>}
            />
          </MenuItem>
        )}

        <FixedSizeList
          itemSize={37}
          height={
            37 * searchResults.length < 300 ? 37 * searchResults.length : 300
          }
          itemCount={searchResults.length}
          width={250}
        >
          {renderRow}
        </FixedSizeList>

        {/*{searchResults.map((searchResult, index) => {*/}
        {/*  return (*/}
        {/*    <MenuItem*/}
        {/*      dense={true}*/}
        {/*      key={index}*/}
        {/*      ref={index === 0 ? firstMenuItemRef : null}*/}
        {/*      onClick={() => {*/}
        {/*        navigate(searchResult.to);*/}
        {/*        handleClose();*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <ListItemAvatar>{searchResult.icon}</ListItemAvatar>*/}
        {/*      <ListItemText*/}
        {/*        primary={*/}
        {/*          <Typography noWrap={true}>{searchResult.primary}</Typography>*/}
        {/*        }*/}
        {/*        secondary={searchResult.secondary}*/}
        {/*      />*/}
        {/*    </MenuItem>*/}
        {/*  );*/}
        {/*})}*/}
      </Menu>
    </>
  );
  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    let searchResult = searchResults[index];
    return (
      <MenuItem
        style={style}
        dense={true}
        key={index}
        ref={index === 0 ? firstMenuItemRef : null}
        onClick={() => {
          navigate(searchResult.to);
          handleClose();
        }}
      >
        <ListItemAvatar>{searchResult.icon}</ListItemAvatar>
        <ListItemText
          primary={
            <Typography noWrap={true}>{searchResult.primary}</Typography>
          }
          secondary={searchResult.secondary}
        />
      </MenuItem>
    );
  }
}
