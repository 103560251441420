import React from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../index";
import { Article, Delete } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortByNumber } from "../../functions/sort-functions";

export default function DocumentsList({ editable }: { editable: boolean }) {
  const documents = useSelector(
    (state: RootState) => state.documentsState.documents
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <List>
      {documents.length === 0 && (
        <ListItem>
          <ListItemText primary={`${t("document.noDocuments")}`} />
        </ListItem>
      )}
      {[...documents]
        .sort((a, b) => sortByNumber(a.id, b.id))
        .map((document) => {
          if (!editable)
            return (
              <ListItem key={document.id} divider={true}>
                <ListItemAvatar>
                  <Avatar>
                    <Article />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={document.title} />
              </ListItem>
            );
          return (
            <ListItemButton
              divider={true}
              key={document.id}
              onClick={() => navigate(`/information/${document.id}`)}
            >
              <ListItemAvatar>
                <Avatar>
                  <Article />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={document.title}
                secondary={`${t("lastChange")}: ${document.lastChangedAt}`}
              />
            </ListItemButton>
          );
        })}
    </List>
  );
}
