import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import useUser from "../../hooks/use-user";
import { stringToColor } from "../../functions/string-functions";
import {
  AddCircle,
  Article,
  Logout,
  Person,
  Settings,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import useLogout from "../../hooks/use-logout";
import { useTranslation } from "react-i18next";
import UserAvatar from "../avatars/user-avatar";

export default function UserAvatarButton() {
  const user = useUser();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = useLogout();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  if (user)
    return (
      <>
        <IconButton
          color={"inherit"}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <UserAvatar />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <ListItem dense={true}>
            <ListItemAvatar>
              <UserAvatar />
            </ListItemAvatar>
            <ListItemText primary={user.username} secondary={user.role} />
          </ListItem>
          <Divider />
          {/*<MenuItem*/}
          {/*  key={"my-account"}*/}
          {/*  onClick={() => {*/}
          {/*    navigate("/my-account");*/}
          {/*    handleClose();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <Person />*/}
          {/*  </ListItemIcon>*/}

          {/*  <ListItemText>*/}
          {/*    {t("avatarButtonMenu.myAccountButtonText")}*/}
          {/*  </ListItemText>*/}
          {/*</MenuItem>*/}

          <MenuItem
            key={"logout"}
            onClick={() => {
              logout();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>

            <ListItemText>
              {t("avatarButtonMenu.logoutButtonText")}
            </ListItemText>
          </MenuItem>
        </Menu>
      </>
    );

  return <></>;
}
