import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useTitle from "../../hooks/use-title";
import ProblemReportDialog from "./problem-report-dialog";
import { AddCircle } from "@mui/icons-material";
import ProblemList from "./problem-list";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Problems() {
  const { t } = useTranslation();
  useTitle(`${t("problem.title")}`);

  const location = useLocation();

  const [reportProblemDialogOpen, setReportProblemDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (location.pathname && location.pathname.includes("new-problem"))
      setReportProblemDialogOpen(true);
  }, [location]);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {t("problem.listTitle")}
                </Typography>
              </Stack>

              <Tooltip title={`${t("problem.newTooltip")}`}>
                <IconButton onClick={() => setReportProblemDialogOpen(true)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>
            <ProblemList />
          </Paper>
        </Grid>
      </Grid>
      {reportProblemDialogOpen && (
        <ProblemReportDialog
          onClose={() => setReportProblemDialogOpen(false)}
        />
      )}
    </Container>
  );
}
