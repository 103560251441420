import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";

export type SnackbarEvent = {
  message: string;
  severity: AlertColor;
};

const initialState = {
  title: null,
  snackbarEvent: null,
  calendarDate: new Date().toUTCString(),
  headerTitle: "Ranc De Banes",
} as {
  title: string | null;
  snackbarEvent: SnackbarEvent | null;
  calendarDate: string;
  headerTitle: string;
};

export const miscStateSlice = createSlice({
  name: "miscState",
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string | null>) {
      state.title = action.payload;
    },
    setSnackbarEvent(state, action: PayloadAction<SnackbarEvent | null>) {
      state.snackbarEvent = action.payload;
    },
    setCalendarDate(state, action: PayloadAction<string>) {
      state.calendarDate = action.payload;
    },
    setHeaderTitle(state, action: PayloadAction<string>) {
      state.headerTitle = action.payload;
    },
  },
});

export const miscState = miscStateSlice.reducer;
export const { setTitle, setSnackbarEvent, setCalendarDate, setHeaderTitle } =
  miscStateSlice.actions;
