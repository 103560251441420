import React from "react";
import { Helmet } from "react-helmet";
import { RootState } from "../index";
import { useSelector } from "react-redux";

export default function CustomHelmet() {
  const headerTitle = useSelector(
    (state: RootState) => state.miscState.headerTitle
  );

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Web site created using create-react-app"
      />

      <title>{headerTitle}</title>
    </Helmet>
  );
}
