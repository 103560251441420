import React, { CSSProperties, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { DirectionsCar } from "@mui/icons-material";
import { CarReservation } from "../../entities/car-reservation";
import { sortByDate } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import useMatchApartment from "../../hooks/use-match-apartment";
import useGetApartmentIdentifierString from "../../hooks/use-get-apartment-identifier-string";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { deleteCarReservationById } from "../../requests/car-requests";
import { getCarReservationsAction } from "../../actions/car-actions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import CarReservationListItem from "./car-reservation-list-item";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import OfficeReservationListItem from "../offices/office-reservation-list-item";
import { useTranslation } from "react-i18next";
import { getTodayNoTime } from "../../functions/date-functions";

export default function CarReservationsList() {
  const carReservations = useSelector(
    (state: RootState) => state.carsState.carReservations
  );

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const [showOthers, setShowOthers] = useState<boolean>(isHousekeeper);
  const [showExpired, setShowExpired] = useState<boolean>(false);

  const [processedCarReservations, setProcessedCarReservations] = useState<
    CarReservation[]
  >([]);

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedCarReservations(
      [...carReservations]
        .filter((carReservation) => {
          if (!showExpired && new Date(carReservation.date) < getTodayNoTime())
            return false;
          if (
            !showOthers &&
            user &&
            user.id !== carReservation.apartmentReservation.userId
          )
            return false;
          return true;
        })
        .sort((a, b) => sortByDate(a.date, b.date, SortType.ASC))
    );
  }, [carReservations, showOthers, showExpired]);

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showExpired}
              onChange={(event) => setShowExpired(event.target.checked)}
            />
          }
          label={`${t("showExpiredLabel")}`}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showOthers}
              onChange={(event) => setShowOthers(event.target.checked)}
            />
          }
          label={`${t("showOthersLabel")}`}
          labelPlacement="end"
        />
      </Stack>
      <Box
        sx={{
          overflowY: "auto",
          height: "350px",
          maxHeight: "350px",
          width: "100%",
        }}
      >
        {processedCarReservations.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText primary={`${t("noReservationsListItemText")}`} />
            </ListItem>
          </List>
        ) : (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  itemSize={60}
                  height={height}
                  itemCount={processedCarReservations.length}
                  width={width}
                >
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        )}
      </Box>
    </>
  );

  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    return (
      <CarReservationListItem
        key={index}
        style={style}
        carReservation={processedCarReservations[index]}
      />
    );
  }
}
