import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomReservation } from "../entities/custom-reservation";

export type CustomReservationsState = {
  customReservations: CustomReservation[];
  customReservationsFetched: boolean;
};

const initialState = {
  customReservations: [],
  customReservationsFetched: false,
} as CustomReservationsState;

export const customReservationsStateSlice = createSlice({
  name: "customReservationsState",
  initialState,
  reducers: {
    setCustomReservations(state, action: PayloadAction<CustomReservation[]>) {
      state.customReservations = action.payload;
      state.customReservationsFetched = true;
    },
  },
});

export const customReservationsState = customReservationsStateSlice.reducer;
export const { setCustomReservations } = customReservationsStateSlice.actions;
