import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { Article, DirectionsCar, Edit } from "@mui/icons-material";
import { Car } from "../../entities/car";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { sortByDate, sortByNumber } from "../../functions/sort-functions";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentById } from "../../requests/apartment-requests";
import { getApartmentsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { getCarsAction } from "../../actions/car-actions";
import { deleteCarById } from "../../requests/car-requests";
import CarEditDialog from "./car-edit-dialog";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function CarList() {
  const cars = useSelector((state: RootState) => state.carsState.cars);

  const isHousekeeper = useIsHousekeeper();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedCars([...cars].sort((a, b) => sortByNumber(a.id, b.id)));
  }, [cars]);

  const [editingCar, setEditingCar] = useState<Car | null>(null);

  const [editingDialogOpen, setEditingDialogOpen] = useState<boolean>(false);

  const emitSnackbarEvent = useGlobalSnackbar();

  const [processedCars, setProcessedCars] = useState<Car[]>([]);

  return (
    <>
      <List sx={{ overflowY: "auto", maxHeight: "350px" }}>
        {processedCars.length === 0 && (
          <ListItem>
            <ListItemText primary={`${t("car.noCars")}`} />
          </ListItem>
        )}
        {processedCars.map((car) => {
          return (
            <ListItem
              key={car.id}
              divider={true}
              secondaryAction={
                isHousekeeper ? (
                  <>
                    <Tooltip title={`${t("tooltips.edit")}`}>
                      <IconButton
                        onClick={() => {
                          setEditingCar(car);
                          setEditingDialogOpen(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <DoubleClickDelete
                      onClick={() => {
                        deleteCarById(car.id)
                          .then((response) => {
                            emitSnackbarEvent({
                              message: `${t("car.deletedSnackbarMessage")}`,
                              severity: "warning",
                            });
                            dispatch(getCarsAction());
                          })
                          .catch((error) =>
                            emitSnackbarEvent({
                              message: errorString(error),
                              severity: "error",
                            })
                          );
                      }}
                    />
                  </>
                ) : null
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <DirectionsCar />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={car.model} secondary={car.licensePlate} />
            </ListItem>
          );
        })}
      </List>
      {editingDialogOpen && editingCar && (
        <CarEditDialog
          car={editingCar}
          onClose={() => {
            setEditingCar(null);
            setEditingDialogOpen(false);
          }}
        />
      )}
    </>
  );
}
