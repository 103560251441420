import { SortType } from "../enums/sort-type";
import { FoodReservation } from "../entities/food-reservation";
import { FoodReservationType } from "../enums/food-reservation-type";

export function sortByDate(
  a: Date,
  b: Date,
  sortType: SortType = SortType.DESC
) {
  let dateA = new Date(a);
  let dateB = new Date(b);

  if (sortType === SortType.DESC) {
    return dateB.getTime() - dateA.getTime();
  }
  return dateA.getTime() - dateB.getTime();
}

function foodReservationTypeToNumber(foodReservationType: FoodReservationType) {
  if (foodReservationType === FoodReservationType.BREAKFAST) return 1;
  if (foodReservationType === FoodReservationType.LUNCH) return 2;
  return 3;
}

export function sortFoodReservation(
  a: FoodReservation,
  b: FoodReservation,
  sortType: SortType = SortType.DESC
) {
  let dateSortResult = sortByDate(a.date, b.date, sortType);
  if (dateSortResult === 0) {
    let aValue = foodReservationTypeToNumber(a.foodReservationType);
    let bValue = foodReservationTypeToNumber(b.foodReservationType);
    return sortByNumber(aValue, bValue, sortType);
  }
  return dateSortResult;
}

export function sortByNumber(
  a: number,
  b: number,
  sortType: SortType = SortType.DESC
) {
  if (sortType === SortType.DESC) {
    return b - a;
  }
  return a - b;
}
