import { Car } from "../entities/car";
import axios, { AxiosResponse } from "axios";
import {
  deleteCarByIdEndpoint,
  deleteCarReservationByIdEndpoint,
  getCarByIdEndpoint,
  getCarReservationByIdEndpoint,
  getCarReservationsEndpoint,
  getCarsEndpoint,
  postCarEndpoint,
  postCarReservationEndpoint,
  putCarByIdEndpoint,
  putCarReservationByIdEndpoint,
} from "../requests/endpoints";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { CarReservation } from "../entities/car-reservation";
import { CarReservationCreationBody } from "../request-bodies/car-reservation-creation-body";
import { CarCreationBody } from "../request-bodies/car-creation-body";
import { CarUpdateBody } from "../request-bodies/car-update-body";

export function getCars(): Promise<AxiosResponse<ReadResponseBody<Car[]>>> {
  return axios.get(getCarsEndpoint);
}

export function getCarById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<Car>>> {
  return axios.get(getCarByIdEndpoint(id));
}

export function postCar(
  car: CarCreationBody
): Promise<AxiosResponse<CreateResponseBody<Car>>> {
  return axios.post(postCarEndpoint, car);
}

export function putCarById(
  id: number,
  car: CarUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<Car>>> {
  return axios.put(putCarByIdEndpoint(id), car);
}

export function deleteCarById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteCarByIdEndpoint(id));
}

export function getCarReservations(): Promise<
  AxiosResponse<ReadResponseBody<CarReservation[]>>
> {
  return axios.get(getCarReservationsEndpoint);
}

export function getCarReservationById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<CarReservation>>> {
  return axios.get(getCarReservationByIdEndpoint(id));
}

export function postCarReservation(
  carReservation: CarReservationCreationBody
): Promise<AxiosResponse<CreateResponseBody<CarReservation>>> {
  return axios.post(postCarReservationEndpoint, carReservation);
}

export function putCarReservationById(
  id: number,
  carReservation: CarReservation
): Promise<AxiosResponse<UpdateResponseBody<CarReservation>>> {
  return axios.put(putCarReservationByIdEndpoint(id), carReservation);
}

export function deleteCarReservationById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteCarReservationByIdEndpoint(id));
}
