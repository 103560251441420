export function compareDatesIrrespectiveOfTime(dateA: Date, dateB: Date) {
  return (
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
}

export function isDateABiggerThenOrEqualToDateBIrrespectiveOfTime(
  dateA: Date,
  dateB: Date
) {
  let dateANoTime = new Date(
    dateA.getFullYear(),
    dateA.getMonth(),
    dateA.getDate()
  );
  let dateBNoTime = new Date(
    dateB.getFullYear(),
    dateB.getMonth(),
    dateB.getDate()
  );

  return dateBNoTime.getTime() >= dateANoTime.getTime();
}
export function dateToTimelessDate(date: Date) {
  date = new Date(date);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
export function getTodayNoTime() {
  let today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

export function sqlDateToDate(sqlDate: Date) {
  sqlDate = new Date(sqlDate);
  let date = new Date(
    sqlDate.getUTCFullYear(),
    sqlDate.getUTCMonth(),
    sqlDate.getUTCDate()
  );
  return date;
}

export function dateToTimelessUTCDateKeepValues(date: Date) {
  date = new Date(date);
  let newDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );

  return newDate;
}

export function UTCDateToTimelessDateKeepValues(UTCDate: Date) {
  UTCDate = new Date(UTCDate);
  return new Date(
    UTCDate.getUTCFullYear(),
    UTCDate.getUTCMonth(),
    UTCDate.getUTCDate()
  );
}
