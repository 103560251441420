import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Alert,
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Tooltip,
} from "@mui/material";
import {
  Cached,
  ContentCopy,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import randomatic from "randomatic";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function PasswordInput({
  password,
  setPassword,
  autoGenerate = false,
  generateButton = false,
  clipboard = false,
  required = false,
  fullWidth = false,
  label = null,
  sx,
  id,
  name,
  autoComplete,
  margin,
}: {
  password: string | null;
  setPassword: Dispatch<SetStateAction<string>>;
  autoGenerate?: boolean;
  generateButton?: boolean;
  clipboard?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  label?: string | null;
  sx?: any;
  name?: string;
  id?: string;
  autoComplete?: string;
  margin?: FormControlProps["margin"];
}) {
  const [showPassword, setShowPassword] = useState<boolean>(autoGenerate);
  const emitSnackbarEvent = useGlobalSnackbar();

  useEffect(() => {
    if (autoGenerate) {
      setPassword(randomatic("Aa0", 14));
    }
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <FormControl
        sx={{ width: fullWidth ? "100%" : "auto" }}
        variant="outlined"
        margin={margin ? margin : "normal"}
      >
        <InputLabel htmlFor="outlined-adornment-password">
          {label ? label : `${t("passwordInput.passwordLabel")}`}
          {required ? " *" : ""}
        </InputLabel>
        <OutlinedInput
          required={required}
          sx={sx}
          id={id}
          name={name}
          autoComplete={autoComplete}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              {generateButton && (
                <Tooltip
                  title={`${t("passwordInput.generateNewPasswordTooltip")}`}
                >
                  <IconButton
                    onClick={async () => {
                      let generatePassword = randomatic("Aa0", 14);
                      setPassword(generatePassword);
                      setShowPassword(true);
                      await navigator.clipboard.writeText(generatePassword);
                      emitSnackbarEvent({
                        message: `${t(
                          "passwordInput.copiedToClipboardSnackbarMessage"
                        )}`,
                        severity: "success",
                      });
                    }}
                    edge="end"
                  >
                    <Cached />
                  </IconButton>
                </Tooltip>
              )}
              {clipboard && password && (
                <Tooltip title={`${t("passwordInput.copyToClipboardTooltip")}`}>
                  <IconButton
                    onClick={async () => {
                      if (password) {
                        await navigator.clipboard.writeText(password);
                        emitSnackbarEvent({
                          message: `${t(
                            "passwordInput.copiedToClipboardSnackbarMessage"
                          )}`,
                          severity: "success",
                        });
                      }
                    }}
                    edge="end"
                  >
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={`${t("passwordInput.togglePasswordVisibilityTooltip")}`}
              >
                <IconButton
                  onClick={() => setShowPassword((old) => !old)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          label={label ? label : `${t("passwordInput.passwordLabel")}`}
        />
      </FormControl>
    </>
  );
}
