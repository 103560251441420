import React, { useState } from "react";
import Calendar from "../calendar/calendar";
import { Event, SlotInfo } from "react-big-calendar";
import OfficeReservationCreateDialog from "./office-reservation-create-dialog";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  customReservationsToCalendarEvents,
  officeReservationsToCalendarEvents,
} from "../../functions/calendar-events-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import useUser from "../../hooks/use-user";
import { useTranslation } from "react-i18next";
import { FoodReservation } from "../../entities/food-reservation";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteFoodReservationById } from "../../requests/food-requests";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { errorString } from "../../functions/error-functions";
import { OfficeReservation } from "../../entities/office-reservation";
import { deleteOfficeReservationById } from "../../requests/office-requests";
import { getOfficeReservationsAction } from "../../actions/office-actions";
import {
  Apartment,
  Bookmark,
  Favorite,
  MeetingRoom,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { VariableCustomEvent } from "../mixed/mixed-calendar";

const MS_IN_MINUTE = 60000;

export default function OfficeReservationsCalendar({
  hideHousekeeperEvents,
}: {
  hideHousekeeperEvents: boolean;
}) {
  const [slotInfo, setSlotInfo] = useState<SlotInfo | null>();

  const officeReservationEvents = useSelector((state: RootState) =>
    officeReservationsToCalendarEvents(state.officesState.officeReservations)
  );
  const customReservationEvents = useSelector((state: RootState) => {
    return customReservationsToCalendarEvents([
      ...state.customReservationsState.customReservations,
    ]);
  });
  return (
    <>
      <Calendar
        onSelectSlot={(slotInfo) => {
          setSlotInfo(slotInfo);
        }}
        events={[
          ...officeReservationEvents,
          ...(hideHousekeeperEvents ? [] : customReservationEvents),
        ]}
        customEvent={VariableCustomEvent}
      />
      {slotInfo && (
        <OfficeReservationCreateDialog
          initialStart={new Date(slotInfo.start)}
          initialEnd={new Date(new Date(slotInfo.end).getTime() - MS_IN_MINUTE)}
          onClose={() => {
            setSlotInfo(null);
          }}
        />
      )}
    </>
  );
}

export function CustomOfficeReservationEvent({ event }: { event: Event }) {
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const { t } = useTranslation();

  let officeReservation: OfficeReservation = event.resource;

  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <MeetingRoom sx={{ height: 24 }} />
      {user && user.id === officeReservation.userId && (
        <Bookmark sx={{ height: 24 }} />
      )}
      <Typography
        sx={{
          ml: 1,
          maxWidth: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title}
      </Typography>
      {((hovering && isHousekeeper) ||
        (hovering && user && user.id === officeReservation.userId)) && (
        <>
          <DoubleClickDelete
            sx={{ ml: 1, height: 24, width: 24 }}
            onClick={() => {
              deleteOfficeReservationById(officeReservation.id)
                .then((response) => {
                  emitSnackbarEvent({
                    message: `${t("office.reservationDeletedSnackbarMessage")}`,
                    severity: "warning",
                  });
                  dispatch(getOfficeReservationsAction());
                })
                .catch((error) =>
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  })
                );
            }}
          />
        </>
      )}
    </div>
  );
}
