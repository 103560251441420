import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowForward, Close, MeetingRoom } from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { useDispatch } from "react-redux";

import { errorString } from "../../functions/error-functions";
import useUser from "../../hooks/use-user";
import OfficeSelect, { OfficeOption } from "../selects/office-select";
import { OfficeReservationCreationBody } from "../../request-bodies/office-reservation-creation-body";
import { postOfficeReservation } from "../../requests/office-requests";
import { getOfficeReservationsAction } from "../../actions/office-actions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function OfficeReservationCreateDialog({
  initialStart,
  initialEnd,
  onClose,
}: {
  initialStart: Date;
  initialEnd: Date;
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [start, setStart] = useState<Date>(initialStart);
  const [end, setEnd] = useState<Date>(initialEnd);
  const [selectedOfficeOption, setSelectedOfficeOption] =
    useState<OfficeOption | null>(null);
  const [error, setError] = useState<string | null>(null);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        handleClose();
      }}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <MeetingRoom />
          </Avatar>
          <Typography>{t("office.reservationCreateDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <DateTimePicker
              onChange={(event) => {
                if (event) setStart(event);
              }}
              value={start}
              label={`${t("startLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
            <ArrowForward />
            <DateTimePicker
              onChange={(event) => {
                if (event) setEnd(event);
              }}
              value={end}
              label={`${t("endLabel")}`}
              renderInput={(props) => <TextField required={true} {...props} />}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <OfficeSelect
              selectedOfficeOption={selectedOfficeOption}
              estSelectedOfficeOption={setSelectedOfficeOption}
              required={true}
            />
          </Box>
          <Box>
            <Button fullWidth={true} variant={"contained"} type={"submit"}>
              {t("createButtonText")}
            </Button>
          </Box>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    // I don't validate inside of a function because then i still need to do null checks (next to standard form validation)
    if (!start) {
      setError(`${t("office.noStartDateError")}`);
      return false;
    }
    if (start > end) {
      setError(`${t("office.startDateBeforeEndDateError")}`);
      return false;
    }
    if (!selectedOfficeOption) {
      setError(`${t("office.noOfficeSelectedError")}`);
      return false;
    }
    setError(null);

    let officeReservationCreationBody = {
      start,
      end,
      officeId: selectedOfficeOption.id,
    } as OfficeReservationCreationBody;

    postOfficeReservation(officeReservationCreationBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("office.reservationCreatedSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getOfficeReservationsAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
