import axios, { AxiosResponse } from "axios";
import {
  deleteUserByIdEndpoint,
  getSelfEndpoint,
  getUsersEndpoint,
  postUserEndpoint,
  putUserByIdEndpoint,
  toggleUserByIdEndpoint,
} from "./endpoints";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { User } from "../entities/user";
import { UserCreationBody } from "../request-bodies/user-creation-body";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { UserUpdateBody } from "../request-bodies/user-update-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";

export function getSelf(): Promise<AxiosResponse<ReadResponseBody<User>>> {
  return axios.get(getSelfEndpoint);
}

export function getUsers(): Promise<AxiosResponse<ReadResponseBody<User[]>>> {
  return axios.get(getUsersEndpoint);
}

export function postUser(
  userCreationBody: UserCreationBody
): Promise<AxiosResponse<CreateResponseBody<User>>> {
  return axios.post(postUserEndpoint, userCreationBody);
}

export function putUserById(
  userId: number,
  userUpdateBody: UserUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<User>>> {
  return axios.put(putUserByIdEndpoint(userId), userUpdateBody);
}
export function toggleUser(userId: number) {
  return axios.put(toggleUserByIdEndpoint(userId));
}

export function deleteUserById(
  userId: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteUserByIdEndpoint(userId));
}
