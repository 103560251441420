import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, ReportProblem } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { ProblemCreationBody } from "../../request-bodies/problem-creation-body";
import { postProblem } from "../../requests/problem-requests";
import { getProblemsAction } from "../../actions/problem-actions";
import { useTranslation } from "react-i18next";

export default function ProblemReportDialog({
  onClose,
}: {
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [subject, setSubject] = useState<string>("");
  const [text, setText] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <ReportProblem />
          </Avatar>
          <Typography>{t("problem.reportDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            size={"small"}
            variant={"standard"}
            autoFocus={true}
            sx={{ mb: 2 }}
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            label={`${t("problem.subjectLabel")}`}
            required={true}
            fullWidth={true}
          />
          <Box sx={{ mb: 2 }}>
            <TextareaAutosize
              required={true}
              value={text}
              onChange={(event) => setText(event.target.value)}
              style={{ width: "100%", minHeight: "200px" }}
            />
          </Box>

          <Button
            color={"error"}
            type={"submit"}
            fullWidth={true}
            variant={"contained"}
          >
            {t("reportButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    let problemCreationBody = { subject, text } as ProblemCreationBody;

    postProblem(problemCreationBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("problem.createdSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getProblemsAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
