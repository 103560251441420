import React, { CSSProperties } from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";
import { MeetingRoom } from "@mui/icons-material";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { errorString } from "../../functions/error-functions";
import { OfficeReservation } from "../../entities/office-reservation";
import { deleteOfficeReservationById } from "../../requests/office-requests";
import { getOfficeReservationsAction } from "../../actions/office-actions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";

export default function OfficeReservationListItem({
  officeReservation,
  style,
}: {
  officeReservation: OfficeReservation;
  style: CSSProperties;
}) {
  const dispatch = useDispatch();
  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const emitSnackbarEvent = useGlobalSnackbar();

  const { t } = useTranslation();

  let start = new Date(officeReservation.start);
  let end = new Date(officeReservation.end);

  return (
    <ListItem
      key={officeReservation.id}
      divider={true}
      style={style}
      secondaryAction={
        isHousekeeper || (user && user.id === officeReservation.userId) ? (
          <>
            <DoubleClickDelete
              onClick={() => {
                deleteOfficeReservationById(officeReservation.id)
                  .then((response) => {
                    emitSnackbarEvent({
                      message: `${t(
                        "office.reservationDeletedSnackbarMessage"
                      )}`,
                      severity: "warning",
                    });
                    dispatch(getOfficeReservationsAction());
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          </>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar>
          <MeetingRoom />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={officeReservation.office.name}
        secondary={`${t("reservedBy")}: ${officeReservation.user.username}`}
      />
      <ListItemText
        primary={
          start.getTime() === end.getTime()
            ? start.toDateString()
            : `${start.toUTCString()} ${t("until")} ${end.toUTCString()}`
        }
      />
    </ListItem>
  );
}
