import React, { FormEvent, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Apartment as ApartmentIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import UserSelect, { UserOption } from "../selects/user-select";
import { ApartmentCreationBody } from "../../request-bodies/apartment-creation-body";
import { postApartment } from "../../requests/apartment-requests";
import { getApartmentsAction } from "../../actions/apartment-actions";
import { Role } from "../../enums/role";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import UsersSelect from "../selects/users-select";
import { ReservationMode } from "../../enums/reservation-mode";
import ReservationModeSelect, {
  ReservationModeOption,
  reservationModeOptions,
} from "../selects/reservation-mode-select";

export default function ApartmentCreateDialog({
  onClose,
}: {
  onClose: () => any;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [name, setName] = useState<string>("");
  const [number, setNumber] = useState<number>(0);

  const [selectedOwnerOptions, setSelectedOwnerOptions] = useState<
    UserOption[]
  >([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState<UserOption[]>(
    []
  );

  const [error, setError] = useState<string | null>(null);
  const [selectedReservationModeOption, setSelectedReservationModeOption] =
    useState<ReservationModeOption>(reservationModeOptions[0]);

  const [inactive, setInactive] = useState<boolean>(false);
  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  useEffect(() => {
    setSelectedUserOptions((old) =>
      old.filter(
        (option) =>
          !selectedOwnerOptions.some(
            (innerOption) => innerOption.id === option.id
          )
      )
    );
  }, [selectedOwnerOptions]);

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <ApartmentIcon />
          </Avatar>
          <Typography>{t("apartment.createDialogTitle")}</Typography>
        </Box>
        <Tooltip title={`${t("tooltips.close")}`}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            sx={{ mb: 2 }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={`${t("apartment.nameLabel")}`}
            required={true}
            fullWidth={true}
          />

          <TextField
            sx={{ mb: 2 }}
            value={number}
            type={"number"}
            onChange={(event) => setNumber(parseInt(event.target.value))}
            label={`${t("apartment.numberLabel")}`}
            required={true}
            fullWidth={true}
          />

          <Box sx={{ mb: 2 }}>
            <UsersSelect
              selectedUserOptions={selectedUserOptions}
              setSelectedUserOptions={setSelectedUserOptions}
              userFilterFunction={(user) =>
                !selectedOwnerOptions.some((option) => option.id === user.id)
              }
              label={`${t("apartment.usersLabel")}`}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <UsersSelect
              selectedUserOptions={selectedOwnerOptions}
              setSelectedUserOptions={setSelectedOwnerOptions}
              userFilterFunction={(user) =>
                user.role === Role.OWNER || user.role === Role.HOUSEKEEPER
              }
              label={`${t("apartment.ownerLabel")}`}
            />
            {/* {selectedUserOption && ( */}
            {/*   <FormControlLabel */}
            {/*     control={ */}
            {/*       <Checkbox */}
            {/*         checked={ownerExclusive} */}
            {/*         onChange={(event) => */}
            {/*           setOwnerExclusive(event.target.checked) */}
            {/*         } */}
            {/*       /> */}
            {/*     } */}
            {/*     label={`${t("apartment.ownerExclusiveLabel")}`} */}
            {/*   /> */}
            {/* )} */}
          </Box>
          <Box>
            <ReservationModeSelect
              selectedReservationModeOption={selectedReservationModeOption}
              setSelectedReservationModeOption={
                setSelectedReservationModeOption
              }
              label={`${t("apartment.reservationModeLabel")}`}
            />
          </Box>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Checkbox
                checked={inactive}
                onChange={(event) => setInactive(event.target.checked)}
              />
            }
            label={`${t("apartment.inactiveLabel")}`}
          />

          <Button type={"submit"} fullWidth={true} variant={"contained"}>
            {t("createButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    let apartmentCreationBody = {
      name,
      number,
      ownerIds: selectedOwnerOptions.map((userOption) => userOption.id),
      reservationMode: selectedReservationModeOption.reservationMode,
      userIds: selectedUserOptions.map((userOption) => userOption.id),
      inactive,
    } as ApartmentCreationBody;

    postApartment(apartmentCreationBody)
      .then((response) => {
        dispatch(getApartmentsAction());
        emitSnackbarEvent({
          message: `${t("apartment.createdSnackbarMessage")}`,
          severity: "success",
        });
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
