import { Dispatch } from "redux";
import {
  setApartmentReservations,
  setApartments,
} from "../reducers/apartments-reducer";
import {
  getApartmentReservations,
  getApartments,
} from "../requests/apartment-requests";

export function getApartmentsAction() {
  return (dispatch: Dispatch) => {
    getApartments().then((response) =>
      dispatch(setApartments(response.data.data))
    );
  };
}

export function getApartmentReservationsAction() {
  return (dispatch: Dispatch) => {
    getApartmentReservations().then((response) =>
      dispatch(setApartmentReservations(response.data.data))
    );
  };
}
