import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../index";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { Edit, Person, PersonOff } from "@mui/icons-material";
import { User } from "../../entities/user";
import UserEditDialog from "./user-edit-dialog";
import DoubleClickDelete from "../buttons/double-click-delete";
import { getUsersAction } from "../../actions/user-actions";
import { deleteUserById, toggleUser } from "../../requests/user-requests";
import { errorString } from "../../functions/error-functions";
import { sortByNumber } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { getProblemsAction } from "../../actions/problem-actions";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import useApplicationState from "../../hooks/use-application-state";
import DialogDelete from "../buttons/dialog-delete";

export default function UserList() {
  const users = useSelector((state: RootState) => state.usersState.users);
  const [showDeactivatedUsers, setShowDeactivatedUsers] =
    useState<boolean>(false);

  useEffect(() => {
    setProcessedUsers(
      [...users]
        .filter((user) => {
          if (!showDeactivatedUsers && user.deactivated) return false;
          return true;
        })
        .sort((a, b) => sortByNumber(a.id, b.id, SortType.ASC))
    );
  }, [users, showDeactivatedUsers]);
  const [stateFetched, refetchAll] = useApplicationState();

  const [processedUsers, setProcessedUsers] = useState<User[]>([]);

  const [userEditDialogOpen, setUserEditDialogOpen] = useState<boolean>(false);

  const [editingUser, setEditingUser] = useState<User | null>(null);

  const emitSnackbarEvent = useGlobalSnackbar();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showDeactivatedUsers}
              onChange={(event) =>
                setShowDeactivatedUsers(event.target.checked)
              }
            />
          }
          label={`${t("showDeactivated")}`}
          labelPlacement="end"
        />
      </Stack>
      <List sx={{ overflowY: "auto", maxHeight: "500px" }}>
        {processedUsers.map((user) => {
          return (
            <ListItem
              divider={true}
              key={user.id}
              secondaryAction={
                <>
                  <Tooltip title={`${t("tooltips.edit")}`}>
                    <IconButton
                      onClick={() => {
                        setEditingUser(user);
                        setUserEditDialogOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`${
                      user.deactivated
                        ? t("user.activate")
                        : t("user.deactivate")
                    }`}
                  >
                    <IconButton
                      onClick={() => {
                        toggleUser(user.id)
                          .then((response) => {
                            emitSnackbarEvent({
                              message: `${t("user.updateSnackbarMessage")}`,
                              severity: "success",
                            });
                            dispatch(getUsersAction());
                          })
                          .catch((error) =>
                            emitSnackbarEvent({
                              message: errorString(error),
                              severity: "error",
                            })
                          );
                      }}
                    >
                      {!user.deactivated ? <PersonOff /> : <Person />}
                    </IconButton>
                  </Tooltip>
                  <DialogDelete
                    message={`${t("user.deleteDialogWarningMessage")}`}
                    title={`${t("user.deleteDialogTitle")}`}
                    avatarIcon={<Person />}
                    onClick={() => {
                      deleteUserById(user.id)
                        .then((response) => {
                          emitSnackbarEvent({
                            message: `${t("user.deletedSnackbarMessage")}`,
                            severity: "warning",
                          });
                          refetchAll();
                        })
                        .catch((error) =>
                          emitSnackbarEvent({
                            message: errorString(error),
                            severity: "error",
                          })
                        );
                    }}
                  />
                  {/* <DoubleClickDelete */}
                  {/*   onClick={() => { */}
                  {/*     deleteUserById(user.id) */}
                  {/*       .then((response) => { */}
                  {/*         emitSnackbarEvent({ */}
                  {/*           message: `${t("user.deletedSnackbarMessage")}`, */}
                  {/*           severity: "warning", */}
                  {/*         }); */}
                  {/*         refetchAll(); */}
                  {/*       }) */}
                  {/*       .catch((error) => */}
                  {/*         emitSnackbarEvent({ */}
                  {/*           message: errorString(error), */}
                  {/*           severity: "error", */}
                  {/*         }) */}
                  {/*       ); */}
                  {/*   }} */}
                  {/* /> */}
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>{user.deactivated ? <PersonOff /> : <Person />}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} secondary={user.role} />
            </ListItem>
          );
        })}
      </List>
      {userEditDialogOpen && editingUser && (
        <UserEditDialog
          onClose={() => setUserEditDialogOpen(false)}
          user={editingUser}
        />
      )}
    </>
  );
}
