import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../entities/user";

export type UsersState = {
  users: User[];
  usersFetched: boolean;
};

const initialState = {
  users: [],
  usersFetched: false,
} as UsersState;

export const usersStateSlice = createSlice({
  name: "usersState",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
      state.usersFetched = true;
    },
  },
});

export const usersState = usersStateSlice.reducer;
export const { setUsers } = usersStateSlice.actions;
