import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaletteMode } from "@mui/material";

const initialState = {
  mode: "light",
} as { mode: PaletteMode };

export const themeStateSlice = createSlice({
  name: "themeState",
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<PaletteMode>) {
      state.mode = action.payload;
    },
  },
});

export const themeState = themeStateSlice.reducer;
export const { setMode } = themeStateSlice.actions;
