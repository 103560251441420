import { Problem } from "../entities/problem";
import axios, { AxiosResponse } from "axios";
import {
  deleteProblemByIdEndpoint,
  getProblemByIdEndpoint,
  getProblemsEndpoint,
  postProblemEndpoint,
  putProblemByIdEndpoint,
  resolveProblemByIdEndpoint,
} from "./endpoints";
import { DeleteResponseBody } from "../responses-bodies/delete-response-body";
import { UpdateResponseBody } from "../responses-bodies/update-response-body";
import { CreateResponseBody } from "../responses-bodies/create-response-body";
import { ReadResponseBody } from "../responses-bodies/read-response-body";
import { ProblemCreationBody } from "../request-bodies/problem-creation-body";

export function getProblems(): Promise<
  AxiosResponse<ReadResponseBody<Problem[]>>
> {
  return axios.get(getProblemsEndpoint);
}

export function getProblemById(
  id: number
): Promise<AxiosResponse<ReadResponseBody<Problem>>> {
  return axios.get(getProblemByIdEndpoint(id));
}

export function postProblem(
  problem: ProblemCreationBody
): Promise<AxiosResponse<CreateResponseBody<Problem>>> {
  return axios.post(postProblemEndpoint, problem);
}

export function putProblemById(
  id: number,
  problem: Problem
): Promise<AxiosResponse<UpdateResponseBody<Problem>>> {
  return axios.put(putProblemByIdEndpoint(id), problem);
}

export function deleteProblemById(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteProblemByIdEndpoint(id));
}

export function resolveProblemById(
  id: number
): Promise<AxiosResponse<UpdateResponseBody<Problem>>> {
  return axios.put(resolveProblemByIdEndpoint(id));
}
