import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTitle from "../../hooks/use-title";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle, Close, HelpOutline, OpenInNew } from "@mui/icons-material";
import MixedCalendar from "../mixed/mixed-calendar";
import { useLocation, useNavigate } from "react-router-dom";
import TodaysFoodReservations from "./todays-food-reservations";
import ReservationsStartingToday from "./reservations-starting-today";
import UpAndComingReservations from "./up-and-coming-reservations";
import ReservationsEndingToday from "./reservations-ending-today";
import CustomReservationCreateDialog from "../custom-reservations/custom-reservation-create-dialog";
import ApartmentReservationCreateDialog from "../apartments/apartment-reservation-create-dialog";
import { SlotInfo } from "react-big-calendar";
import { MS_IN_MINUTE } from "../../consts";
import CustomReservationList from "../custom-reservations/custom-reservation-list";

export default function Dashboard() {
  const { t } = useTranslation();
  useTitle(`${t("dashboard.title")}`);

  const [slotInfo, setSlotInfo] = useState<SlotInfo | null>();

  const [
    createCustomReservationDialogOpen,
    setCreateCustomReservationDialogOpen,
  ] = useState<boolean>(false);
  const [fullscreenCalendarDialogOpen, setFullscreenCalendarDialogOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.pathname.includes("new-custom-reservation"))
      setCreateCustomReservationDialogOpen(true);
    else if (location && location.pathname.includes("fullscreen-calendar"))
      setFullscreenCalendarDialogOpen(true);
    else {
      setFullscreenCalendarDialogOpen(false);
      setCreateCustomReservationDialogOpen(false);
    }
  }, [location]);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>{renderCalendar()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <TodaysFoodReservations />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <ReservationsStartingToday />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <ReservationsEndingToday />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <UpAndComingReservations />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {t("customReservation.listTitle")}
              </Typography>
              <Tooltip title={`${t("customReservation.newTooltip")}`}>
                <IconButton onClick={() => navigate("/new-custom-reservation")}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>
            <CustomReservationList />
          </Paper>
        </Grid>
      </Grid>
      {fullscreenCalendarDialogOpen && (
        <Dialog fullScreen open={fullscreenCalendarDialogOpen}>
          <DialogContent>{renderCalendar()}</DialogContent>
        </Dialog>
      )}
      {createCustomReservationDialogOpen && (
        <CustomReservationCreateDialog
          initialStart={slotInfo ? new Date(slotInfo.start) : new Date()}
          initialEnd={
            slotInfo
              ? new Date(new Date(slotInfo.end).getTime() - MS_IN_MINUTE)
              : new Date()
          }
          onClose={() => {
            setSlotInfo(null);
            setCreateCustomReservationDialogOpen(false);
          }}
        />
      )}
    </Container>
  );

  function renderCalendar() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {t("home.calendarTitle")}
            </Typography>
            {!fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.openInFullscreen")}`}>
                <IconButton
                  onClick={() => {
                    navigate("/fullscreen-calendar");
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            {/* <Tooltip title={`${t("tooltips.deleteOnlyCalendarHelp")}`}> */}
            {/*   <IconButton> */}
            {/*     <HelpOutline /> */}
            {/*   </IconButton> */}
            {/* </Tooltip> */}
            <Tooltip title={`${t("customReservation.newTooltip")}`}>
              <IconButton onClick={() => navigate("/new-custom-reservation")}>
                <AddCircle />
              </IconButton>
            </Tooltip>
            {fullscreenCalendarDialogOpen && (
              <Tooltip title={`${t("tooltips.close")}`}>
                <IconButton
                  onClick={() => setFullscreenCalendarDialogOpen(false)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box sx={{ height: fullscreenCalendarDialogOpen ? "90%" : 500 }}>
          <MixedCalendar
            onSelectSlot={(slotInfo) => {
              setSlotInfo(slotInfo);
              setCreateCustomReservationDialogOpen(true);
            }}
            showFoodReservations={true}
            showApartmentReservations={true}
            showOfficeReservations={true}
            showCarReservations={true}
            showCustomReservations={true}
          />
        </Box>
      </>
    );
  }
}
