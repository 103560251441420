import React, { useEffect, useState } from "react";
import useTitle from "../../hooks/use-title";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import UserCreateDialog from "./user-create-dialog";
import UserList from "./user-list";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Users() {
  const { t } = useTranslation();
  useTitle(`${t("user.title")}`);

  const location = useLocation();

  const [createUserDialogOpen, setCreateUserDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (location && location.pathname.includes("new-user")) {
      setCreateUserDialogOpen(true);
    }
  }, [location]);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {t("user.listTitle")}
                </Typography>
              </Stack>
              <Tooltip title={`${t("user.newTooltip")}`}>
                <IconButton onClick={() => setCreateUserDialogOpen(true)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Box>

            <UserList />
          </Paper>
        </Grid>
      </Grid>
      {createUserDialogOpen && (
        <UserCreateDialog onClose={() => setCreateUserDialogOpen(false)} />
      )}
    </Container>
  );
}
