import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Document } from "../../entities/document";
import {
  deleteDocumentById,
  getDocumentById,
  postDocument,
  putDocumentById,
} from "../../requests/document-requests";
import {
  Alert,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextareaAutosize,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DocumentEditor from "./document-editor";
import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import DoubleClickDelete from "../buttons/double-click-delete";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsAction,
  getDocumentsWithoutHtmlAction,
} from "../../actions/document-actions";
import { AxiosError } from "axios";
import { DocumentCreationBody } from "../../request-bodies/document-creation-body";
import { errorString } from "../../functions/error-functions";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { RootState } from "../../index";

export default function DocumentCreate() {
  const navigate = useNavigate();

  const documents = useSelector(
    (state: RootState) => state.documentsState.documents
  );

  const [html, setHtml] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [index, setIndex] = useState<number>(
    Math.max(...documents.map((document) => document.index)) + 1
  );
  const [parentTopic, setParentTopic] = useState<string>("");

  const [useRawHtml, setUseRawHtml] = useState<boolean>(false);
  const dispatch = useDispatch();

  const emitSnackbarEvent = useGlobalSnackbar();

  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Box sx={{ width: "30%" }}>
                <TextField
                  required={true}
                  size={"small"}
                  label={`${t("document.documentTitleLabel")}`}
                  value={title}
                  variant={"standard"}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Box>
              <Box sx={{ width: "30%" }}>
                <TextField
                  size={"small"}
                  label={`${t("document.documentParentTopicLabel")}`}
                  value={parentTopic}
                  variant={"standard"}
                  onChange={(event) => setParentTopic(event.target.value)}
                />
              </Box>

              <Box sx={{ width: "30%" }}>
                <TextField
                  required={true}
                  size={"small"}
                  label={`${t("document.documentIndexLabel")}`}
                  value={index}
                  type={"number"}
                  variant={"standard"}
                  onChange={(event) => setIndex(parseInt(event.target.value))}
                />
              </Box>

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "30%",
                }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography>{t("document.editor")}</Typography>
                <Switch
                  checked={useRawHtml}
                  onChange={(event) => setUseRawHtml(event.target.checked)}
                  defaultChecked
                  color="default"
                />
                <Typography>{t("document.rawHtml")}</Typography>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  width: "30%",
                }}
              >
                <Tooltip title={`${t("tooltips.save")}`}>
                  <IconButton onClick={saveDocument} color={"success"}>
                    <Save />
                  </IconButton>
                </Tooltip>

                <Tooltip title={`${t("tooltips.cancel")}`}>
                  <IconButton
                    color={"error"}
                    component={Link}
                    to={"/information"}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {error && <Alert severity={"error"}>{error}</Alert>}

            {!useRawHtml ? (
              <Box sx={{ border: "1px solid grey" }}>
                <DocumentEditor html={html} setHtml={setHtml} />
              </Box>
            ) : (
              <TextareaAutosize
                value={html}
                style={{ width: "100%" }}
                onChange={(event) => setHtml(event.target.value)}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );

  function saveDocument() {
    if (validateDocument()) {
      let documentCreationBody: DocumentCreationBody = {
        html: html,
        title: title,
        index,
        parentTopic: parentTopic ? parentTopic : null,
      };
      postDocument(documentCreationBody)
        .then((response) => {
          emitSnackbarEvent({
            message: `${t("document.createdSnackbarMessage")}`,
            severity: "success",
          });
          dispatch(getDocumentsAction());
          navigate("/information");
        })
        .catch((error) => setError(errorString(error)));
    }
  }

  function validateDocument() {
    if (title === "") {
      setError(`${t("document.titleEmptyError")}`);
      return false;
    }
    return true;
  }
}
