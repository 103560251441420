import axios from "axios";
import { setUsers } from "../reducers/users-reducer";
import { getSelf, getUsers } from "../requests/user-requests";
import { Dispatch } from "redux";
import { setAuthorizationData } from "../reducers/authorization-reducer";

export function getUsersAction() {
  return (dispatch: Dispatch) => {
    getUsers()
      .then((response) => dispatch(setUsers(response.data.data)))
      .catch((error) => console.error(error));
  };
}

export function getSelfAction() {
  return (dispatch: Dispatch) => {
    getSelf()
      .then((response) => dispatch(setAuthorizationData(response.data.data)))
      .catch((error) => console.error(error));
  };
}
