import React, { CSSProperties, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { Apartment as ApartmentIcon } from "@mui/icons-material";
import { FoodReservation } from "../../entities/food-reservation";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import { CarReservation } from "../../entities/car-reservation";
import { OfficeReservation } from "../../entities/office-reservation";
import { RootState } from "../../index";
import { useSelector } from "react-redux";
import {
  sortByNumber,
  sortFoodReservation,
} from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import FoodReservationListItem from "../food/food-reservation-list-item";
import ApartmentReservationListItem from "../apartments/apartment-reservation-list-item";
import CarReservationListItem from "../cars/car-reservation-list-item";
import OfficeReservationListItem from "../offices/office-reservation-list-item";
import useUser from "../../hooks/use-user";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { useTranslation } from "react-i18next";

export default function MixedReservationList({
  filter,
  emptyMessage,
}: {
  filter?: (
    reservation:
      | FoodReservation
      | ApartmentReservation
      | CarReservation
      | OfficeReservation
  ) => boolean;
  emptyMessage?: string;
}) {
  const { t } = useTranslation();
  const foodReservation = useSelector(
    (state: RootState) => state.foodState.foodReservations
  );
  const carReservations = useSelector(
    (state: RootState) => state.carsState.carReservations
  );
  const apartmentReservations = useSelector(
    (state: RootState) => state.apartmentsState.apartmentReservations
  );
  const officeReservation = useSelector(
    (state: RootState) => state.officesState.officeReservations
  );

  const [processedReservations, setProcessedReservations] = useState<
    (
      | FoodReservation
      | ApartmentReservation
      | CarReservation
      | OfficeReservation
    )[]
  >([]);

  useEffect(() => {
    let reservations = [
      ...officeReservation.map(
        (officeReservation) => new OfficeReservation(officeReservation)
      ),
      ...carReservations.map(
        (carReservation) => new CarReservation(carReservation)
      ),
      ...apartmentReservations.map(
        (apartmentReservation) => new ApartmentReservation(apartmentReservation)
      ),
      ...foodReservation.map(
        (foodReservation) => new FoodReservation(foodReservation)
      ),
    ]
      .filter(filter ? filter : (reservation) => true)
      .sort((a, b) => {
        if (a instanceof FoodReservation && b instanceof FoodReservation)
          return sortFoodReservation(a, b, SortType.ASC);
        let aValue =
          a instanceof FoodReservation || a instanceof CarReservation
            ? new Date(a.date).getTime()
            : new Date(a.start).getTime();
        let bValue =
          b instanceof FoodReservation || b instanceof CarReservation
            ? new Date(b.date).getTime()
            : new Date(b.start).getTime();

        return sortByNumber(aValue, bValue, SortType.ASC);
      });

    setProcessedReservations(reservations);
  }, [
    foodReservation,
    apartmentReservations,
    carReservations,
    officeReservation,
    filter,
  ]);

  return (
    <>
      {/*<Stack direction={"row"}>*/}
      {/*  <FormControlLabel*/}
      {/*    control={*/}
      {/*      <Checkbox*/}
      {/*        checked={showExpired}*/}
      {/*        onChange={(event) => setShowExpired(event.target.checked)}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    label={"Show Expired"}*/}
      {/*    labelPlacement="end"*/}
      {/*  />*/}
      {/*  <FormControlLabel*/}
      {/*    control={*/}
      {/*      <Checkbox*/}
      {/*        checked={showOthers}*/}
      {/*        onChange={(event) => setShowOthers(event.target.checked)}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    label={"Show others"}*/}
      {/*    labelPlacement="end"*/}
      {/*  />*/}
      {/*</Stack>*/}
      <Box
        sx={{
          overflowY: "auto",
          height: "350px",
          maxHeight: "350px",
          width: "100%",
        }}
      >
        {processedReservations.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText
                primary={
                  emptyMessage
                    ? emptyMessage
                    : `${t("noReservationsListItemText")}`
                }
              />
            </ListItem>
          </List>
        ) : (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  itemSize={60}
                  height={height}
                  itemCount={processedReservations.length}
                  width={width}
                >
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        )}
      </Box>
    </>
  );

  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    let reservation = processedReservations[index];

    if (reservation instanceof FoodReservation)
      return (
        <FoodReservationListItem
          style={style}
          key={reservation.id}
          foodReservation={reservation}
        />
      );
    if (reservation instanceof CarReservation)
      return (
        <CarReservationListItem
          style={style}
          key={reservation.id}
          carReservation={reservation}
        />
      );
    else if (reservation instanceof ApartmentReservation)
      return (
        <ApartmentReservationListItem
          style={style}
          key={reservation.id}
          apartmentReservation={reservation}
        />
      );
    return (
      <OfficeReservationListItem
        style={style}
        key={reservation.id}
        officeReservation={reservation}
      />
    );
  }
}
