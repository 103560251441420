import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OfficeReservation } from "../entities/office-reservation";
import { Office } from "../entities/office";

export type OfficesState = {
  offices: Office[];
  officesFetched: boolean;
  officeReservations: OfficeReservation[];
  officeReservationsFetched: boolean;
};

const initialState = {
  offices: [],
  officesFetched: false,
  officeReservations: [],
  officeReservationsFetched: false,
} as OfficesState;

export const officesStateSlice = createSlice({
  name: "officesState",
  initialState,
  reducers: {
    setOffices(state, action: PayloadAction<Office[]>) {
      state.offices = action.payload;
      state.officesFetched = true;
    },
    setOfficeReservations(state, action: PayloadAction<OfficeReservation[]>) {
      state.officeReservations = action.payload;
      state.officeReservationsFetched = true;
    },
  },
});

export const officesState = officesStateSlice.reducer;
export const { setOffices, setOfficeReservations } = officesStateSlice.actions;
