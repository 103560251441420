import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import MixedReservationList from "../mixed/mixed-reservation-list";
import { FoodReservation } from "../../entities/food-reservation";
import {
  compareDatesIrrespectiveOfTime,
  isDateABiggerThenOrEqualToDateBIrrespectiveOfTime,
} from "../../functions/date-functions";
import { CarReservation } from "../../entities/car-reservation";

export default function UpAndComingReservations() {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {t("dashboard.upAndComingReservations")}
      </Typography>
      <MixedReservationList
        filter={(reservation) => {
          let today = new Date();
          if (
            reservation instanceof FoodReservation ||
            reservation instanceof CarReservation
          ) {
            return isDateABiggerThenOrEqualToDateBIrrespectiveOfTime(
              today,
              reservation.date
            );
          }

          return isDateABiggerThenOrEqualToDateBIrrespectiveOfTime(
            today,
            reservation.start
          );
        }}
      />
    </>
  );
}
