import React, { useEffect, useState } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { Edit, MeetingRoom } from "@mui/icons-material";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import DoubleClickDelete from "../buttons/double-click-delete";
import { errorString } from "../../functions/error-functions";

import { Office } from "../../entities/office";
import { deleteOfficeById } from "../../requests/office-requests";
import { getOfficesAction } from "../../actions/office-actions";
import OfficeEditDialog from "./office-edit-dialog";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import { sortByNumber } from "../../functions/sort-functions";

export default function OfficeList() {
  const offices = useSelector((state: RootState) => state.officesState.offices);

  const dispatch = useDispatch();

  const isHousekeeper = useIsHousekeeper();

  const [editingOffice, setEditingOffice] = useState<Office | null>(null);

  const [editingDialogOpen, setEditingDialogOpen] = useState<boolean>(false);

  const emitSnackbarEvent = useGlobalSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedOffices([...offices].sort((a, b) => sortByNumber(a.id, b.id)));
  }, [offices]);

  const [processedOffices, setProcessedOffices] = useState<Office[]>([]);

  return (
    <>
      <List sx={{ overflowY: "auto", maxHeight: "350px" }}>
        {processedOffices.map((office) => {
          return (
            <ListItem
              divider={true}
              key={office.id}
              secondaryAction={
                isHousekeeper ? (
                  <>
                    <Tooltip title={`${t("tooltips.edit")}`}>
                      <IconButton
                        onClick={() => {
                          setEditingOffice(office);
                          setEditingDialogOpen(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <DoubleClickDelete
                      onClick={() => {
                        deleteOfficeById(office.id)
                          .then((response) => {
                            emitSnackbarEvent({
                              message: `${t("office.deletedSnackbarMessage")}`,
                              severity: "warning",
                            });
                            dispatch(getOfficesAction());
                          })
                          .catch((error) =>
                            emitSnackbarEvent({
                              message: errorString(error),
                              severity: "error",
                            })
                          );
                      }}
                    />
                  </>
                ) : null
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <MeetingRoom />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${office.name}, ${office.number}`} />
            </ListItem>
          );
        })}
      </List>
      {editingDialogOpen && editingOffice && (
        <OfficeEditDialog
          onClose={() => setEditingDialogOpen(false)}
          office={editingOffice}
        />
      )}
    </>
  );
}
