import { Action, combineReducers } from "redux";
import { authorizationState } from "./authorization-reducer";
import { themeState } from "./theme-reducer";
import { miscState } from "./misc-reducer";
import { carsState } from "./cars-reducer";
import { foodState } from "./food-reducer";
import { officesState } from "./offices-reducer";
import { problemsState } from "./problems-reducer";
import { apartmentsState } from "./apartments-reducer";
import { documentsState } from "./documents-reducer";
import { usersState } from "./users-reducer";
import { customReservationsState } from "./custom-reservations-reducer";

const appReducer = combineReducers({
  authorizationState,
  themeState,
  miscState,
  carsState,
  foodState,
  officesState,
  problemsState,
  apartmentsState,
  documentsState,
  usersState,
  customReservationsState,
});

export default function rootReducer(state: any, action: Action) {
  if (action.type === "LOGOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
