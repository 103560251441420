import React, { CSSProperties } from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { Apartment, Apartment as ApartmentIcon } from "@mui/icons-material";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useDispatch } from "react-redux";
import { User } from "../../entities/user";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { useTranslation } from "react-i18next";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { getCarReservationsAction } from "../../actions/car-actions";
import DialogDelete from "../buttons/dialog-delete";
import { Guest } from "../../entities/guest";

export default function ApartmentReservationListItem({
  apartmentReservation,
  style,
}: {
  apartmentReservation: ApartmentReservation;
  style: CSSProperties;
}) {
  console.log(apartmentReservation);
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const { t } = useTranslation();

  let start = new Date(apartmentReservation.start);
  let end = new Date(apartmentReservation.end);
  return (
    <ListItem
      key={apartmentReservation.id}
      divider={true}
      style={style}
      secondaryAction={
        isHousekeeper || (user && user.id === apartmentReservation.userId) ? (
          <>
            <DialogDelete
              message={`${t("apartment.reservationDeleteDialogWarning")}`}
              title={`${t("apartment.reservationDeleteDialogTitle")}`}
              avatarIcon={<Apartment />}
              onClick={() => {
                deleteApartmentReservationById(apartmentReservation.id)
                  .then((response) => {
                    emitSnackbarEvent({
                      message: `${t(
                        "apartment.reservationDeletedSnackbarMessage"
                      )}`,
                      severity: "warning",
                    });
                    dispatch(getApartmentReservationsAction());
                    dispatch(getFoodReservationsAction());
                    dispatch(getCarReservationsAction());
                  })
                  .catch((error) =>
                    emitSnackbarEvent({
                      message: errorString(error),
                      severity: "error",
                    })
                  );
              }}
            />
          </>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar>
          <ApartmentIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{
          width: "60%",
          maxHeight: "101%",
          overflow: "auto",
        }}
        primary={apartmentReservation.apartment.name}
        secondary={`${t("reservedBy")} ${
          apartmentReservation.user.username
        } for ${apartmentReservation.guests
          .map((guestString) => {
            let guest = Guest.fromString(guestString);
            console.log(guest.start, start);
            if (
              guest.start &&
              guest.end &&
              new Date(guest.start).getTime() === new Date(start).getTime() &&
              new Date(guest.end).getTime() === new Date(end).getTime()
            ) {
              return guest.name;
            }
            return guest.toPrettyString();
          })
          .join(", ")} (${apartmentReservation.guests.length} people)`}
      />
      <ListItemText
        style={{
          width: "40%",
        }}
        primary={
          start.getTime() === end.getTime()
            ? start.toDateString()
            : `${start.toDateString()} ${t("until")} ${end.toDateString()}`
        }
      />
    </ListItem>
  );
}
