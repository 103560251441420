import React, { useState } from "react";
import Calendar from "../calendar/calendar";
import { Event, DateRange, SlotInfo } from "react-big-calendar";
import ApartmentReservationCreateDialog from "./apartment-reservation-create-dialog";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {
  apartmentReservationsToCalendarEvents,
  carReservationsToCalendarEvents,
  customReservationsToCalendarEvents,
} from "../../functions/calendar-events-functions";
import useUser from "../../hooks/use-user";
import DoubleClickDelete from "../buttons/double-click-delete";
import { deleteApartmentReservationById } from "../../requests/apartment-requests";
import { getApartmentReservationsAction } from "../../actions/apartment-actions";
import { errorString } from "../../functions/error-functions";
import { ApartmentReservation } from "../../entities/apartment-reservation";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import {
  Apartment,
  Bookmark,
  DirectionsCar,
  Favorite,
  Restaurant,
} from "@mui/icons-material";
import {
  Avatar,
  Icon,
  IconButton,
  ListItemAvatar,
  Tooltip,
  Typography,
} from "@mui/material";
import { getFoodReservationsAction } from "../../actions/food-actions";
import { getCarReservationsAction } from "../../actions/car-actions";
import DialogDelete from "../buttons/dialog-delete";
import { stringToColor } from "../../functions/string-functions";
import UserAvatar from "../avatars/user-avatar";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { MS_IN_MINUTE } from "../../consts";
import { VariableCustomEvent } from "../mixed/mixed-calendar";
import { useLocation, useNavigate } from "react-router-dom";

export default function ApartmentReservationsCalendar({
  hideHousekeeperEvents,
}: {
  hideHousekeeperEvents: boolean;
}) {
  const [slotInfo, setSlotInfo] = useState<SlotInfo | null>();

  const apartmentReservationEvents = useSelector((state: RootState) =>
    apartmentReservationsToCalendarEvents(
      state.apartmentsState.apartmentReservations
    )
  );

  const customReservationEvents = useSelector((state: RootState) => {
    return customReservationsToCalendarEvents([
      ...state.customReservationsState.customReservations,
    ]);
  });
  return (
    <>
      <Calendar
        onSelectSlot={(slotInfo) => {
          setSlotInfo(slotInfo);
        }}
        events={[
          ...apartmentReservationEvents,
          ...(hideHousekeeperEvents ? [] : customReservationEvents),
        ]}
        customEvent={VariableCustomEvent}
      />
      {slotInfo && (
        <ApartmentReservationCreateDialog
          initialStart={new Date(slotInfo.start)}
          initialEnd={new Date(new Date(slotInfo.end).getTime() - MS_IN_MINUTE)}
          onClose={() => {
            setSlotInfo(null);
          }}
        />
      )}
    </>
  );
}

export function CustomApartmentReservationEvent({ event }: { event: Event }) {
  let apartmentReservation: ApartmentReservation = event.resource;

  const { t } = useTranslation();

  const location = useLocation();
  const emitSnackbarEvent = useGlobalSnackbar();
  const dispatch = useDispatch();

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();
  const navigate = useNavigate();
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <Apartment sx={{ height: 24 }} />
      {user && user.id === apartmentReservation.userId && (
        <Bookmark sx={{ height: 24 }} />
      )}
      <Typography
        sx={{
          ml: 1,
          maxWidth: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event.title}
      </Typography>

      {((hovering && isHousekeeper) ||
        (hovering &&
          user &&
          (user.id === apartmentReservation.userId ||
            user.guests.some(
              (innerUser) => innerUser.id === apartmentReservation.userId
            )))) && (
        <>
          {location &&
            (location.pathname.includes("/food") ||
              location.pathname.includes("/apartments")) &&
            user &&
            user.id === apartmentReservation.userId && (
              <Tooltip
                title={`${t(
                  "food.apartmentReservationEventFoodReservationTooltip"
                )}`}
              >
                <IconButton
                  onClick={() => {
                    navigate(`/food/${apartmentReservation.id}`);
                  }}
                  color={"inherit"}
                  sx={{ ml: 1, height: 24, width: 24 }}
                >
                  <Restaurant />
                </IconButton>
              </Tooltip>
            )}
          <DialogDelete
            sx={{ ml: 1, height: 24, width: 24 }}
            message={`${t("apartment.reservationDeleteDialogWarning")}`}
            title={`${t("apartment.reservationDeleteDialogTitle")}`}
            avatarIcon={<Apartment />}
            onClick={() => {
              deleteApartmentReservationById(apartmentReservation.id)
                .then((response) => {
                  emitSnackbarEvent({
                    message: `${t(
                      "apartment.reservationDeletedSnackbarMessage"
                    )}`,
                    severity: "warning",
                  });
                  dispatch(getApartmentReservationsAction());
                  dispatch(getFoodReservationsAction());
                  dispatch(getCarReservationsAction());
                })
                .catch((error) =>
                  emitSnackbarEvent({
                    message: errorString(error),
                    severity: "error",
                  })
                );
            }}
          />
        </>
      )}
    </div>
  );
}
