import React from "react";
import { RootState } from "../index";
import { useSelector } from "react-redux";
import { Apartment } from "../entities/apartment";

export default function useMatchApartment(): (
  apartmentId: number
) => Apartment | null {
  const apartments = useSelector(
    (state: RootState) => state.apartmentsState.apartments
  );

  function matchApartment(apartmentId: number) {
    let matchedApartment = apartments.find(
      (apartment) => apartment.id === apartmentId
    );

    return matchedApartment ? matchedApartment : null;
  }

  return matchApartment;
}
