import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import useTitle from "../../hooks/use-title";
import { useTranslation } from "react-i18next";

export default function MyAccount() {
  const { t } = useTranslation();
  useTitle(`${t("myAccount.title")}`);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}></Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
