import { User } from "./user";

export class CustomReservation {
  constructor(customReservation: CustomReservation) {
    this.id = customReservation.id;
    this.color = customReservation.color;
    this.title = customReservation.title;
    this.start = customReservation.start;
    this.end = customReservation.end;
    this.user = customReservation.user;
    this.userId = customReservation.userId;
    this.createdAt = customReservation.createdAt;
  }

  id: number;

  start: Date;

  end: Date;

  title: string;

  userId: number;

  user: User;

  color: string;

  createdAt: Date;
}
