import React, { CSSProperties, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
} from "@mui/material";
import { RootState } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { sortByDate } from "../../functions/sort-functions";
import { SortType } from "../../enums/sort-type";
import useUser from "../../hooks/use-user";
import useIsHousekeeper from "../../hooks/use-is-housekeeper";
import { OfficeReservation } from "../../entities/office-reservation";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import OfficeReservationListItem from "./office-reservation-list-item";
import { useTranslation } from "react-i18next";
import { getTodayNoTime } from "../../functions/date-functions";

export default function OfficeReservationList() {
  const officeReservations = useSelector(
    (state: RootState) => state.officesState.officeReservations
  );

  const user = useUser();
  const isHousekeeper = useIsHousekeeper();

  const [showOthers, setShowOthers] = useState<boolean>(isHousekeeper);
  const [showExpired, setShowExpired] = useState<boolean>(false);

  const [processedOfficeReservations, setProcessedOfficeReservations] =
    useState<OfficeReservation[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setProcessedOfficeReservations(
      [...officeReservations]
        .filter((officeReservation) => {
          if (
            !showExpired &&
            new Date(officeReservation.end) < getTodayNoTime()
          )
            return false;
          if (!showOthers && user && user.id !== officeReservation.userId)
            return false;
          return true;
        })
        .sort((a, b) => sortByDate(a.start, b.start, SortType.ASC))
    );
  }, [officeReservations, showOthers, showExpired]);

  return (
    <>
      <Stack direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showExpired}
              onChange={(event) => setShowExpired(event.target.checked)}
            />
          }
          label={`${t("showExpiredLabel")}`}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showOthers}
              onChange={(event) => setShowOthers(event.target.checked)}
            />
          }
          label={`${t("showOthersLabel")}`}
          labelPlacement="end"
        />
      </Stack>
      <Box
        sx={{
          overflowY: "auto",
          height: "350px",
          maxHeight: "350px",
          width: "100%",
        }}
      >
        {processedOfficeReservations.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText primary={`${t("noReservationsListItemText")}`} />
            </ListItem>
          </List>
        ) : (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  itemSize={60}
                  height={height}
                  itemCount={processedOfficeReservations.length}
                  width={width}
                >
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        )}
      </Box>
    </>
  );

  function renderRow({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) {
    return (
      <OfficeReservationListItem
        key={index}
        style={style}
        officeReservation={processedOfficeReservations[index]}
      />
    );
  }
}
