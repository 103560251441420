import { Dispatch } from "redux";
import { setOfficeReservations, setOffices } from "../reducers/offices-reducer";
import { getOfficeReservations, getOffices } from "../requests/office-requests";

export function getOfficesAction() {
  return (dispatch: Dispatch) => {
    getOffices().then((response) => dispatch(setOffices(response.data.data)));
  };
}

export function getOfficeReservationsAction() {
  return (dispatch: Dispatch) => {
    getOfficeReservations().then((response) =>
      dispatch(setOfficeReservations(response.data.data))
    );
  };
}
