import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home/home";
import Offices from "./offices/offices";
import Cars from "./cars/cars";
import Apartments from "./apartments/apartments";
import Food from "./food/food";
import useIsHousekeeper from "../hooks/use-is-housekeeper";
import Documents from "./documents/documents";
import DocumentEdit from "./documents/document-edit";
import DocumentCreate from "./documents/document-create";
import DocumentReader from "./documents/document-reader";
import Users from "./users/users";
import useIsWorker from "../hooks/use-is-worker";
import MyAccount from "./my-account/my-account";
import WorkerHome from "./home/worker-home";
import Problems from "./problems/problems";
import Dashboard from "./dashboard/dashboard";

export default function Router() {
  const isHousekeeper = useIsHousekeeper();
  const isWorker = useIsWorker();

  if (isWorker)
    return (
      <Routes>
        <Route path={"/my-account"} element={<MyAccount />} />
        <Route path={"/offices"} element={<Offices />}>
          <Route path={"new-reservation"} element={<Offices />} />
        </Route>
        <Route path={"/:documentTitle"} element={<DocumentReader />} />
        <Route path={"/"} element={<WorkerHome />} />
      </Routes>
    );

  return (
    <Routes>
      <Route path={"/my-account"} element={<MyAccount />} />
      <Route path={"/cars"} element={<Cars />}>
        <Route path={"fullscreen-calendar"} element={<Cars />} />
        <Route path={"new-reservation"} element={<Cars />} />
        {isHousekeeper && <Route path={"new-car"} element={<Cars />} />}
      </Route>
      <Route path={"/apartments"} element={<Apartments />}>
        <Route path={"fullscreen-calendar"} element={<Apartments />} />
        <Route path={"new-reservation"} element={<Apartments />} />

        {isHousekeeper && (
          <Route path={"new-apartment"} element={<Apartments />} />
        )}
      </Route>
      <Route path={"/problems"} element={<Problems />}>
        <Route path={":problemId"} element={<Problems />} />
        <Route path={"new-problem"} element={<Problems />} />
      </Route>
      <Route path={"/food"} element={<Food />}>
        <Route path={":apartmentReservationId"} element={<Food />} />
        <Route path={"fullscreen-calendar"} element={<Food />} />
        <Route path={"new-reservation"} element={<Food />} />
      </Route>
      {isHousekeeper && (
        <>
          <Route path={"/offices"} element={<Offices />}>
            <Route path={"new-reservation"} element={<Offices />} />
            <Route path={"fullscreen-calendar"} element={<Offices />} />
            {isHousekeeper && (
              <Route path={"new-office"} element={<Offices />} />
            )}
          </Route>
          <Route
            path={"/information/new-document"}
            element={<DocumentCreate />}
          />
          <Route path={"/information/:documentId"} element={<DocumentEdit />} />
          <Route path={"/information"} element={<Documents />} />
          <Route path={"/users"} element={<Users />}>
            <Route path={"new-user"} element={<Users />} />
          </Route>
        </>
      )}
      <Route path={"/:documentTitle"} element={<DocumentReader />} />
      {isHousekeeper ? (
        <>
          <Route path={"/new-custom-reservation"} element={<Dashboard />} />
          <Route path={"/fullscreen-calendar"} element={<Dashboard />} />
          <Route path={"/"} element={<Dashboard />} />
        </>
      ) : (
        <>
          <Route path={"/fullscreen-calendar"} element={<Home />} />
          <Route path={"/"} element={<Home />} />
        </>
      )}
    </Routes>
  );
}
