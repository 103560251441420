import React, { FormEvent, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, HelpOutline, Person } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import PasswordInput from "../inputs/password-input";
import md5 from "md5";
import { putUserById } from "../../requests/user-requests";
import { getSelfAction, getUsersAction } from "../../actions/user-actions";
import RoleSelect, { RoleOption } from "../selects/role-select";
import { User } from "../../entities/user";
import { UserUpdateBody } from "../../request-bodies/user-update-body";
import { errorString } from "../../functions/error-functions";
import useUser from "../../hooks/use-user";
import useGlobalSnackbar from "../../hooks/use-global-snackbar";
import { useTranslation } from "react-i18next";
import UserSelect, { UserOption } from "../selects/user-select";
import { Role } from "../../enums/role";

export default function UserEditDialog({
  onClose,
  user,
}: {
  onClose: () => any;
  user: User;
}) {
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const [username, setUsername] = useState<string>(user.username);
  const [password, setPassword] = useState<string>("");
  const [selectedRoleOption, setSelectedRoleOption] =
    useState<RoleOption | null>({ label: user.role, role: user.role });

  const [selectedUserOption, setSelectedUserOption] =
    useState<UserOption | null>(
      user.host ? { id: user.host.id, label: user.host.username } : null
    );
  const emitSnackbarEvent = useGlobalSnackbar();

  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const authenticatedUser = useUser();

  function handleClose() {
    setDialogOpened(false);
    onClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpened}
      onClose={handleClose}
      sx={{ ".MuiDialog-paper": { overflowY: "visible" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }}>
            <Person />
          </Avatar>

          <Typography>Edit User</Typography>
        </Box>
        <Box>
          {/* <Tooltip title={`${t("tooltips.editHelp")}`}> */}
          {/*   <IconButton> */}
          {/*     <HelpOutline /> */}
          {/*   </IconButton> */}
          {/* </Tooltip> */}
          <Tooltip title={`${t("tooltips.close")}`}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ width: "600px" }}>
        <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            sx={{ mb: 2 }}
            value={username}
            required={true}
            onChange={(event) => setUsername(event.target.value)}
            label={`${t("user.usernameLabel")}`}
            fullWidth={true}
          />

          <PasswordInput
            sx={{ mb: 2 }}
            password={password}
            setPassword={setPassword}
            fullWidth={true}
            generateButton={true}
            clipboard={true}
          />

          <Box sx={{ mb: 2 }}>
            <RoleSelect
              selectedRoleOption={selectedRoleOption}
              setSelectedRoleOption={setSelectedRoleOption}
              required={true}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <UserSelect
              selectedUserOption={selectedUserOption}
              setSelectedUserOption={setSelectedUserOption}
              userFilterFunction={(user) =>
                user.role === Role.OWNER || user.role === Role.HOUSEKEEPER
              }
              label={"Host"}
            />
          </Box>
          <Button type={"submit"} fullWidth={true} variant={"contained"}>
            {t("updateButtonText")}
          </Button>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Box>
      </DialogContent>
    </Dialog>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!username && !password && !selectedRoleOption) {
      setError(`${t("allFieldsEmptyError")}`);
      return;
    }

    // if (!username) {
    //   setError("no username entered");
    //   return;
    // }
    // if (!password) {
    //   setError("no password entered");
    //   return;
    // }
    if (!selectedRoleOption) {
      setError("no role selected");
      return;
    }
    let hostUserId: number | null = null;
    if (selectedUserOption) {
      hostUserId = selectedUserOption.id;
    }

    let userUpdateBody = {
      username,
      password: password ? md5(password) : null,
      role: selectedRoleOption.role,
      hostUserId,
    } as UserUpdateBody;

    putUserById(user.id, userUpdateBody)
      .then((response) => {
        emitSnackbarEvent({
          message: `${t("user.updateSnackbarMessage")}`,
          severity: "success",
        });
        dispatch(getUsersAction());
        dispatch(getSelfAction());
        handleClose();
      })
      .catch((error) => setError(errorString(error)));
  }
}
